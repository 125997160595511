import { Icon } from "@iconify/react";
import React, { useEffect, useRef, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import getBankLogo from "../../stores/getBankLogo";

function DropdownMenu({
  beneficiaryData,
  payset,
  paymentInfo,
  status,
  setIsOpenModal,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedBeneficiary, setSelectedBeneficiary] = useState(null);
  const [position, setPosition] = useState("below");
  const divRef = useRef(null);
  const dropdownRef = useRef(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const navigate = useNavigate();
  const beneficiaries = [...beneficiaryData];

  // 

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  // handle click out side the drop down

  const handleOptionSelect = (beneficiary) => {
    const selectedData = {
      paydatas: beneficiary.payment_info,
      bend_ids: beneficiary.beneficiary_id,
      name: beneficiary.beneficiary_name,
      address: beneficiary.beneficiary_address,
    };

    payset(JSON.stringify(selectedData));
    setSelectedBeneficiary(beneficiary);
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false); // Close the dropdown if clicked outside
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const button = divRef.current;
    if (button) {
      const spaceBelow =
        window.innerHeight - button.getBoundingClientRect().bottom;
      const spaceAbove = button.getBoundingClientRect().top;
      // Check if there's not enough space below the button to display the dropdown
      if (spaceBelow < 200 && spaceAbove > 200) {
        setPosition("above");
      } else {
        setPosition("below");
      }
    }
  }, [isOpen]);

  const handleAddNewBeneficiary = () => {
    // 
    if (beneficiaries?.length === 5) {
      openPopup();
      return;
    }
    // navigate("/profile/beneficiary");
    setIsOpenModal(true);
  };
  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };
  return (
    <div className="relative" ref={dropdownRef}>
      <div
        onClick={toggleDropdown}
        ref={divRef}
        className={`${
          selectedBeneficiary ? "text-black" : "text-blackBlur2"
        } flex justify-between cursor-pointer items-center bg-transparent p-4 font-bold text-[0.8rem] lg:text-[1rem]	rounded-[0.5rem] border-[1px] border-solid border-[rgba(255,255,255,0.20)]`}
      >
        <span className="font-bold text-[0.8rem] lg:text-[1rem] text-white">
          {selectedBeneficiary
            ? selectedBeneficiary.beneficiary_name
            : "Select Beneficiary"}
        </span>
        <span>
          {isOpen ? (
            <Icon
              icon="material-symbols:keyboard-arrow-down-rounded"
              rotate={2}
              className=" text-white text-[1.5rem]"
            />
          ) : (
            <Icon
              icon="material-symbols:keyboard-arrow-down-rounded"
              className="cursor-pointer text-white text-[1.5rem]"
            />
          )}
        </span>
      </div>
      {isOpen && (
        <ul
          className={`absolute mt-0 p-[16px] sm:p-[24px] bg-[#202020]  w-full shadow-md z-10 border-[1px] border-solid border-[rgba(255,255,255,0.20)] ${
            position === "above" ? "bottom-full rounded-t-lg " : "rounded-b-lg"
          }`}
        >
          <li className="mb-[16px] flex justify-between items-center">
            <NavLink
              to="/profile/beneficiary"
              className="flex gap-[2px] items-center"
            >
              <span className="font-[Lato] text-[0.8rem] lg:text-[1rem] not-italic font-medium leading-[normal] [text-decoration-line:underline]">
                Manage Beneficiaries
              </span>
              <span>
                <Icon
                  icon="ic:baseline-greater-than"
                  width="1rem"
                  height="1rem"
                  className="text-white"
                />
              </span>
            </NavLink>
            <div>
              <span className="font-[Lato] text-[0.8rem] lg:text-[1rem] not-italic font-bold leading-[normal]">
                <span className="text-[#F55960]">{beneficiaries?.length}</span>{" "}
                out of <span className="text-[#F90070]">5</span> added
              </span>
            </div>
          </li>
          {beneficiaries.map((beneficiary) => (
            <li
              key={beneficiary.beneficiary_id}
              onClick={() => handleOptionSelect(beneficiary)}
              className="cursor-pointer flex items-center gap-[16px] hover:text-white rounded-[0.5rem] border-b border-[#4D4D4D] px-4 py-4 hover:bg-[linear-gradient(90deg,_#F4795A_0.04%,_#F4705C_15.04%,_#F55960_38.04%,_#F73367_67.04%,_#F90070_100.04%)] "
              value={{
                paydatas: beneficiary.payment_info,
                bend_ids: beneficiary.beneficiary_id,
                name: beneficiary.beneficiary_name,
                address: beneficiary.beneficiary_address,
              }}
            >
              <div>
                <img
                  src={getBankLogo(
                    beneficiary?.payment_info?.ifsc_code.substring(0, 4)
                  )}
                  alt=""
                  className="min-w-[35px] h-[35px]"
                />
              </div>
              <div className="flex flex-col grow justify-center items-start gap-[4px] text-[0.8rem] ">
                <p>{beneficiary?.beneficiary_name}</p>
                <p className="md:w-full lg:flex lg:justify-between">
                  <p>{beneficiary?.payment_info?.bankAccount}</p>
                  <p>{beneficiary?.payment_info?.ifsc_code}</p>
                </p>
              </div>
            </li>
          ))}

          {beneficiaries?.length === 5 ? (
            <li
              className="cursor-pointer  text-center mt-[16px]  px-[27px] py-4  text-[0.8rem] lg:text-[18px] font-bold  rounded-full bg-[#BCBCBC] "
              onClick={handleAddNewBeneficiary}
            >
              <span className="">Max no. of Beneficiaries added</span>
            </li>
          ) : (
            <li
              className="cursor-pointer  text-center mt-[16px]  px-[27px] py-4  text-[0.8rem] lg:text-[18px] font-bold  rounded-full bg-[linear-gradient(90deg,_#F4795A_0.04%,_#F4705C_15.04%,_#F55960_38.04%,_#F73367_67.04%,_#F90070_100.04%)] "
              onClick={handleAddNewBeneficiary}
            >
              <span className="   ">Add New Bank Account</span>
            </li>
          )}
        </ul>
      )}
      {isPopupOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-10 ">
          <div className="bg-[#202020] p-6 border  rounded-md w-96 relative ">
            <div className="flex justify-end absolute top-2 right-2">
              <button onClick={closePopup}>
                <Icon icon="charm:cross" width="32" height="32" className="" />{" "}
              </button>
            </div>
            <div className=" mt-2 flex justify-center">
              <Icon
                icon="fluent:delete-12-filled"
                width="80"
                height="80"
                className="text-[#f73367]"
              />
            </div>
            <p className="mt-3 text-[18px] text-center">
              Delete any one to add new Beneficiary
            </p>
            <div className="mt-5 flex justify-center">
              <span
                className=" cursor-pointer text-[14px] font-semibold px-[10px] py-[5px] bg-[linear-gradient(90deg,_#F4795A_0.04%,_#F4705C_15.04%,_#F55960_38.04%,_#F73367_67.04%,_#F90070_100.04%)] rounded-[8px] "
                onClick={() => navigate("/profile/beneficiary")}
              >
                Delete Beneficiary
              </span>
            </div>
          </div>
        </div>
      )}

      {paymentInfo && (
        <p className="text-sm text-red-700 mt-3">
          *Add Beneficiary and make payment
        </p>
      )}
    </div>
  );
}

export default DropdownMenu;
