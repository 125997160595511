// import { Option, Select } from "@material-tailwind/react";
import axios from "axios";
import React from "react";
import Select from "react-select";
import { toast } from "react-toastify";
import { APIUrls } from "../../baseUrl/BaseUrl";

const SelectBox = ({
  name,
  label,
  options,
  value,
  onChange,
  token,
  setLoading,
  setUserBillData,
}) => {
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "#131313",
      borderColor: "rgba(255, 255, 255, 0.2)",
      padding: "10px",
      color: state.isSelected ? "#fff" : "#fff",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#3498db" : "#131313",
      color: state.isSelected ? "#fff" : "#fff",
      borderColor: "rgba(255, 255, 255, 0.2)",
    }),
    input: (styles) => ({ ...styles, color: "#fff" }),
    placeholder: (styles) => ({ ...styles, color: "#fff" }),
    singleValue: (styles) => ({ ...styles, color: "#fff" }),
    noOptionsMessage: (styles) => ({
      ...styles,
      color: "#fff",
    }),
    menu: (styles) => ({
      ...styles,
      color: "#fff",
      backgroundColor: "#131313",
      border: "1px solid rgb(255, 255, 255,0.2)",
    }),
  };

  return (
    <div className="flex flex-col gap-[1rem] mb-5">
      <label
        htmlFor="accounId"
        className="text-[#FFF] font-[Inter] text-[1rem] xs:text-[20px] not-italic font-bold leading-[normal]"
      >
        {label}
      </label>
      {/* <Select
        variant="standard"
        name={name}
        className="h-[53.6px] xs:h-[56px] outline-none  text-[1rem] lg:text-[18px] text-[rgba(255,_255,_255,_0.70)] font-[Inter] not-italic font-bold leading-[normal] rounded-[4px] border-[1px] border-[solid] border-[rgba(255,255,255,0.20)]"
        style={{ padding: "16px" }}
        placeholder="Select Beneficiary"
        value={value || ""}
        onChange={onChange}
      >
        {options.map((item, index) => (
          <Option key={index} value={item.value}>
            <span className="ml-[16px]">{item.label}</span>
          </Option>
        ))}
      </Select> */}

      <Select
        options={options}
        value={options.find((option) => option.value === value)}
        // we pass static value for this
        // value={options.find((option) => option.value === "TNEB00000TND01")}
        onChange={async (selectedOption) => {
          fetchUserBillData(
            selectedOption.value,
            await token(),
            setLoading,
            setUserBillData
          );

          return onChange(selectedOption.value);
        }}
        styles={customStyles}
        isSearchable={true}
        name={name}
        classNamePrefix="react-select"
      />
    </div>
  );
};

const fetchUserBillData = async (
  billerId,
  token,
  setLoading,
  setUserBillData,
  billInfo
) => {
  try {
    setLoading(true);
    const response = await axios.post(
      APIUrls.user_bill_fetch,
      {
        billerId: billerId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    // 

    // setBillerData(response.data);
    if (response.data.code === 200) {
      setLoading(false);
      // 

      setUserBillData({
        requestData:
          response.data.response?.userBillData?.paymentInfo?.billInfo
            ?.requestData,
      });

      // setBillerData(response?.data?.response?.billers);
    } else {
      setLoading(false);
      toast("Somthing went to wrong", {
        theme: "dark",
        hideProgressBar: true,
        type: "error",
      });
    }
  } catch (error) {
    setLoading(false);
  }
};

export default SelectBox;
