import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  beneficiaryReloader: false,
  beneficiaryRefetch: null,
  KYCRefetch: null,
  KYCRefetchGlobal: null,
  kycVerified: false,
  aadhaarVerified: false,
  panVerified: false,
  isProfileComplete: false,
  isReauthenticationRequired: false,
};

export const paySlice = createSlice({
  name: "pay",
  initialState,
  reducers: {
    updateBeneficiaryReloader(state, action) {
      state.beneficiaryReloader = !state.beneficiaryReloader;
    },
    updateBeneficiaryRefetch(state, action) {
      state.beneficiaryRefetch = action.payload.refetch;
    },
    updateKYCRefetch(state, action) {
      state.KYCRefetch = action.payload.refetch;
    },
    updateKYCRefetchGlobal(state, action) {
      state.KYCRefetchGlobal = action.payload.globalRefetch;
    },
    updateEmployeeDetails(state, action) {
      state.employeeDetails = action.payload.employeeDetails;
    },
    updateUserName(state, action) {
      state.userName = action.payload.userName;
    },
    updateUserId(state, action) {
      state.userId = action.payload.userId;
    },

    updateKYCVerification(state, action) {
      state.kycVerified = action.payload.kycVerified;
    },
    updateAadhaarVerification(state, action) {
      state.aadhaarVerified = action.payload.aadhaarVerified;
    },
    updatePANVerification(state, action) {
      state.panVerified = action.payload.panVerified;
    },
    updateProfileStatus(state, action) {
      state.isProfileComplete = action.payload.profileStatus;
    },
    updateIsReauthenticationRequired(state, action) {
      state.isReauthenticationRequired =
        action.payload.isReauthenticationRequired;
    },
  },
});
