// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../layers/paketo-buildpacks_npm-install/launch-modules/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../layers/paketo-buildpacks_npm-install/launch-modules/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.scrollbar::-webkit-scrollbar {
    width: 2px;
    height: 3px;
  }
  
  /* Track */
  .scrollbar::-webkit-scrollbar-track {
    background: #DFDFDF;
  }
  
  /* Handle */
  .scrollbar::-webkit-scrollbar-thumb {
    background: #DFDFDF;
  }
  
  /* Handle on hover */
  .scrollbar::-webkit-scrollbar-thumb:hover {
    background: 
    #9b9b9b;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/cards/payment/scrollbar.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,WAAW;EACb;;EAEA,UAAU;EACV;IACE,mBAAmB;EACrB;;EAEA,WAAW;EACX;IACE,mBAAmB;EACrB;;EAEA,oBAAoB;EACpB;IACE;WACO;EACT","sourcesContent":[".scrollbar::-webkit-scrollbar {\n    width: 2px;\n    height: 3px;\n  }\n  \n  /* Track */\n  .scrollbar::-webkit-scrollbar-track {\n    background: #DFDFDF;\n  }\n  \n  /* Handle */\n  .scrollbar::-webkit-scrollbar-thumb {\n    background: #DFDFDF;\n  }\n  \n  /* Handle on hover */\n  .scrollbar::-webkit-scrollbar-thumb:hover {\n    background: \n    #9b9b9b;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
