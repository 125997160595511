import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { AuthContextProvider } from "./stores/AuthContext";
import store from "./stores/IndexRedux";

import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: "GTM-56QGQPCW",
};

TagManager.initialize(tagManagerArgs);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <AuthContextProvider>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </AuthContextProvider>
  </Provider>
);

reportWebVitals();
