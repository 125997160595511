let baseUrl = "http://localhost:4000";
let cashfreeMode = "sandbox";
let ReturnUrl = "http://localhost:3000";
const type = "PROD";

if (type === "PROD") {
  baseUrl = "https://apiv2.payinstacard.com";
  ReturnUrl = "https://payinstacard.com";
  cashfreeMode = "production";
} else if (type === "STAGING") {
  baseUrl = "https://stagingapiv2.payinstacard.com";
  ReturnUrl = "https://staging.payinstacard.com";
  cashfreeMode = "production";
} else {
  baseUrl = "http://localhost:4000";
}

export const APIUrls = {
  ENV_TYPE: type,
  paymentENV: "PROD",
  baseUrl: baseUrl,
  updateProfile: `${baseUrl}/users/profile-update`,
  generateAadhaarOTP: `${baseUrl}/verification-suits/generateAadhaarOTP`,
  submitAadhaarOTP: `${baseUrl}/verification-suits/submitAadhaarOTP`,
  verifyPAN: `${baseUrl}/verification-suits/verifyPAN`,
  //add_benificer: `${baseUrl}/users/add-new-ben`,
  add_benificer: `${baseUrl}/users/add-cashfree-ben`,
  fetchBen: (category) =>
    `${baseUrl}/users/fetch-beneficiary?ben_category=${category}`,
  make_payment: `${baseUrl}/payment/create_order_post`,
  fetch_order: `${baseUrl}/payment/order_detail`,
  deleteBenficiery: `${baseUrl}/users/remove-ben-new`,
  fetchKYCDetails: `${baseUrl}/users/fetch-KYCDetails`,
  requestKYC: `${baseUrl}/users/kyc-request`,
  requestVerifyKYC: `${baseUrl}/users/kyc-request-verify/`,
  getTransactions: `${baseUrl}/users/get-transactions`,
  airpay_payment: `${baseUrl}/payment/airpay_post`,
  airpay_verify: `${baseUrl}/payment/airpay/verify-order`,
  login_logs: `${baseUrl}/users/update-login-log`,
  get_persentage: `${baseUrl}/users/get-commission`,
  set_feedback: `${baseUrl}/users/user-feedback`,
  email_verify: `${baseUrl}/users/verify-email`,
  submit_otp_email: `${baseUrl}/users/email-otp-submit`,
  login_blocker: `${baseUrl}/users/login-prevention`,
  log_visitors: `${baseUrl}/users/visitor/logs`,
  get_wallet_limit: `${baseUrl}/api/v2/dashboarding/get-wallet-limit`,
  get_userData: `${baseUrl}/users/get/data`,
  validate_referals: `${baseUrl}/referal/validatereferal`,
  generate_referals: `${baseUrl}/referal/getreferaid`,
  contactus_post: `${baseUrl}/users/contactus`,
  withdrawal_request: `${baseUrl}/referal/request-widthdrawal`,
  get_settingsData: `${baseUrl}/users/get-settings`,
  biller_fetch: `${baseUrl}/api/bbps/fetchbillers`,
  fetch_biller_states: `${baseUrl}/api/bbps/fetchBillerStates`,
  fetch_bills: `${baseUrl}/api/bbps/fetch-bill`,
  validate_bills: `${baseUrl}/api/bbps/validate-bill`,
  razorPayroll: `${baseUrl}/paymentg/razorpay/create-order`,
  paymentStatus2: `${baseUrl}/paymentg/razorpay/status`,
  cashFreeCreateOrder: `${baseUrl}/paymentg/cashfree/create-order`,
  cashFreePaymentStatus: `${baseUrl}/paymentg/cashfree/status`,
  cashFreePaymentResponse: `${baseUrl}/paymentg/cashfree/payment_response`,
  ReturnUrl: ReturnUrl,
  cashfreeMode: cashfreeMode,
  cashFreeCreateOrderForBBPS: `${baseUrl}/api/bbps/cashfree/create-order`,
  cashFreePaymentStatusForBBPS: `${baseUrl}/api/bbps/cashfree/status`,
  cashFreePaymentResponseForBBPS: `${baseUrl}/api/bbps/cashfree/payment_response`,
  KYC_verify: `${baseUrl}/users/event/`,
  getTransactionDetail: `${baseUrl}/paymentg/getTransactionDetail`,
  createUser: `${baseUrl}/users/create-user`,
  isUserExist: `${baseUrl}/users/is-user-exist`,
  auth: {
    sendPhoneAuthOTP: `${baseUrl}/api/v2/auth/phone/send-otp`,
    resendPhoneAuthOTP: `${baseUrl}/api/v2/auth/phone/resend-otp`,
    verifyPhoneAuthOTP: `${baseUrl}/api/v2/auth/phone/verify-otp`,
  },
};
