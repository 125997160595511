import { load } from "@cashfreepayments/cashfree-js";
import axios from "axios";
import CryptoJS from "crypto-js";
import _ from "lodash";
import forge from "node-forge";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { APIUrls } from "../../baseUrl/BaseUrl";
import public_key from "../../public_key";
import { useAuth } from "../../stores/AuthContext";
import DynamicBillerForm from "../cards/DynamicInput";
import SelectBox from "../forms/SelectBox";
import Loader from "../loader/Loader";
import BbpsHeader from "./BbpsHeader";
import PaymentInfo from "./PaymentInfo";
import TotalPayButton from "./TotalPayButton";

function MobilePostpaidService({ utilitycategory = "Electricity" }) {
  // const { utilitycategory } = useParams();
  const [billerData, setBillerData] = useState([]);
  const [selectedBiller, setSelectedBiller] = useState(false);

  const [showAmountField, setAmountField] = useState(false);
  const [loading, setLoading] = useState(false);
  const [billInfo, setBillInfo] = useState(false);
  const [userBillData, setUserBillData] = useState(false);
  const [custParaInfo, setCustParaInfo] = useState(false);
  // const [emailVerifieds, setEmailverified] = useState(true);
  const [kycStatuss, setKyc_status] = useState(false);
  const [paymentDisables, setPaymentDisable] = useState(false);
  const { getAccessToken, currentUser } = useAuth();
  const payButtonRef = useRef(null);
  const userDatas = useSelector((state) => state.userData);
  const { emailVerified, kyc_verified, paymentDisabled } = userDatas;

  useEffect(() => {
    // Scroll to pay button when bill data changes
    if (payButtonRef.current) {
      payButtonRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [billInfo]);
  useEffect(() => {
    if (_.has(currentUser, "reloadUserInfo")) {
      if (_.has(currentUser.reloadUserInfo, "customAttributes")) {
        setValuesToForm(currentUser);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);
  const setValuesToForm = (userData) => {
    // const { FirstName, LastName, State, City, Address, Pincode } = JSON.parse(
    //   userData.reloadUserInfo.customAttributes
    // ).Customer_data;

    if (_.isEmpty(emailVerified)) {
      //
      // setEmailverified(false);
    }

    if (emailVerified === true) {
      //
      // setEmailverified(true);
    }

    if (_.isEmpty(paymentDisabled)) {
      //
      setPaymentDisable(false);
    }

    if (paymentDisabled === true) {
      setPaymentDisable(true);
    }

    if (_.isEmpty(kyc_verified)) {
      //
      setKyc_status(false);
    }

    if (kyc_verified === true) {
      setKyc_status(true);
    }
  };

  // const getBillerStates = async () => {
  //   const token = await getAccessToken();
  //   const response = await axios.get(APIUrls.fetch_biller_states, {
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //       "Content-Type": "application/json",
  //     },
  //   });
  //   if (response.data.response) {
  //     // 
  //     return response.data.response;
  //   } else {
  //     return [];
  //   }
  // };
  // useEffect(() => {
  //   const fetchStateData = async () => {
  //     const states = await getBillerStates();
  //     setBillerStates(states);
  //     setSelectedBiller(false);
  //   };
  //   fetchStateData();
  // }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (utilitycategory && utilitycategory !== "") {
        try {
          setLoading(true);
          const token = await getAccessToken();
          const response = await axios.post(
            APIUrls.biller_fetch,
            {
              billertype: utilitycategory,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          );

          // setBillerData(response.data);
          if (response.data.code === 200) {
            // 
            setBillerData(response?.data?.response?.billers);
            setLoading(false);
          } else {
            setLoading(false);
            toast("Somthing went to wrong", {
              theme: "dark",
              hideProgressBar: true,
              type: "error",
            });
          }
        } catch (error) {
          setLoading(false);
        }
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [utilitycategory]);

  //custom input filed
  useEffect(() => {
    setCustParaInfo(false);

    // if (
    //   _.isEmpty(selectedBiller) &&
    //   _.isEmpty(
    //     selectedBiller?.customerParams?.find(
    //       (item) => item.paramName === "Amount"
    //     )
    //   )
    // ) {
    //   setAmountField(true);
    // }

    let fetchRequirement = selectedBiller?.billarData?.fetchRequirement;

    if (fetchRequirement === "NOT_SUPPORTED") {
      let findAmout = selectedBiller?.billarData?.customerParams?.find(
        (item) => item.paramName === "Amount"
      );

      if (_.isEmpty(findAmout)) {
        setAmountField(true);
      }
    }
  }, [utilitycategory, selectedBiller]);

  // Function to handle search input change
  // const handleSearchChange = (event) => {
  //   setSearchTerm(event.target.value);
  // };

  // Filter billers based on search term
  // const filteredBillers = billerData.filter((biller) => {
  //   return biller.billerName.toLowerCase().includes(searchTerm.toLowerCase());
  // });

  const handleTransaction = async () => {
    // 
    // 

    if (!emailVerified) return toast.error("Your Email is not Verified !");

    if (!kycStatuss) {
      return toast.error("Your Kyc status is disabled !");
    }

    if (!paymentDisables) {
      return toast.error("Your payment is disabled !");
    }

    let QuickPay = "No";

    if (selectedBiller.billarData.billerAcceptsAdhoc === "true") {
      // if (selectedBiller?.billerDetail?.fetchRequirement === "OPTIONAL") {
      // }

      if (selectedBiller?.billarData?.fetchRequirement === "NOT_SUPPORTED") {
        if (
          selectedBiller?.billarData?.supportBillValidation === "OPTIONAL" ||
          selectedBiller?.billarData?.supportBillValidation === "NOT_SUPPORTED"
        ) {
          QuickPay = "Yes";
        }
      }
      // if (selectedBiller?.billarData?.supportBillValidation === "MANDATORY") {
      // }
    }

    const Paydata = {
      // Amount: payableAmount,
      // TotalAmount: totalAmount.toString(),
      // paymentType: rent,
      // paymentInfo: {
      //   beneficier_id: paymentData.bend_ids,
      //   name: paymentData?.name,
      //   address: paymentData?.address,
      //   type: paymentData?.paydatas?.type || "",
      //   bankAccount: paymentData?.paydatas?.bankAccount || "",
      //   ifsc_code: paymentData?.paydatas?.ifsc_code || "",
      //   upi_code: paymentData?.paydatas?.upi_code || "",
      // },
      // conviFees: convieCharge,
      billerDetail: selectedBiller,
      billInfo: {
        ...billInfo,
        QuickPay: QuickPay,
        showAmountField: showAmountField,
      },
      billerCategory: utilitycategory,
    };

    forge.random.getBytesSync(16);
    const binaryKEy = forge.random.getBytesSync(16).toString();

    const symmetricKey = forge.util.bytesToHex(binaryKEy);

    const key = symmetricKey;
    // Encrypt
    const ciphertext = CryptoJS.AES.encrypt(
      JSON.stringify(Paydata),
      key
    ).toString();
    const encryptData = (paymentData, publicKey) => {
      // Replace with the server's public key
      // const symmetricKey = forge.random.getBytesSync(16);

      // Convert the public key to a Forge RSA public key object
      const publicKeyForge = forge.pki.publicKeyFromPem(public_key);

      // Encrypt the data using RSA_PKCS1_OAEP_PADDING and sha256 hash
      const encrypted = publicKeyForge.encrypt(key, "RSA-OAEP", {
        md: forge.md.sha256.create(),
      });

      // Convert the encrypted bytes to a Base64-encoded string
      const encryptedBase64 = forge.util.encode64(encrypted);

      return encryptedBase64;
    };

    try {
      setLoading(true);

      const updatedValue = forge.util.encode64(ciphertext);

      const cashFreeData = {
        token: await getAccessToken(),
        key: encryptData(Paydata, public_key),
        payData: updatedValue,
      };

      try {
        const response = await axios.post(
          APIUrls.cashFreeCreateOrderForBBPS,
          cashFreeData
        );
        // 
        setLoading(false);

        if (response.status === 201) {
          const payment_session_id =
            response?.data?.response?.payment_session_id;
          const order_id = response?.data?.response?.order_id;
          // 

          handleCheckOut(payment_session_id, order_id);
        }
      } catch (error) {
        setLoading(false);
        // 
        toast.error("Something went Wrong !");
      }
    } catch (error) {
      setLoading(false);
      // 

      toast.error("Something went Wrong !");
    }
  };
  const handleCheckOut = async (payment_session_id, order_id) => {
    try {
      // it containse mode (sandbox, production) for the cashfree PG
      const cashfree = await load({
        mode: APIUrls.cashfreeMode, // "sandbox", //or production
      });
      let checkoutOptions = {
        paymentSessionId: payment_session_id,
        returnUrl: `${APIUrls.cashFreePaymentResponseForBBPS}/?orderid=${order_id}`,
        redirectTarget: "_self",
      };
      cashfree.checkout(checkoutOptions).then(function (result) {
        if (result.error) {
          alert(result.error.message);
        }
        if (result.redirect) {
          // 
        }
      });
    } catch (error) {
      // 
    }
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="px-[1rem] md:px-[80px] py-[2rem] md:py-[40px]">
            <div className="flex flex-col md:flex-row gap-[2rem] md:gap-[80px] lg:min-w-[350px]">
              <div className="md:w-[50%]  xs:p-[40px] lg:px-[80px] lg:py-[40px] flex flex-col gap-[24px]">
                <BbpsHeader />
                {/* <SelectBox
                  name="state"
                  label="Select State"
                  options={billerStates.map((datas, index) => {
                    return { label: datas.state, value: datas.state };
                  })}
                  value={selectedStates || ""}
                  onChange={(e) => {
                    setSelectedState(e);
                  }}
                /> */}

                {billerData?.length > 0 && (
                  <SelectBox
                    name="biller"
                    label="Select your biller"
                    options={billerData.map((datas, index) => {
                      return {
                        label: datas.billerName,
                        value: datas.billerId,
                      };
                    })}
                    value={selectedBiller.billerId || ""}
                    onChange={(e) => {
                      setSelectedBiller(
                        billerData?.find((a) => a.billerId === e)
                      );
                    }}
                    token={getAccessToken}
                    setLoading={setLoading}
                    setUserBillData={setUserBillData}
                  />

                  // <>
                  //   <p className="font-[Inter] text-[20px] not-italic font-bold leading-[normal]">
                  //     Select your biller
                  //   </p>
                  //   <input
                  //     type="text"
                  //     placeholder="Search for biller..."
                  //     value={searchTerm}
                  //     onChange={handleSearchChange}
                  //     className="p-4 rounded-[4px] border-[1px] border-[solid] border-[#424242] bg-transparent"
                  //   />
                  //   <ul className="h-[300px] overflow-y-auto scrollbar">
                  //     {filteredBillers.map((biller) => (
                  //       <li key={biller._id} className="mb-3">
                  //         <p>{biller.billerName}</p>
                  //       </li>
                  //     ))}
                  //   </ul>
                  // </>
                )}
                {billerData?.length > 0 &&
                  selectedBiller?.billarData?.customerParams && (
                    <DynamicBillerForm
                      selectedBiller={selectedBiller}
                      customerParams={
                        selectedBiller?.billarData?.customerParams
                      }
                      setBillInfo={setBillInfo}
                      setCustParaInfo={setCustParaInfo}
                      custParaInfo={custParaInfo}
                      showAmountField={showAmountField}
                      userBillData={userBillData}
                    />
                  )}
              </div>

              {/* payment card */}
              <div
                className="md:w-[50%] p-[24px] xs:p-[40px] lg:px-[80px] lg:py-[40px] text-white font-inter rounded-[25px] border-[1px] border-[solid] border-[rgba(255,255,255,0.04)] bg-[#202020] md:self-start"
                ref={payButtonRef}
              >
                <PaymentInfo
                  CardTitle="Payment Details"
                  TotalData={[
                    {
                      label: "Name",
                      value:
                        billInfo?.responseData?.response?.billerResponse
                          ?.customerName || "-",
                    },
                    {
                      label: "Due Date",
                      value:
                        billInfo?.responseData?.response?.billerResponse
                          ?.dueDate || "-",
                    },
                    {
                      label: "Amount",
                      value: billInfo?.amount
                        ? billInfo?.amount
                        : billInfo?.responseData?.response?.billerResponse
                            ?.amount || "-",
                    },
                    // {
                    //   label: "Convenience fees",
                    //   value:"-",
                    // },
                    {
                      label: "Total",
                      value: billInfo?.amount
                        ? billInfo?.amount
                        : billInfo?.responseData?.response?.billerResponse
                            ?.amount || "-",
                      bold: true,
                    },
                  ]}
                />

                <TotalPayButton
                  lableAmount={
                    billInfo?.amount || // billInfo?.responseData?.response?.billerResponse?.amount ||
                    "0"
                  }
                  loading={loading}
                  handleTransaction={handleTransaction}
                  disabled={!selectedBiller || !billInfo}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default MobilePostpaidService;
