import { Icon } from "@iconify/react";
import { Option, Select, ThemeProvider } from "@material-tailwind/react";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { Controller, useForm } from "react-hook-form";
import { CgSpinner } from "react-icons/cg";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import cross from "../../assets/revamped/Cross.svg";
import uploadImage from "../../assets/revamped/document-upload.svg";
import { APIUrls } from "../../baseUrl/BaseUrl";
import { useAuth } from "../../stores/AuthContext";
import Modal from "./Modal";

const theme = {
  select: {
    defaultProps: {
      variant: "outlined",
      color: "blue-gray",
      size: "md",
      label: "",
      error: false,
      success: false,
      arrow: undefined,
      value: undefined,
      onChange: undefined,
      selected: undefined,
      offset: 5,
      dismiss: {},
      animate: {
        unmount: {},
        mount: {},
      },
      autoHeight: false,
      lockScroll: false,
      labelProps: {},
      menuProps: {},
      className: "",
      disabled: false,
      containerProps: undefined,
    },
    valid: {
      variants: ["outlined"],
      sizes: ["md"],
      colors: ["blue-gray"],
    },
    styles: {
      base: {
        container: {
          position: "relative",
          width: "w-full",
          minWidth: "min-w-[200px]",
          height: "h-[3.5rem]",
        },
        select: {
          peer: "peer",
          width: "w-full",
          height: "h-full",
          bg: "bg-transparent",
          color: "text-blue-gray-700",
          fontFamily: "font-lato",
          fontWeight: "font-normal",
          textAlign: "text-left",
          outline: "outline outline-0 focus:outline-0",
          disabled: "disabled:bg-blue-gray-50 disabled:border-0",
          transition: "transition-all",
        },
        arrow: {
          initial: {
            display: "grid",
            placeItems: "place-items-center",
            position: "absolute",
            top: "top-2/4",
            right: "right-2",
            pt: "pt-px",
            width: "w-5",
            height: "h-5",
            color: "text-white",
            transform: "rotate-0 -translate-y-2/4",
            transition: "transition-all",
          },
          active: {
            transform: "rotate-180",
            mt: "mt-px",
          },
        },
        label: {
          display: "flex",
          width: "w-full",
          height: "h-full",
          userSelect: "select-none",
          pointerEvents: "pointer-events-none",
          position: "absolute",
          left: "left-0",
          fontWeight: "font-normal",
          transition: "transition-all",
        },
        menu: {
          width: "w-full",
          maxHeight: "max-h-96",
          bg: "bg-[#202020]",
          p: "p-3",
          border: "border-none",
          borderRadius: "rounded-md",
          boxShadow: "shadow-lg shadow-blue-gray-500/10",
          fontFamily: "font-sans",
          fontSize: "text-sm",
          fontWeight: "font-normal",
          color: "text-[#fff]",
          overflow: "overflow-auto",
          outline: "focus:outline-none",
        },
        option: {
          initial: {
            pt: "pt-[9px]",
            pb: "pb-2",
            px: "px-3",
            borderRadius: "rounded-md",
            lightHeight: "leading-tight",
            cursor: "cursor-pointer",
            userSelect: "select-none",
            background: "hover:bg-none focus:bg-none",
            opacity: "hover:bg-opacity-80 focus:bg-opacity-80",
            color: "hover:text-[#fff] focus:text-[#fff]",
            outline: "outline outline-0",
            transition: "transition-all",
          },
          active: {
            bg: "bg-gradient-to-r from-[#F4795A] to-[#F7336767] ",
            color: "text-white",
          },
          disabled: {
            opacity: "opacity-50",
            cursor: "cursor-not-allowed",
            userSelect: "select-none",
            pointerEvents: "pointer-events-none",
          },
        },
      },
      variants: {
        outlined: {
          base: {
            select: {
              borderWidth: "border",
              borderColor: "border-blue-gray-200",
            },
            label: {
              position: "-top-1.5",
              before: {
                content: "before:content[' ']",
                display: "before:block",
                boxSizing: "before:box-border",
                width: "before:w-2.5",
                height: "before:h-1.5",
                mt: "before:mt-[6.5px]",
                mr: "before:mr-1",
                borderRadius: "before:rounded-tl-md",
                pointerEvents: "before:pointer-events-none",
                transition: "before:transition-all",
                disabled: "peer-disabled:before:border-transparent",
              },
              after: {
                content: "after:content[' ']",
                display: "after:block",
                flexGrow: "after:flex-grow",
                boxSizing: "after:box-border",
                width: "after:w-2.5",
                height: "after:h-1.5",
                mt: "after:mt-[6.5px]",
                ml: "after:ml-1",
                borderRadius: "after:rounded-tr-md",
                pointerEvents: "after:pointer-events-none",
                transition: "after:transition-all",
                disabled: "peer-disabled:after:border-transparent",
              },
            },
          },
          sizes: {
            md: {
              container: {
                height: "h-10",
              },
              select: {
                fontSize: "text-sm",
                px: "px-3",
                py: "py-2.5",
                borderRadius: "rounded-[7px]",
              },
              label: {
                initial: {},
                states: {
                  close: {
                    lineHeight: "leading-[3.75]",
                  },
                  open: {
                    lineHeight: "leading-tight",
                  },
                  withValue: {
                    lineHeight: "leading-tight",
                  },
                },
              },
            },
          },
          colors: {
            "blue-gray": {
              close: {
                borderColor: "border-blue-gray-200",
              },
              open: {
                borderColor: "border-blue-gray-500",
              },
              withValue: {
                borderColor: "border-blue-gray-200",
              },
            },
          },
          states: {
            close: {
              select: {
                borderWidth: "border",
                borderColor: "border-blue-gray-200",
              },
              label: {
                fontSize: "text-lg",
                disabled: "peer-disabled:text-blue-gray-400",
                before: {
                  borderColor: "before:border-blue-gray-200",
                },
                after: {
                  borderColor: "after:border-blue-gray-200",
                },
              },
            },
            open: {
              select: {
                borderWidth: "border-2",
                borderColor: "border-blue-gray-500",
              },
              label: {
                fontSize: "text-[11px]",
                disabled: "peer-disabled:text-transparent",
                before: {
                  borderColor: "before:border-blue-gray-500",
                },
                after: {
                  borderColor: "after:border-blue-gray-500",
                },
              },
            },
            withValue: {
              select: {
                borderWidth: "border",
                borderColor: "border-blue-gray-200",
              },
              label: {
                fontSize: "text-[11px]",
                disabled: "peer-disabled:text-transparent",
                before: {
                  borderColor: "before:border-blue-gray-200",
                },
                after: {
                  borderColor: "after:border-blue-gray-200",
                },
              },
            },
          },
          error: {
            select: {
              borderColor: "border-red-500",
            },
            label: {
              color: "text-red-500",
              before: "before:border-red-500",
              after: "after:border-red-500",
            },
          },
          success: {
            select: {
              borderColor: "border-green-500",
            },
            label: {
              color: "text-green-500",
              before: "before:border-green-500",
              after: "after:border-green-500",
            },
          },
        },
      },
    },
  },
};
const AddBeneficiaryModal = ({ isOpenModal, setIsOpenModal, type }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [categoryRent, setCategroyrent] = useState("RENT");
  // const [isFirstOpen, setIsFirstOpen] = useState(true);
  // const [isSecondOpen, setIsSecondOpen] = useState(false);
  // const [isThirdOpen, setIsThirdOpen] = useState(false);
  const { getAccessToken } = useAuth();
  const [paymentType, setPaymentType] = useState("Bank");
  const { beneficiaryRefetch } = useSelector((state) => state.pay);

  const fileTypes = ["PDF"];

  const [file, setFile] = useState(null);
  const [fileError, SetFileError] = useState(false);
  const handleChange = (file) => {
    setFile(file);
    SetFileError(false);
  };
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    watch,
    formState: { errors, isValid },
  } = useForm();

  const addBenificer = async (data, event) => {
    //

    setIsLoading(true);
    event.preventDefault();

    //Make rental agreement is optionally
    // if (!file) {
    //   SetFileError("This field is required.");
    //   toast("Please select rental agreement");
    //   return;
    // }

    let {
      FirstName,
      bankAccount,
      beneficiary_address,
      beneficiary_email,
      beneficiary_phone,
      ifsc_code,
      pan_number,
      category,

      // document,
    } = data;

    console.log(data, "data");

    let upi;
    //
    if (paymentType === "UPI") {
      upi = bankAccount;
      bankAccount = "";
    }
    const benData = {
      FirstName,
      beneficiary_email,
      beneficiary_address,
      beneficiary_phone,
      payment_info: {
        type: paymentType,
        bankAccount,
        ifsc_code: ifsc_code?.toUpperCase(),
        upi_code: upi,
      },
      pan_number: pan_number.toUpperCase(),
      Category: categoryRent,
    };

    // Create a FormData object
    const formData = new FormData();

    // Append data fields to the FormData object
    // Object.entries(benData).forEach(([key, value]) => {
    //   formData.append(key, value);
    // });

    // eslint-disable-next-line array-callback-return
    Object.keys(benData).map((key) => {
      if (key !== "document") {
        formData.append(
          key,
          typeof benData[key] != "string"
            ? JSON.stringify(benData[key])
            : benData[key].trim()
        );
      }
    });

    // Append the file to the FormData object
    // formData.append("document", document[0]);
    formData.append("document", file);

    //

    try {
      const token = await getAccessToken();
      const response = await fetch(APIUrls.add_benificer, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setIsLoading(false);
      const responseJson = await response.json();

      if (_.isEmpty(responseJson)) {
        return toast.error("Something went wrong !!");
      }

      if (responseJson?.code === 201) {
        // const message = responseJson.response.message;

        toast.success("Profile data added successfully !");
        beneficiaryRefetch();
        setIsOpenModal(false);
        return;
      }

      if (responseJson?.code === 200) {
        // const message = responseJson.response.message;
        toast.success("Profile has been updated Successfully !");
        beneficiaryRefetch();
        setIsOpenModal(false);
        return;
      }
      if (responseJson?.code === 409) {
        const message = responseJson.response.message;
        toast.error(message);
        return;
      }
      if (responseJson?.code === 429) {
        // const message = responseJson.response.message;
        return toast.warning("Beneficiary with this email exists already");
      }

      if (responseJson?.code === 439) {
        // const message = responseJson.response.message;
        return toast.warning("Beneficiary of Limit 5 is reached !");
      }

      if (responseJson?.code === 400) {
        const message = responseJson.response.message;

        if (message === "Pan number is already registered") {
          return toast.error(
            "Beneficiary PAN should not match with user details"
          );
        } else {
          return toast.error(message);
        }
      }

      //
    } catch (error) {
      setIsLoading(false);
      //

      toast.error("Something went Wrong !");
    }
  };

  useEffect(() => {
    if (type !== "profile") {
      setValue("category", type);
      setCategroyrent(type);
    } else {
      setValue("category", categoryRent);
    }
  }, [type]);

  const beneficiaryCategory = watch("category");

  // const handleGoBack = () => {
  //   if (isThirdOpen) {
  //     setIsThirdOpen(false);
  //     setIsSecondOpen(true);
  //   } else if (isSecondOpen) {
  //     setIsSecondOpen(false);
  //     setIsThirdOpen(false);
  //     setIsFirstOpen(true);
  //   }
  // };

  // const getOTPHandler = (data, event) => {
  //   setIsFirstOpen(false);
  //   setIsThirdOpen(false);
  //   setIsSecondOpen(true);
  // };

  // const submitOTP = (data, event) => {
  //   setIsFirstOpen(false);
  //   setIsSecondOpen(false);
  //   setIsThirdOpen(true);
  // };

  return (
    <Modal isOpenModal={isOpenModal} setIsOpenModal={setIsOpenModal}>
      <ThemeProvider value={theme}>
        <div className='bg-[#202020] overflow-y-hidden	 w-[320px] md:w-[900px] rounded-[12px] md:rounded-[25px] border-[1px] border-solid  border-[rgba(255,255,255,0.04)] p-[24px]  md:px-[80px] md:py-[40px] flex flex-col gap-[40px] md:gap-[48px] relative max-h-[85vh] overflow-y-auto scrollbar'>
          {/* <Icon
          icon="radix-icons:cross-circled"
          className="absolute right-[2rem] top-[1rem] text-[1.5rem] cursor-pointer"
          onClick={() => {
            setIsOpenModal(false);
          }}
        /> */}

          <div className=' flex justify-between items-center self-stretch'>
            {/* {isSecondOpen || isThirdOpen ? (
            <Icon
              icon="octicon:arrow-left-16"
              className="text-[1.5rem] text-white cursor-pointer"
              onClick={() => {
                handleGoBack();
              }}
            />
          ) : null} */}

            <p className='flex text-[#FFF] font-[Inter] tex-[20px] md:text-[24px] not-italic font-bold leading-[normal]'>
              Add New Bank Account
            </p>
            <p
              className='cursor-pointer'
              onClick={() => {
                setIsOpenModal(false);
              }}>
              <img src={cross} alt='' />
            </p>
          </div>

          <form
            action=''
            onSubmit={handleSubmit(addBenificer)}
            className='flex flex-col gap-[40px] md:gap-[48px] w-full '>
            <div className=' flex flex-col gap-[24px]'>
              <div className=' flex flex-col md:flex-row md:justify-between items-center gap-[24px] self-stretch'>
                <div className='flex flex-col gap-[1rem] md:w-[48%]'>
                  <label
                    htmlFor='beneficiary_phone'
                    className='text-[#FFF] font-[Inter] text-[16px] md:text-[20px] not-italic font-bold leading-[normal]'>
                    Pan number <span className='text-[#FF2525]'>*</span>
                  </label>
                  <input
                    type='text'
                    className='p-[16px] rounded-[4px] border-[1px] border-[solid] border-[rgba(255,255,255,0.20)] bg-transparent outline-none  text-[rgba(255,_255,_255,_0.70)] font-[Inter] tex-[16px] md:text-[18px] not-italic font-bold leading-[normal] '
                    placeholder='Enter PAN Number'
                    {...register("pan_number", {
                      required: "This field is required.",
                      minLength: {
                        value: 10,
                        message: "This minimum length should be 3.",
                      },
                      maxLength: {
                        value: 14,
                        message:
                          "The maxium length should be 10 along with country code.",
                      },
                    })}
                  />

                  {errors?.pan_number && (
                    <div className='text-[0.9rem] mt-[0.5rem] gap-[0.5rem] text-[#EBEAEA] bg-[#DA4633] flex flex-row items-center rounded-[12px] py-[0.8rem] px-[1rem]'>
                      <Icon
                        icon='material-symbols:info-rounded'
                        className='text-[1.5rem]'
                      />
                      <span>{errors?.pan_number?.message}</span>
                    </div>
                  )}
                </div>

                {categoryRent === "RENT" && (
                  <div className='flex flex-col gap-[1rem] md:w-[48%]'>
                    <label
                      htmlFor='document'
                      className='text-[#FFF] font-[Inter] text-[16px] md:text-[20px] not-italic font-bold leading-[normal] '>
                      Upload Rental agreement
                    </label>
                    {/* <input
                  type="file"
                  className="p-[16px] rounded-[4px] border-[1px] border-[solid] border-[rgba(255,255,255,0.20)] bg-transparent outline-none  text-[rgba(255,_255,_255,_0.70)] font-[Inter] tex-[12px] md:text-[14px] not-italic font-bold leading-[normal]"
                  {...register("document", {
                    required: "This field is required.",
                    minLength: {
                      value: 3,
                      message: "This minimum length should be 3.",
                    },
                    validate: (value) => {
                      const acceptedFormats = ["pdf"];
                      const fileExtension = value[0]?.name
                        .split(".")
                        .pop()
                        .toLowerCase();
                      if (!acceptedFormats.includes(fileExtension)) {
                        return "Invalid file format. Only PDF files are allowed.";
                      }
                      return true;
                    },
                  })}
                /> */}
                    {/** Rental agreement make it optional */}
                    <FileUploader
                      // required
                      handleChange={handleChange}
                      name='file'
                      types={fileTypes}
                      maxSize={25}
                      children={
                        <>
                          <div className='cursor-pointer px-[16px] py-[10px] rounded-[6px] border-[1px] border-dotted	 border-[rgba(255,255,255,0.20)] '>
                            <div className='flex gap-2 justify-center items-center'>
                              <div>
                                <img src={uploadImage} alt='' />
                              </div>
                              {file ? (
                                <div>
                                  <span className='font-medium text-[#F55960]'>
                                    {file?.name}
                                  </span>
                                </div>
                              ) : (
                                <div className='text-[rgba(255,_255,_255,_0.70)] font-[Inter] text-[14px] not-italic font-normal leading-[normal]'>
                                  <div className='text-center'>
                                    <span className='font-medium text-[#F55960]'>
                                      Click to Upload
                                    </span>
                                    <span> or drag and drop</span>
                                  </div>
                                  <div className='text-center'>
                                    (Max. File size: 25 MB)
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </>
                      }
                    />
                    {fileError && (
                      <div className='text-[0.9rem] mt-[0.5rem] gap-[0.5rem] text-[#EBEAEA] bg-[#DA4633] flex flex-row items-center rounded-[12px] py-[0.8rem] px-[1rem]'>
                        <Icon
                          icon='material-symbols:info-rounded'
                          className='text-[1.5rem]'
                        />
                        <span>{fileError}</span>
                      </div>
                    )}
                    

                    {/* {errors?.document && (
                  <div className="text-[0.9rem] mt-[0.5rem] gap-[0.5rem] text-[#EBEAEA] bg-[#DA4633] flex flex-row items-center rounded-[12px] py-[0.8rem] px-[1rem]">
                    <Icon
                      icon="material-symbols:info-rounded"
                      className="text-[1.5rem]"
                    />
                    {
                    <span>{errors?.document?.message}</span>
                  </div>
                )} */}
                  </div>
                )}
              </div>

              {/* pan card details */}

              {/* <div className='flex flex-col md:flex-row md:justify-between items-center gap-[24px] self-stretch'>
              <div className='flex flex-col gap-[1rem] md:w-[48%]'>
                <label
                  htmlFor='FirstName'
                  className='text-[#FFF] font-[Inter] text-[16px] md:text-[20px] not-italic font-bold leading-[normal]'>
                  Pan Card<span className='text-[#FF2525]'>*</span>
                </label>
                <div className='flex flex-row items-center w-full  border-[1px] border-solid border-[rgba(255,255,255,0.20)] '>
                  <input
                    type='text'
                    className='p-[16px] rounded-[4px] bg-transparent outline-none flex-1  text-[rgba(255,_255,_255,_0.70) ] font-[Inter] tex-[16px] md:text-[18px] not-italic font-bold leading-[normal] '
                    placeholder='Enter PAN Number'
                    {...register("pan_number", {
                      required: "This field is required.",
                    })}
                  />
                  <button
                    className='py-[0.5rem] px-[1.5rem]  mr-[2.5rem]'
                    style={{
                      borderRadius: "100px",
                      background:
                        "linear-gradient(90deg, #F4795A 0.04%, #F4705C 15.04%, #F55960 38.04%, #F73367 67.04%, #F90070 100.04%)",
                    }}
                    type='button'>
                    Verify
                  </button>
                </div>

                {errors?.pan_number && (
                  <div className='text-[0.9rem] mt-[0.5rem] gap-[0.5rem] text-[#EBEAEA] bg-[#DA4633] flex flex-row items-center rounded-[12px] py-[0.8rem] px-[1rem]'>
                    <Icon
                      icon='material-symbols:info-rounded'
                      className='text-[1.5rem]'
                    />
                    <span>{errors?.pan_number?.message}</span>
                  </div>
                )}
              </div>
            </div> */}
              {/* pan card details */}

              <div className='flex flex-col md:flex-row md:justify-between items-center gap-[24px] self-stretch'>
                <div className='flex flex-col gap-[1rem] md:w-[48%]'>
                  <label
                    htmlFor='FirstName'
                    className='text-[#FFF] font-[Inter] text-[16px] md:text-[20px] not-italic font-bold leading-[normal]'>
                    Full Name<span className='text-[#FF2525]'>*</span>
                  </label>
                  <input
                    type='text'
                    className='p-[16px] rounded-[4px] border-[1px] border-solid border-[rgba(255,255,255,0.20)] bg-transparent outline-none  text-[rgba(255,_255,_255,_0.70)] font-[Inter] tex-[16px] md:text-[18px] not-italic font-bold leading-[normal] '
                    placeholder='Enter Full Name'
                    {...register("FirstName", {
                      required: "This field is required.",
                      minLength: {
                        value: 3,
                        message: "This minimum length should be 3.",
                      },
                    })}
                  />

                

                  {errors?.FirstName && (
                    <div className='text-[0.9rem] mt-[0.5rem] gap-[0.5rem] text-[#EBEAEA] bg-[#DA4633] flex flex-row items-center rounded-[12px] py-[0.8rem] px-[1rem]'>
                      <Icon
                        icon='material-symbols:info-rounded'
                        className='text-[1.5rem]'
                      />
                      <span>{errors?.FirstName?.message}</span>
                    </div>
                  )}
                </div>

                <div className='flex flex-col gap-[1rem] md:w-[48%]'>
                  <label
                    htmlFor='beneficiary_email'
                    className='text-[#FFF] font-[Inter] text-[16px] md:text-[20px] not-italic font-bold leading-[normal] '>
                    Beneficiary Email<span className='text-[#FF2525]'>*</span>
                  </label>
                  <input
                    type='text'
                    className='p-[16px] rounded-[4px] border-[1px] border-[solid] border-[rgba(255,255,255,0.20)] bg-transparent outline-none  text-[rgba(255,_255,_255,_0.70)] font-[Inter] tex-[16px] md:text-[18px] not-italic font-bold leading-[normal]'
                    placeholder='Enter Email'
                    {...register("beneficiary_email", {
                      required: "This field is required.",
                      minLength: {
                        value: 3,
                        message: "This minimum length should be 3.",
                      },
                    })}
                  />

                  {/* <div className="text-[0.9rem] mt-[0.5rem] gap-[0.5rem] items-start text-[#676767] bg-[#FFEFBA] flex flex-row  rounded-[12px] py-[0.8rem] px-[1rem]">
                  <Icon
                    icon="material-symbols:info-rounded"
                    className="text-[2rem]"
                  />
                  <span>
                    Enter your 12 digit unique aadhar number to verify your
                    identity
                  </span>
                </div> */}

                  {errors?.beneficiary_email && (
                    <div className='text-[0.9rem] mt-[0.5rem] gap-[0.5rem] text-[#EBEAEA] bg-[#DA4633] flex flex-row items-center rounded-[12px] py-[0.8rem] px-[1rem]'>
                      <Icon
                        icon='material-symbols:info-rounded'
                        className='text-[1.5rem]'
                      />
                      <span>{errors?.beneficiary_email?.message}</span>
                    </div>
                  )}
                </div>
              </div>

              <div className='flex flex-col md:flex-row md:justify-between  items-center gap-[24px] self-stretch'>
                <div className='flex flex-col gap-[1rem] md:w-[48%]'>
                  <label
                    htmlFor='beneficiary_address'
                    className='text-[#FFF] font-[Inter] text-[16px] md:text-[20px] not-italic font-bold leading-[normal] '>
                    Address
                  </label>
                  <input
                    type='text'
                    className='p-[16px] rounded-[4px] border-[1px] border-[solid] border-[rgba(255,255,255,0.20)] bg-transparent outline-none  text-[rgba(255,_255,_255,_0.70)] font-[Inter] tex-[16px] md:text-[18px] not-italic font-bold leading-[normal]'
                    placeholder='Enter Address'
                    {...register("beneficiary_address", {
                      required: "This field is required.",
                      minLength: {
                        value: 3,
                        message: "This minimum length should be 3.",
                      },
                    })}
                  />

                  {/* <div className="text-[0.9rem] mt-[0.5rem] gap-[0.5rem] items-start text-[#676767] bg-[#FFEFBA] flex flex-row  rounded-[12px] py-[0.8rem] px-[1rem]">
                  <Icon
                    icon="material-symbols:info-rounded"
                    className="text-[2rem]"
                  />
                  <span>
                    Enter your 12 digit unique aadhar number to verify your
                    identity
                  </span>
                </div> */}

                  {errors?.beneficiary_address && (
                    <div className='text-[0.9rem] mt-[0.5rem] gap-[0.5rem] text-[#EBEAEA] bg-[#DA4633] flex flex-row items-center rounded-[12px] py-[0.8rem] px-[1rem]'>
                      <Icon
                        icon='material-symbols:info-rounded'
                        className='text-[1.5rem]'
                      />
                      <span>{errors?.beneficiary_address?.message}</span>
                    </div>
                  )}
                </div>

                <div className='flex flex-col gap-[1rem] md:w-[48%]'>
                  <label
                    htmlFor='beneficiary_phone'
                    className='text-[#FFF] font-[Inter] text-[16px] md:text-[20px] not-italic font-bold leading-[normal] '>
                    Mobile Number<span className='text-[#FF2525]'>*</span>
                  </label>
                  <input
                    type='text'
                    className='p-[16px] rounded-[4px] border-[1px] border-[solid] border-[rgba(255,255,255,0.20)] bg-transparent outline-none  text-[rgba(255,_255,_255,_0.70)] font-[Inter] tex-[16px] md:text-[18px] not-italic font-bold leading-[normal]'
                    placeholder='Enter Mobile Number'
                    {...register("beneficiary_phone", {
                      required: "This field is required.",
                      minLength: {
                        value: 10,
                        message: "This minimum length should be 3.",
                      },
                      maxLength: {
                        value: 14,
                        message:
                          "The maxium length should be 10 along with country code.",
                      },
                    })}
                  />

                  {/* <div className="text-[0.9rem] mt-[0.5rem] gap-[0.5rem] items-start text-[#676767] bg-[#FFEFBA] flex flex-row  rounded-[12px] py-[0.8rem] px-[1rem]">
                  <Icon
                    icon="material-symbols:info-rounded"
                    className="text-[2rem]"
                  />
                  <span>
                    Enter your 12 digit unique aadhar number to verify your
                    identity
                  </span>
                </div> */}

                  {errors?.beneficiary_phone && (
                    <div className='text-[0.9rem] mt-[0.5rem] gap-[0.5rem] text-[#EBEAEA] bg-[#DA4633] flex flex-row items-center rounded-[12px] py-[0.8rem] px-[1rem]'>
                      <Icon
                        icon='material-symbols:info-rounded'
                        className='text-[1.5rem]'
                      />
                      <span>{errors?.beneficiary_phone?.message}</span>
                    </div>
                  )}
                </div>
              </div>

              {type === "profile" && (
                <div className='flex flex-col gap-[1rem] '>
                  <label
                    htmlFor='beneficiaryCategory'
                    className='text-[#FFF] font-[Inter] text-[16px] md:text-[20px] not-italic font-bold leading-[normal]'>
                    Benificary Category<span className='text-[#FF2525]'>*</span>
                  </label>

                  <Controller
                    name='category'
                    id='beneficiaryCategory'
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        label='Select  Beneficiary Category'
                        value={categoryRent}
                        className='p-[16px]  rounded-[4px]  bg-[#202020] outline-none  text-[#fff] font-[Inter] tex-[16px] md:text-[18px] not-italic font-bold leading-[normal]'
                        onChange={(value) => {
                          if (value) {
                            setCategroyrent(value);
                            console.log(value);
                          }
                        }}>
                        <Option value='EDUCATION'>Education</Option>
                        <Option value='RENT' selected>
                          Rental
                        </Option>
                      </Select>
                    )}
                  />
                </div>
              )}

              {/* <div className='flex flex-col gap-[1rem]'>
              <label
                htmlFor='amount'
                className='text-[#FFF] font-[Inter] text-[16px] md:text-[20px] not-italic font-bold leading-[normal]'>
                Payment Type<span className='text-[#FF2525]'>*</span>
              </label>
              <select
                name='bankacc'
                id='amout'
                disabled
                className='p-[16px] appearance-none rounded-[4px] border-[1px] border-solid border-[rgba(255,255,255,0.20)] bg-[#202020] outline-none  text-[rgba(255,_255,_255,_0.70)] font-[Inter] tex-[16px] md:text-[18px] not-italic font-bold leading-[normal]'
                onChange={(e) => {
                  if (e.target.value) {
                    setPaymentType(e.target.value);
                    // 
                  }
                }}>
                <option value='Bank' selected>
                  Bank
                </option>
              </select>
         
            </div> */}

              <div className=' flex flex-col md:flex-row md:justify-between items-center gap-[24px] self-stretch'>
                <div className='flex flex-col gap-[1rem] md:w-[48%]'>
                  <label
                    htmlFor='bankAccount'
                    className='text-[#FFF] font-[Inter] text-[16px] md:text-[20px] not-italic font-bold leading-[normal]'>
                    Account Number<span className='text-[#FF2525]'>*</span>
                  </label>
                  <input
                    type='text'
                    className='p-[16px] rounded-[4px] border-[1px] border-solid border-[rgba(255,255,255,0.20)] bg-transparent outline-none  text-[rgba(255,_255,_255,_0.70)] font-[Inter] tex-[16px] md:text-[18px] not-italic font-bold leading-[normal]'
                    placeholder='Enter Account Number'
                    {...register("bankAccount", {
                      required: "This field is required.",
                      minLength: {
                        value: 3,
                        message: "This minimum length should be 3.",
                      },
                    })}
                  />

                  {/* <div className="text-[0.9rem] mt-[0.5rem] gap-[0.5rem] items-start text-[#676767] bg-[#FFEFBA] flex flex-row  rounded-[12px] py-[0.8rem] px-[1rem]">
                  <Icon
                    icon="material-symbols:info-rounded"
                    className="text-[2rem]"
                  />
                  <span>
                    Enter your 12 digit unique aadhar number to verify your
                    identity
                  </span>
                </div> */}

                  {errors?.bankAccount && (
                    <div className='text-[0.9rem] mt-[0.5rem] gap-[0.5rem] text-[#EBEAEA] bg-[#DA4633] flex flex-row items-center rounded-[12px] py-[0.8rem] px-[1rem]'>
                      <Icon
                        icon='material-symbols:info-rounded'
                        className='text-[1.5rem]'
                      />
                      <span>{errors?.bankAccount?.message}</span>
                    </div>
                  )}
                </div>

                {paymentType === "Bank" ? (
                  <div className='flex flex-col gap-[1rem] md:w-[48%]'>
                    <label
                      htmlFor='ifsc_code'
                      className='text-[#FFF] font-[Inter] text-[16px] md:text-[20px] not-italic font-bold leading-[normal]'>
                      IFSC<span className='text-[#FF2525]'>*</span>
                    </label>
                    <input
                      type='text'
                      className='p-[16px] rounded-[4px] border-[1px] border-[solid] border-[rgba(255,255,255,0.20)] bg-transparent outline-none  text-[rgba(255,_255,_255,_0.70)] font-[Inter] tex-[16px] md:text-[18px] not-italic font-bold leading-[normal]'
                      placeholder='Enter valid IFSC code'
                      {...register("ifsc_code", {
                        required: "This field is required.",
                        minLength: {
                          value: 3,
                          message: "This minimum length should be 3.",
                        },
                      })}
                    />

                    {/* <div className="text-[0.9rem] mt-[0.5rem] gap-[0.5rem] items-start text-[#676767] bg-[#FFEFBA] flex flex-row  rounded-[12px] py-[0.8rem] px-[1rem]">
                  <Icon
                    icon="material-symbols:info-rounded"
                    className="text-[2rem]"
                  />
                  <span>
                    Enter your 12 digit unique aadhar number to verify your
                    identity
                  </span>
                </div> */}

                    {errors?.ifsc_code && (
                      <div className='text-[0.9rem] mt-[0.5rem] gap-[0.5rem] text-[#EBEAEA] bg-[#DA4633] flex flex-row items-center rounded-[12px] py-[0.8rem] px-[1rem]'>
                        <Icon
                          icon='material-symbols:info-rounded'
                          className='text-[1.5rem]'
                        />
                        <span>{errors?.ifsc_code?.message}</span>
                      </div>
                    )}
                  </div>
                ) : null}
              </div>
            </div>

            {/* submit button */}
            <div className='flex justify-center'>
              <button
                type='submit'
                disabled={isLoading}
                className={`${
                  !isValid || isLoading
                    ? "bg-[linear-gradient(90deg,_rgba(244,121,90,1)_12%,_rgba(244,112,92,1)_33%,_rgba(245,89,96,1)_55%,_rgba(247,51,103,1)_76%,_rgba(249,0,112,1)_100%)]"
                    : "bg-[linear-gradient(90deg,_rgba(244,121,90,1)_12%,_rgba(244,112,92,1)_33%,_rgba(245,89,96,1)_55%,_rgba(247,51,103,1)_76%,_rgba(249,0,112,1)_100%)]"
                } text-center w-full  rounded-[100px] text-white py-[0.5rem] font-[800] flex flex-row items-center w-[15rem] justify-center gap-[0.5rem]`}>
                {isLoading && (
                  <CgSpinner
                    size={`${window.innerWidth < 768 ? 10 : 20}`}
                    className='mt-1 animate-spin'
                  />
                )}
                <span className='text-[#FFF] font-[Inter] text-[16px] md:text-[20px] not-italic font-semibold leading-[normal]'>
                  {" "}
                  Save
                </span>
              </button>
            </div>
          </form>
        </div>
      </ThemeProvider>
    </Modal>
  );
};

export default AddBeneficiaryModal;