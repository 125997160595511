import { Icon } from "@iconify/react/dist/iconify.js";
import React, { useEffect, useState } from "react";

function EditableInputFieldForElectricity({
  closeEditAmount,
  billInfo,
  setBillInfo,
  setErrors,
  errors,
}) {
  const [amount, setAmount] = useState(billInfo?.amount);

  const minAmount = getMinvalue(billInfo); // Example minimum amount
  // const maxAmount = findValueByName(
  //   billInfo?.responseData?.response?.additionalInfo,
  //   "Maximum Permissible Recharge Amount"
  // );
  const maxAmount = 20000.0;

  // 

  function findValueByName(arr, name) {
    const obj = arr.find((item) => item.name === name);
    return obj ? parseFloat(obj.value) : null;
  }

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    // 
    setAmount(inputValue);

    // Update billInfo using the callback function
    setBillInfo((prevBillInfo) => ({
      ...prevBillInfo,
      amount: inputValue,
    }));

    // Validate the input and update errors object
    const errorsCopy = { ...errors };
    if (
      isNaN(inputValue) ||
      isNaN(parseFloat(inputValue)) ||
      parseFloat(inputValue) < minAmount ||
      parseFloat(inputValue) > maxAmount
    ) {
      errorsCopy.amount =
        "Valid amount between " + minAmount + " and " + maxAmount;
    } else {
      delete errorsCopy.amount;
    }
    setErrors(errorsCopy);
  };

  const handleBlur = () => {
    // Validate before saving
    if (!errors.amount) {
      // Perform the save operation here
      // 

      setBillInfo((prevBillInfo) => ({
        ...prevBillInfo,
        amount: amount,
      }));

      // You can close the popup or update the state as needed
      closeEditAmount();
    }
  };

  useEffect(() => {
    setAmount(billInfo?.amount);
  }, [billInfo]);

  return (
    <div className="relative">
      <input
        type="text"
        id="amout"
        value={amount}
        className="w-[130px] p-2 bg-[#202020] border rounded-[5px] outline-none"
        onChange={handleInputChange}
        onBlur={handleBlur}
      />
      <span
        className="absolute cursor-pointer right-1 top-[10px]"
        onClick={() => {
          if (!errors?.amount) {
            closeEditAmount();
          }
        }}
      >
        {!errors?.amount ? (
          <Icon
            icon="typcn:tick"
            width="1.4rem"
            height="1.4rem"
            className="text-[#34b233]"
          />
        ) : (
          <Icon
            icon="charm:circle-cross"
            width="1.3rem"
            height="1.3rem"
            className="text-[#f73367]"
          />
        )}
      </span>
    </div>
  );
}

export default EditableInputFieldForElectricity;

const getMinvalue = (billInfo) => {
  // let additionaInfo = billInfo?.responseData?.response?.additionalInfo;
  // // 

  // const minimumAmount = additionaInfo.find((obj) =>
  //   obj.name.toLowerCase().includes("minimum")
  // );
  // if (minimumAmount) return parseFloat(minimumAmount.value);

  if (billInfo?.responseData?.response?.billerResponse?.amount) {
    return parseFloat(billInfo?.responseData?.response?.billerResponse?.amount);
  }

  return 1.0;
};
