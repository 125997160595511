import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  alltransactions: [],
  transactionOrder: "Newest",
};

export const alltransactionSlice = createSlice({
  name: "allTransaction",
  initialState,
  reducers: {
    storeTransaction(state, action) {
      state.alltransactions = action.payload.alltransactions;
    },
    setTransactionOrder(state, action) {
      state.transactionOrder = action.payload.transactionOrder;
    },
  },
});
