import React from "react";
import Select from "react-select";

function SelectBoxForIndianOilCustomParam({
  customerParams,
  setCustomPara,

  setIsFormOpen,
}) {
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "#131313",
      borderColor: "rgba(255, 255, 255, 0.2)",
      padding: "10px",
      color: state.isSelected ? "#fff" : "#fff",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#3498db" : "#131313",
      color: state.isSelected ? "#fff" : "#fff",
      borderColor: "rgba(255, 255, 255, 0.2)",
    }),
    input: (styles) => ({ ...styles, color: "#fff" }),
    placeholder: (styles) => ({ ...styles, color: "#fff" }),
    singleValue: (styles) => ({ ...styles, color: "#fff" }),
    noOptionsMessage: (styles) => ({
      ...styles,
      color: "#fff",
    }),
    menu: (styles) => ({
      ...styles,
      color: "#fff",
      backgroundColor: "#131313",
      border: "1px solid rgb(255, 255, 255,0.2)",
    }),
  };
  let options = customerParams
    ?.filter((data) =>
      ["Mobile Number", "Consumer ID"].includes(data.paramName)
    )
    .map((datas, index) => {
      return {
        label: datas.paramName,
        value: datas,
      };
    });

  return (
    <div className="flex flex-col gap-[1rem] mb-[40px]">
      <label
        htmlFor="accounId"
        className="text-[#FFF] font-[Inter] text-[1rem] xs:text-[20px] not-italic font-bold leading-[normal]"
      >
        Select options
      </label>
      <Select
        options={options}
        // value={options.find((option) => option.value === value)}
        onChange={(selectedOption) => {
          // 
          let customParaArray = [selectedOption.value];
          setCustomPara(customParaArray);
          //   setIsFormOpen(true);
        }}
        styles={customStyles}
        isSearchable={true}
        classNamePrefix="react-select"
        defaultValue={options[0]}
      />
    </div>
  );
}

export default SelectBoxForIndianOilCustomParam;
