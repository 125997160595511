import { useEffect, useState } from "react";
import "react-circular-progressbar/dist/styles.css";
import { useSelector } from "react-redux";
import { APIUrls } from "../../baseUrl/BaseUrl";
import { useAuth } from "../../stores/AuthContext";

// const KycPendingCard = () => {
//   return (
//     <Link
//       to={"/profile/kyc-verification"}
//       className=" text-[#FFF] font-[Inter] text-[14px] xs:text-[20px] not-italic font-semibold leading-[normal]  md:mb-[0px] self-start flex p-[10px] xs:p-[15px]  flex-col items-start gap-[10px] md:gap-[32px] rounded-[12px] bg-[linear-gradient(90deg,_#F4795A_0.04%,_#F4705C_15.04%,_#F55960_38.04%,_#F73367_67.04%,_#F90070_100.04%)] "
//     >
//       Verify KYC
//     </Link>
//   );
// };
// const EmailPendingCard = () => {
//   return (
//     <Link
//       to={"/profile"}
//       className=" text-[#FFF] font-[Inter] text-[14px] xs:text-[20px] not-italic font-semibold leading-[normal]  md:mb-[0px] self-start flex p-[10px] xs:p-[15px]  flex-col items-start gap-[10px] md:gap-[32px] rounded-[12px] bg-[linear-gradient(90deg,_#F4795A_0.04%,_#F4705C_15.04%,_#F55960_38.04%,_#F73367_67.04%,_#F90070_100.04%)] "
//     >
//       Verify Email
//     </Link>
//   );
// };
const KycStatusCard = () => {
  const { emailVerified } = useSelector((state) => state.userData);
  // const { panVerified, aadhaarVerified } = useSelector((state) => state.pay);
  const { currentUser } = useAuth();
  const [kyc_status, setKyc_status] = useState(false);
  // const [kycProcessMessage, setKycProcessMessage] = useState(false);

  // const emailVerified = false;
  // const panVerified = true;
  // const aadhaarVerified = true;
  useEffect(() => {
    const eventSource = new EventSource(APIUrls.KYC_verify + currentUser?.uid);
    eventSource.onopen = (e) => {
      // 
    };
    eventSource.onmessage = (e) => {
      // 
      // 
    };
    eventSource.addEventListener("ping", (e) => {
      // 
      const messageData = JSON.parse(e.data);
      // 
      setKyc_status(messageData.kyc_status);
      if (messageData.kyc_status) {
        eventSource.close();
      }
    });

    return () => eventSource.close();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // function showStatusCards() {
  //   if (!emailVerified && kyc_status) {
  //     // Email is not verified, kyc verified
  //     return <EmailPendingCard />;
  //   } else if (emailVerified && !kyc_status) {
  //     //  kyc not verified
  //     return <KycPendingCard />;
  //   } else {
  //     return (
  //       <>
  //         <KycPendingCard />
  //         <EmailPendingCard />
  //       </>
  //     );
  //   }
  // }
  // const checkKycStatus = async () => {
  //   const token = await getAccessToken();
  //   await axios
  //     .get(APIUrls.KYC_verify + currentUser?.uid, {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //         "Content-Type": "application/json",
  //       },
  //     })
  //     .then((res) => {
  //       setKyc_status(res.data.kyc_status);
  //     })
  //     .catch((err) => {
  //       toast.error("Something went wrong checking status of kyc !!");
  //     });
  // };
  // const requestKyc = async () => {
  //   if (window.Digio) {
  //     try {
  //       const token = await getAccessToken();
  //       const response = await axios.post(
  //         APIUrls.requestKYC,
  //         {},
  //         {
  //           headers: {
  //             Authorization: `Bearer ${token}`,
  //             "Content-Type": "application/json",
  //           },
  //         }
  //       );
  //       if (response.status === 200 && response.data.response) {
  //         // 
  //         // setKycProcessMessage("Kyc Window started Please complete Process");
  //         let kycresponse = response.data.response;
  //         let requestId = kycresponse.requestId;
  //         let identifier = kycresponse.identifier;
  //         let token_id = kycresponse.token_id;
  //         let degioenv = "sandbox";
  //         if (APIUrls.ENV_TYPE === "PROD" || APIUrls.ENV_TYPE === "STAGING") {
  //           degioenv = "production";
  //         }
  //         let options = {
  //           environment: degioenv,
  //           callback: function (response) {
  //             // 
  //             if (response.hasOwnProperty("error_code")) {
  //               // setKycProcessMessage(false);
  //               return 
  //             }
  //             checkKycStatus();
  //             // alert("Your KYC request is processing Wait for some second");
  //           },
  //           // reeturedirect_url: "https://www.staging.payinstacard.com/",

  //           // event_filter: {
  //           //   events: [
  //           //     "kyc.initiated",
  //           //     "kyc.already_completed",
  //           //     "kyc.completed",
  //           //     "kyc.cancelled_by_user",
  //           //   ], // Check table 1.2 for event names
  //           // },

  //           event_listener: async (event) => {
  //             // 
  //             if (event.event === "kyc.completed") {
  //               // setKycProcessMessage("Thanks for completing Kyc Process");
  //             }
  //           },
  //         };

  //         var digio = new window.Digio(options);
  //         digio.init();
  //         digio.submit(requestId, identifier, token_id);
  //       } else {
  //         // 
  //       }
  //     } catch (error) {
  //       // 
  //       toast.error("KYC Request Fail, Please try again ");
  //     }
  //   }
  // };

  // const value = 1;
  // const percentage = (value / 3) * 100;

  // 
  if (emailVerified && kyc_status) {
    //  Both verified
    return;
  } else {
    return null;

    // <div className="flex justify-start gap-3 mt-[10px] xs:mt-[20px] md:mt-[40px]">

    //   {/* {showStatusCards()} */}
    // </div>

    // <div
    //   className='p-[1.5rem] font-inter flex flex-col  gap-[1.5rem] lg:flex-row lg:justify-between lg:items-center'
    //   style={{
    //     borderRadius: "16px",
    //     border: "2px solid #FEA400",
    //     background: "var(--Dark-Card, #202020)",
    //     boxShadow: "0px 0px 20px 0px rgba(254, 164, 0, 0.20)",
    //   }}>
    //   <div className='flex flex-col gap-[0.75rem] lg:flex-row'>
    //     <Icon
    //       icon='material-symbols:info-rounded'
    //       className='text-[#FEA400] text-[1.5rem] self-start'
    //     />
    //     <div className='flex flex-row items-center  h-auto gap-[1.25rem]'>
    //       <div className='h-[5rem] w-[5rem] '>
    //         <CircularProgressbar
    //           value={percentage}
    //           text={`${value}/3`}
    //           styles={{
    //             root: {
    //               height: "100%",
    //               width: "100%",
    //             },

    //             path: {
    //               stroke: `#00C44E`,

    //               strokeLinecap: "round",

    //               transition: "stroke-dashoffset 0.5s ease 0s",

    //               transform: "rotate(0.50turn)",
    //               transformOrigin: "center center",
    //             },
    //             // Customize the circle behind the path, i.e. the "total progress"
    //             trail: {
    //               // Trail color
    //               stroke: "#424242",
    //               // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
    //               strokeLinecap: "butt",
    //               // Rotate the trail
    //               transform: "rotate(0.50turn)",
    //               transformOrigin: "center center",
    //             },

    //             text: {
    //               fill: "#fff",

    //               fontSize: "32px",
    //               fontWeight: "bold",
    //               fontFamily: "Inter",
    //             },

    //             background: {
    //               fill: "#424242",
    //             },
    //           }}
    //         />
    //       </div>
    //       <div className='flex flex-col gap-[0.75rem] '>
    //         <h2 className='text-white text-[1rem] lg:text-[1.5rem] font-bold'>
    //           Complete Verification
    //         </h2>
    //         <p className='text-[0.75rem] lg:text-[0.9rem] text-white font-normal'>
    //           Finish your Email and KYC verification for uninterrupted
    //           payments
    //         </p>
    //       </div>
    //     </div>
    //   </div>
    //   <div className='flex flex-col gap-[1rem] sm:flex-row'>
    //     <button
    //       type='button'
    //       className='w-full lg:h-[3.5rem] lg:px-[4rem] py-[0.5rem] text-white text-[0.875rem] font-semibold whitespace-nowrap'
    //       style={{
    //         borderRadius: "12px",
    //         background:
    //           "linear-gradient(90deg, #F4795A 0.04%, #F4705C 15.04%, #F55960 38.04%, #F73367 67.04%, #F90070 100.04%)",
    //       }}>
    //       Verify Email
    //     </button>
    //     <button
    //       onClick={() => {
    //         requestKyc();
    //       }}
    //       type='button'
    //       className='w-full lg:h-[3.5rem] lg:px-[4rem] py-[0.5rem] text-white text-[0.875rem] font-semibold whitespace-nowrap'
    //       style={{
    //         borderRadius: "12px",
    //         background:
    //           "linear-gradient(90deg, #F4795A 0.04%, #F4705C 15.04%, #F55960 38.04%, #F73367 67.04%, #F90070 100.04%)",
    //       }}>
    //       Verify KYC
    //     </button>
    //   </div>
    // </div>
  }
};

export default KycStatusCard;
