import axios from "axios";
import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { APIUrls } from "../../../baseUrl/BaseUrl";
import { toast } from "react-toastify";
import { useAuth } from "../../../stores/AuthContext";
import { CgSpinner } from "react-icons/cg";
import EmailOTP from "../../modals/EmailOTP";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { fetchUser } from "../../../stores/UserRedux";
import { Icon } from "@iconify/react";

const SignupSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  lastName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
});

function RegistrationForm({ secondStep }) {
  const [step, setStep] = useState(secondStep ? secondStep : 1);

  const [isLoading, setIsLoading] = useState(false);
  const [otpuuids, setotpuuids] = useState("");
  const [timer, setTimer] = useState(60);
  const [email, setEmail] = useState("");

  const { logout, currentUser, getAccessToken, updateUsers } = useAuth();
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const logoutHandler = async (e) => {
    e.preventDefault();
    await logout();
    navigation("/");
  };

  const CreateUser = async (firstName, lastName) => {
    try {
      setIsLoading(true);

      const token = await getAccessToken();
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      await axios
        .post(
          APIUrls.createUser,
          {
            FirstName: firstName,
            LastName: lastName,
          },
          config
        )
        .then((data) => {
          setIsLoading(false);
          const { code, response, message } = data.data;

          if (data.status === 200) {
            if (code === 200) {
              setStep(2);
              //return toast.success("Successfully user create");
            }
          }

          if (data.status === 400) {
            if (code === 400) {
              return toast.error(message);
            }
          }
        })
        .catch((err) => {
          setIsLoading(false);
         

          return toast.error("Something went wrong Please try again later !");
        });
    } catch (errr) {
      setIsLoading(false);
      return toast.error("Something went wrong Please try again later !");
    }
  };

  const SendVerify = async (email) => {
    setTimer(60);
    try {
      setIsLoading(true);

      const token = await getAccessToken();
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      await axios
        .post(
          APIUrls.email_verify,
          {
            email: email,
          },
          config
        )
        .then((data) => {
          setIsLoading(false);
          const { code, response, message } = data.data;

          if (data.status === 200) {
            if (code === 200) {
              const uid = response.OTP_Id;
              setotpuuids(uid);

              setStep(3);
              return toast.success("An OTP has been sent to your mail id");
            }
          }

          if (data.status === 400) {
            if (code === 400) {
              return toast.error(message);
            }
          }
        })
        .catch((err) => {
          setIsLoading(false);

          return toast.error("Something went wrong Please try again later !");
        });
    } catch (errr) {
      setIsLoading(false);
      return toast.error("Something went wrong Please try again later !");
    }
  };

  const submitOTPs = async (otp) => {
    //

    try {
      setIsLoading(true);

      const token = await getAccessToken();
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      await axios
        .post(
          APIUrls.submit_otp_email,
          {
            otp: otp,
            uu_id: otpuuids,
          },
          config
        )
        .then(async (data) => {
          setIsLoading(false);
          const { code, message } = data.data;

          if (data.status === 200) {
            if (code === 200) {
              toast.success("Email has been verified successfully");

              await updateUsers();

              dispatch(fetchUser(token));

              return navigation("/");
            }
          }

          if (data.status === 400) {
            if (code === 400) {
              const message = "Otp verification failed";
              return toast.error(message);
            }
          }
        })
        .catch((err) => {
          setIsLoading(false);
          console.error(err);
          // setIsOpenModal(false);
          return toast.error("Something went wrong Please try again later !");
        });
    } catch (errr) {
      setIsLoading(false);

      // setIsOpenModal(false);
      return toast.error("Something went wrong Please try again later !");
    }
  };

  useEffect(() => {
    let intervalId;

    if (timer > 0) {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    return () => {
      clearInterval(intervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timer]);

  return (
    <div>
      <div className="fixed z-[1] inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
        <div className="bg-[#131313]  px-4 py-9 xs:py-10 xs:px-8 rounded-lg shadow-lg w-[300px] xs:w-[500px]">
          {step === 1 && (
            <>
              <div className=" flex justify-between items-center mb-[10px] mt-[-20px] ">
                <Icon
                  icon="octicon:arrow-left-16"
                  className="invisible text-[1.5rem] text-white cursor-pointer"
                  onClick={() => {}}
                />

                <div
                  onClick={logoutHandler}
                  className=" cursor-pointer flex rounded-full  items-center gap-2 p-2 bg-[linear-gradient(90deg,_rgba(244,121,90,1)_12%,_rgba(244,112,92,1)_33%,_rgba(245,89,96,1)_55%,_rgba(247,51,103,1)_76%,_rgba(249,0,112,1)_100%)] "
                >
                  <h3
                    className="text-white text-[0.8rem] xs:text-[1rem] font-medium"
                    type="button"
                  >
                    Logout
                  </h3>
                </div>
              </div>
              <div className="flex justify-center mb-3 xs:mb-[20px]">
                <h2 className="text-xl xs:text-2xl  text-[rgba(244,112,92,1)] font-[Inter]  not-italic font-medium leading-[normal]">
                  Registration
                </h2>
              </div>
              <Formik
                initialValues={{
                  firstName: "",
                  lastName: "",
                }}
                validationSchema={SignupSchema}
                onSubmit={async (values) => {
                  // same shape as initial values

                  // send otp for verification
                  let firstName = values.firstName.trim();
                  let lastName = values.lastName.trim();
                  CreateUser(firstName, lastName);
                }}
              >
                {({ errors }) => (
                  <Form>
                    <div className="mb-[10px] xs:mb-[20px]">
                      <label className="mb-[7px] block text-[rgba(255,_255,_255,_0.70)] font-[Inter]  not-italic font-medium leading-[normal] ">
                        First Name<span className="text-[#F00]">*</span>
                      </label>
                      <Field
                        name="firstName"
                        className=" w-full px-2 py-1 xs:px-4 xs:py-2 text-[#ffffff] bg-transparent font-bold text-[0.8rem] xs:text-[1rem] rounded-[4px] border-[1px] border-[solid] border-[rgba(255,255,255,0.20)]"
                      />
                      {errors.firstName ? (
                        <div className="text-[#F00] text-[0.8rem] xs:text-[1rem]">
                          {errors.firstName}
                        </div>
                      ) : null}
                    </div>
                    <div className="mb-[20px]">
                      <label className="mb-[7px] block text-[rgba(255,_255,_255,_0.70)] font-[Inter]  not-italic font-medium leading-[normal] ">
                        Last Name<span className="text-[#F00]">*</span>
                      </label>
                      <Field
                        name="lastName"
                        className=" w-full px-2 py-1 xs:px-4 xs:py-2 text-[#ffffff] bg-transparent font-bold text-[0.8rem] xs:text-[1rem] rounded-[4px] border-[1px] border-[solid] border-[rgba(255,255,255,0.20)]"
                      />
                      {errors.lastName ? (
                        <div className="text-[#F00] text-[0.8rem] xs:text-[1rem]">
                          {errors.lastName}
                        </div>
                      ) : null}
                    </div>

                    <button
                      type="submit"
                      className={`${
                        isLoading
                          ? "bg-[linear-gradient(90deg,_rgba(244,121,90,1)_12%,_rgba(244,112,92,1)_33%,_rgba(245,89,96,1)_55%,_rgba(247,51,103,1)_76%,_rgba(249,0,112,1)_100%)]"
                          : "bg-[linear-gradient(90deg,_rgba(244,121,90,1)_12%,_rgba(244,112,92,1)_33%,_rgba(245,89,96,1)_55%,_rgba(247,51,103,1)_76%,_rgba(249,0,112,1)_100%)]"
                      } w-full text-center xs:mt-[40px]  rounded-[100px] text-white text-[0.8rem] xs:text-[1rem] font-[500] flex px-[16px] py-[6px] md:px-[40px] md:py-[8px] justify-center items-center gap-[10px]`}
                    >
                      {isLoading && (
                        <CgSpinner
                          size={`${window.innerWidth < 768 ? 10 : 20}`}
                          className="mt-1 animate-spin"
                        />
                      )}
                      Next
                    </button>
                  </Form>
                )}
              </Formik>
            </>
          )}

          {step === 2 && (
            <>
              {" "}
              <div className=" flex justify-between items-center mb-[10px] mt-[-20px] ">
                <Icon
                  icon="octicon:arrow-left-16"
                  className="text-[1.5rem] text-white cursor-pointer"
                  onClick={() => {
                    setStep(step - 1);
                  }}
                />

                <div
                  onClick={logoutHandler}
                  className=" cursor-pointer flex rounded-full  items-center gap-2 p-2 bg-[linear-gradient(90deg,_rgba(244,121,90,1)_12%,_rgba(244,112,92,1)_33%,_rgba(245,89,96,1)_55%,_rgba(247,51,103,1)_76%,_rgba(249,0,112,1)_100%)] "
                >
                  <h3
                    className="text-white text-[0.8rem] xs:text-[1rem] font-medium"
                    type="button"
                  >
                    Logout
                  </h3>
                </div>
              </div>
              <div className="flex justify-center mb-3 xs:mb-[20px]">
                <h2 className="text-xl xs:text-2xl  text-[rgba(244,112,92,1)] font-[Inter]  not-italic font-medium leading-[normal]">
                  Email Verification
                </h2>
              </div>
              <Formik
                initialValues={{
                  email: "",
                }}
                validationSchema={Yup.object().shape({
                  email: Yup.string()
                    .email("Invalid email")
                    .required("Required"),
                })}
                onSubmit={async (values) => {
                  // same shape as initial values

                  // send otp for verification

                  let email = values.email.trim();
                  setEmail(email);

                  SendVerify(email);
                }}
              >
                {({ errors }) => (
                  <Form>
                    <div className="mb-[20px]">
                      <label className="mb-[7px] block text-[rgba(255,_255,_255,_0.70)] font-[Inter]  not-italic font-medium leading-[normal] ">
                        Email<span className="text-[#F00]">*</span>
                      </label>
                      <Field
                        name="email"
                        type="email"
                        className=" w-full px-2 py-1 xs:px-4 xs:py-2 text-[#ffffff] bg-transparent font-bold text-[0.8rem] xs:text-[1rem] rounded-[4px] border-[1px] border-[solid] border-[rgba(255,255,255,0.20)]"
                      />
                      {errors.email ? (
                        <div className="text-[#F00] text-[0.8rem] xs:text-[1rem]">
                          {errors.email}
                        </div>
                      ) : null}
                    </div>
                    <button
                      type="submit"
                      className={`${
                        isLoading
                          ? "bg-[linear-gradient(90deg,_rgba(244,121,90,1)_12%,_rgba(244,112,92,1)_33%,_rgba(245,89,96,1)_55%,_rgba(247,51,103,1)_76%,_rgba(249,0,112,1)_100%)]"
                          : "bg-[linear-gradient(90deg,_rgba(244,121,90,1)_12%,_rgba(244,112,92,1)_33%,_rgba(245,89,96,1)_55%,_rgba(247,51,103,1)_76%,_rgba(249,0,112,1)_100%)]"
                      } w-full text-center xs:mt-[40px] rounded-[100px] text-white text-[0.8rem] xs:text-[1rem] font-[500] flex px-[16px] py-[6px] md:px-[40px] md:py-[8px] justify-center items-center gap-[10px]`}
                    >
                      {isLoading && (
                        <CgSpinner
                          size={`${window.innerWidth < 768 ? 10 : 20}`}
                          className="mt-1 animate-spin"
                        />
                      )}
                      Send OTP
                    </button>
                  </Form>
                )}
              </Formik>
            </>
          )}

          {step === 3 && (
            <>
              {" "}
              <div className=" flex justify-between items-center mb-[10px] mt-[-20px] ">
                <Icon
                  icon="octicon:arrow-left-16"
                  className="text-[1.5rem] text-white cursor-pointer"
                  onClick={() => {
                    setStep(step - 1);
                  }}
                />

                <div
                  onClick={logoutHandler}
                  className=" cursor-pointer flex rounded-full  items-center gap-2 p-2 bg-[linear-gradient(90deg,_rgba(244,121,90,1)_12%,_rgba(244,112,92,1)_33%,_rgba(245,89,96,1)_55%,_rgba(247,51,103,1)_76%,_rgba(249,0,112,1)_100%)] "
                >
                  <h3
                    className="text-white text-[0.8rem] xs:text-[1rem] font-medium"
                    type="button"
                  >
                    Logout
                  </h3>
                </div>
              </div>
              <div className="flex justify-center mb-3 xs:mb-[20px]">
                <h2 className="text-xl xs:text-2xl  text-[rgba(244,112,92,1)] font-[Inter]  not-italic font-medium leading-[normal]">
                  OTP Verification
                </h2>
              </div>
              <p className="text-[rgba(255,_255,_255,_0.70)] mb-3 xs:mb-[20px] font-[Inter] text-[12px] xs:text-[14px] not-italic font-medium leading-[normal]">
                An OTP has been sent to{" "}
                <span className="text-[#FFF] ">&nbsp;{email}</span>
              </p>
              <Formik
                initialValues={{
                  otp: "",
                }}
                validationSchema={Yup.object().shape({
                  otp: Yup.string()
                    .matches(/^\d{6}$/, "OTP must be exactly 6 digits")
                    .required("Required"),
                })}
                onSubmit={async (values) => {
                  // same shape as initial values

                  // send otp for verification

                  let otp = values.otp.trim();

                  submitOTPs(otp);
                }}
              >
                {({ errors }) => (
                  <Form>
                    <div className="mb-[10px] ">
                      <label className="mb-[7px] block text-[rgba(255,_255,_255,_0.70)] font-[Inter]  not-italic font-medium leading-[normal] ">
                        OTP<span className="text-[#F00]">*</span>
                      </label>
                      <Field
                        name="otp"
                        type="otp"
                        className=" w-full px-2 py-1 xs:px-4 xs:py-2 text-[#ffffff] bg-transparent font-bold text-[0.8rem] xs:text-[1rem] rounded-[4px] border-[1px] border-[solid] border-[rgba(255,255,255,0.20)]"
                      />
                      {errors.otp ? (
                        <div className="text-[#F00] text-[0.8rem] xs:text-[1rem]">
                          {errors.otp}
                        </div>
                      ) : null}
                    </div>
                    <div className="flex mb-[20px] ">
                      {timer > 0 ? (
                        <p className="text-[#FFF] font-[Inter] text-[14px] md:text-[16px] not-italic font-semibold leading-[normal] [text-decoration-line:underline]">
                          Resend OTP in {timer} sec{" "}
                        </p>
                      ) : (
                        <button
                          type="button"
                          className="text-[#FFF] text-[0.8rem] xs:text-[1rem] font-bold"
                          onClick={() => {
                            SendVerify(email);
                          }}
                        >
                          Resend OTP
                        </button>
                      )}
                    </div>

                    <button
                      type="submit"
                      className={`${
                        isLoading
                          ? "bg-[linear-gradient(90deg,_rgba(244,121,90,1)_12%,_rgba(244,112,92,1)_33%,_rgba(245,89,96,1)_55%,_rgba(247,51,103,1)_76%,_rgba(249,0,112,1)_100%)]"
                          : "bg-[linear-gradient(90deg,_rgba(244,121,90,1)_12%,_rgba(244,112,92,1)_33%,_rgba(245,89,96,1)_55%,_rgba(247,51,103,1)_76%,_rgba(249,0,112,1)_100%)]"
                      } w-full text-center xs:mt-[40px] rounded-[100px] text-white text-[0.8rem] xs:text-[1rem] font-[500] flex px-[16px] py-[6px] md:px-[40px] md:py-[8px] justify-center items-center gap-[10px]`}
                    >
                      {isLoading && (
                        <CgSpinner
                          size={`${window.innerWidth < 768 ? 10 : 20}`}
                          className="mt-1 animate-spin"
                        />
                      )}
                      Verify OTP
                    </button>
                  </Form>
                )}
              </Formik>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default RegistrationForm;
