import React from "react";
import { CgSpinner } from "react-icons/cg";

const TotalPayButton = ({
  lableAmount,
  loading,
  handleTransaction,
  disabled,
}) => {
  return (
    <button
      type="submit"
      className="bg-[#424242] mt-[80px] xs:mt-[32px] text-center w-full text-[16px] xs:text-[20px] rounded-[30px] text-white py-[0.8rem] font-[800] flex flex-row items-center justify-center gap-[0.5rem]"
      onClick={handleTransaction}
      disabled={disabled}
    >
      {loading && <CgSpinner size={20} className="mt-1 animate-spin" />}
      Total Pay ₹{lableAmount || "0"}
    </button>
  );
};

export default TotalPayButton;
