import React from "react";
import SpinningImg from "../../../src/assets/revamped/spinImg.png";

const LoaderForBBPS = () => {
  return (
    <div className="text-center">
      <p className=" mb-[20px] flex justify-center text-[rgba(255,_255,_255,_0.70)] font-[Inter] text-[16px] md:text-[24px] not-italic font-medium leading-[normal]">
        <img src={SpinningImg} alt="" className="animate-spin-anti" />
      </p>
      <p className="text-[rgba(255,_255,_255,_0.70)] font-[Inter] text-[16px] md:text-[24px] not-italic font-medium leading-[normal]">
        Do not close or Press back during the transaction
      </p>
    </div>
  );
};

export default LoaderForBBPS;
