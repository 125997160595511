import { Icon } from "@iconify/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import failed from "../../assets/revamped/FailedBBPSTransaction.svg";
import CardWrapper from "./CardWrapper";

const PaymentRefund = (props) => {
  const navigate = useNavigate();
  const goBack = (e) => {
    e.preventDefault();
    navigate("/profile/my-transactions");
  };
  return (
    <CardWrapper
      className={
        "px-[1rem] bg-[#F9F9F9] font-inter rounded-[12px] md:rounded-[25px] xs:max-sm:px-[1.5rem] sm:max-md:px-[2.5rem] md:px-[1rem] lg:px-[1.5rem] xl:px-[2.5rem] flex flex-col justify-center gap-[0px] sm:gap-[2rem] py-[1rem] sm:py-[2rem] min-h-[420px] xs:min-h-[500px]"
      }
    >
      {/* <p className="flex text-white flex-row gap-[1rem] justify-between items-center text-black text-[1.2rem] xs:text-[1.5rem] font-bold">
        <Icon
          icon="tabler:arrow-left"
          className="text-[1.5rem] xs:text-[2rem] cursor-pointer"
          onClick={(e) => {
            goBack(e);
          }}
        />{" "}
        <Icon
          icon="bi:question-circle"
          className="text-[1.5rem] xs:text-[2rem] cursor-pointer"
        />{" "}
      </p> */}
      {/* <div className="xs:max-md:px-[2.5rem] md:px-[1rem] lg:px-[2.5rem] flex flex-col gap-[1.5rem]">
        <div className="flex justify-center items-center flex-col">
          <div className="w-fit">
            <img src={failed} alt="" />
          </div>
          <hr className=" border-white w-[60%]" />
        </div>
        <div className="flex flex-col gap-[0.5rem]">
          <p className="text-[#FF4F5B] text-[1rem] sm:text-[1.2rem] font-bold text-center">
            Your payment of ₹{props.amount || 0} was Failed.
          </p>
          <p className="text-[#FF4F5B] text-[1rem] sm:text-[1.2rem] font-bold text-center">
            Processing refund for amount: ₹{props.amount || 0}
          </p>
          <p className="text-[#FF4F5B] text-[1rem] sm:text-[1.2rem] font-bold text-center">
            Refund status: {props.refundStatus || "N/A"}
          </p>
          <p className="text-[#FFF] mb-[14px] text-center  sm:mb-[16px] lg:mb-[32px]  font-[Inter] text-[14px] sm:text-[16px] xl:text-[20px] not-italic font-bold leading-[normal]">
            <span className="text-[#000] font-[Inter] not-italic font-semibold leading-[normal]">
              Transaction ID:{" "}
            </span>
            <span className="text-[rgba(0,_0,_0,_0.60)]">
              {props.keyid || "N/A"}
            </span>
          </p>

          <button
            className="  bg-[linear-gradient(90deg,_#F4795A_0.04%,_#F4705C_15.04%,_#F55960_38.04%,_#F73367_67.04%,_#F90070_100.04%)]
            text-center  font-[Inter] w-full text-[16px] sm:text-[20pxrem] rounded-[30px] text-white py-[0.8rem] font-[800] flex flex-row items-center justify-center gap-[0.5rem]"
            onClick={(e) => goBack(e)}
          >
            <span className="text-[16px] sm:text-[20px]">Back</span>
          </button>
        </div>
      </div> */}

      <div className="flex flex-col md:flex-row  md:items-center md:justify-center px-[0px] sm:px-[40px] lg:px-[60px] xl:px-[80px] gap-[20px] md:gap-[40px] lg:gap-[60px] xl:gap-[80px] ">
        <div className="">
          <div className="w-full flex justify-center mb-[20px]">
            <img
              src={failed}
              alt=""
              className="w-[90px] sm:w-[120px] lg:w-[150px]"
            />
          </div>

          <div className="text-center">
            <p className="text-[#f00] text-center font-[Inter] mb-[16px] text-[16px] sm:text-[20px] xl:text-[24px] not-italic font-bold leading-[normal]">
              Your payment of ₹{props.amount || 0} was Failed.
            </p>
            <p className="text-[#f00] text-center font-[Inter] mb-[16px] text-[16px] sm:text-[20px] xl:text-[24px] not-italic font-bold leading-[normal]">
              Processing refund for amount: ₹{props.amount || 0}
            </p>

            <p className="text-[#000] text-center font-[Inter] mb-[16px] text-[16px] sm:text-[20px] xl:text-[24px] not-italic font-bold leading-[normal]">
              Refund status: {props.refundStatus || "N/A"}
            </p>
          </div>

          <button
            className="  bg-[linear-gradient(90deg,_#F4795A_0.04%,_#F4705C_15.04%,_#F55960_38.04%,_#F73367_67.04%,_#F90070_100.04%)]
            text-center  font-[Inter] w-full text-[16px] sm:text-[20pxrem] rounded-[30px] text-white py-[0.8rem] font-[800] flex flex-row items-center justify-center gap-[0.5rem]"
            onClick={(e) => goBack(e)}
          >
            <span className="text-[16px] sm:text-[20px]">HOME</span>
          </button>

          {/* </button> */}
        </div>
      </div>
    </CardWrapper>
  );
};

export default PaymentRefund;
