import React, { useState } from "react";

function CreditCardAmountSection({
  billInfo,
  setBillInfo,
  openEditAmount,
  closeEditAmount,
  setErrors,
}) {
  const [selectedValue, setSelectedValue] = useState(false);

  // Define handleChange function
  const handleChange = (event) => {
    let inputValue = event.target.value;
    // 
    if (event.target.name === "Any Amount") {
      openEditAmount();
    } else {
      closeEditAmount();
      setErrors({});
    }
    setSelectedValue(event.target.value);

    // Update billInfo using the callback function
    setBillInfo((prevBillInfo) => ({
      ...prevBillInfo,
      amount: inputValue,
    }));
  };

  // 

  const additionalInfo = billInfo?.responseData?.response?.additionalInfo;
  let radioButtons = [
    // {
    //   name: "MinimumDueAmount",
    //   value: "527.49",
    // },
    // {
    //   name: "TotalDueAmount",
    //   value: "1000.00",
    // },
    // {
    //   name: "MinimumPayableAmount",
    //   value: "200.00",
    // },
    // {
    //   name: "UnbilledAmount",
    //   value: "300.00",
    // },
    // {
    //   name: "CurrentOutstandingAmount",
    //   value: "1500.00",
    // },
  ];
  // eslint-disable-next-line array-callback-return
  additionalInfo?.find((obj) => {
    let outStandingdata = obj.name.toLowerCase().includes("outstanding")
      ? obj
      : null;
    if (outStandingdata) {
      radioButtons.push({
        ...outStandingdata,
        label: "Current Outstanding Amount",
      });
    }

    let totalDueData = obj.name.toLowerCase().includes("total") ? obj : null;
    if (totalDueData) {
      radioButtons.push({ ...totalDueData, label: "Total Due Amount" });
    }

    let unbilleData = obj.name.toLowerCase().includes("unbilled") ? obj : null;
    if (unbilleData) {
      radioButtons.push({ ...unbilleData, label: "Unbilled Amount" });
    }

    let minimumData = obj.name.toLowerCase().includes("minimum") ? obj : null;
    if (minimumData) {
      radioButtons.push({ ...minimumData, label: "Minimum due amount" });
    }

    radioButtons.push({
      name: "AnyAmount",
      value: billInfo
        ? billInfo?.responseData?.response?.billerResponse?.amount
        : "",
      label: "Any Amount",
    });
  });

  // 

  return (
    <div>
      <h4 className="text-[rgba(255,_255,_255,_0.70)] font-[Inter] text-[16px] xs:text-[20px] mb-5">
        How much do you want to pay?
      </h4>

      {radioButtons?.map((option, index) => (
        <div
          key={index}
          className="flex items-center gap-2 mb-3 text-[rgba(255,_255,_255,_0.70)] font-[Inter] text-[16px] xs:text-[20px] "
        >
          <input
            type="radio"
            name={option.label}
            value={option.value}
            checked={selectedValue === option.value}
            onChange={handleChange}
            className="text-[#f00] w-6"
            id={option.name}
          />
          <label htmlFor={option.name}>
            {option.label || option.name}
            {option.label !== "Any Amount" && (
              <span className="text-white"> - {option.value}</span>
            )}
          </label>
        </div>
      ))}
    </div>
  );
}

export default CreditCardAmountSection;
