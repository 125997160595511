import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useAuth } from "../../stores/AuthContext";

import BharatBillLogo from "../../../src/assets/revamped/BharatBillPaymentLogo.svg";
import BharatBillB from "../../../src/assets/revamped/BhartBillB.svg";
import BroadbandLogo from "../../../src/assets/revamped/BroadbandLogo.svg";
import DthLogo from "../../../src/assets/revamped/DthLogo.svg";
import fasTagLogo from "../../../src/assets/revamped/FasTagLogo.svg";
import HouseRent from "../../../src/assets/revamped/HouseRent.svg";
import SchoolFee from "../../../src/assets/revamped/SchoolIcon.svg";
import CollegeFee from "../../../src/assets/revamped/CollegeIcon.svg";
import LandLineLogo from "../../../src/assets/revamped/LandLineLogo.svg";
import BharatSinglBillLogo from "../../../src/assets/revamped/bbpsBLogo.svg";
import OfficeRent from "../../../src/assets/revamped/building.svg";

import ComingSoonBadge from "../../../src/assets/revamped/ComingSoonBadge.svg";
import CreditCardLogo from "../../../src/assets/revamped/CreditCard.svg";
import ElectricityLogo from "../../../src/assets/revamped/ElectricityLogo.svg";
import LiveBadge from "../../../src/assets/revamped/LiveBadge.svg";
import PipeGasLogo from "../../../src/assets/revamped/PipeGasLogo.svg";
import loanRepayment from "../../assets/Frame 1261154628.svg";
import mobileRecharge from "../../assets/Iphone (1).svg";
import municipalTax from "../../assets/Subtract.svg";
import gasLogo from "../../assets/gas_3063549 1.svg";
import insuranceLogo from "../../assets/material-symbols_shield.svg";
import KycStatusCard from "./KycStatusCard";
import ServicesLogo from "./ServicesLogo";
import axios from "axios";
import { APIUrls } from "../../baseUrl/BaseUrl";
import RegistrationForm from "./login/RegistrationForm";
import EmailOTP from "../modals/EmailOTP";
import { toast } from "react-toastify";
import EmailOTPInHomePage from "../modals/EmailOTPInHomePage";
import CompleteKYCVerificationModal from "../modals/CompleteKYCVerificationModal";

const RentPaymentCardItem = ({
  kyc_status,
  setShowKycCompleteModal,
  icon,
  name,
  linkto,
  currentUser,
}) => {
  const blockHtml = (
    <>
      <img
        src={icon}
        alt=""
        className="w-[24px] h-[20px] xs:w-auto xs:h-auto"
      />
      <span className="text-center font-[Inter] text-[14px] xs:text-[16px] not-italic font-medium leading-[normal]">
        {name}
      </span>
    </>
  );

  return (
    <div className="">
      {currentUser && kyc_status !== true ? (
        <button
          onClick={() => setShowKycCompleteModal(true)}
          className="flex min-w-[100px] xs:min-w-[113px] p-[8px] xs:p-[12px] flex-col items-center gap-[12px] xs:gap-[16px] hover:bg-[linear-gradient(90deg,_#F4795A_0.04%,_#F4705C_15.04%,_#F55960_38.04%,_#F73367_67.04%,_#F90070_100.04%)] rounded-[8px]"
        >
          {blockHtml}
        </button>
      ) : (
        <NavLink
          to={linkto}
          className="flex min-w-[100px] xs:min-w-[113px] p-[8px] xs:p-[12px] flex-col items-center gap-[12px] xs:gap-[16px] hover:bg-[linear-gradient(90deg,_#F4795A_0.04%,_#F4705C_15.04%,_#F55960_38.04%,_#F73367_67.04%,_#F90070_100.04%)] rounded-[8px]"
        >
          {blockHtml}
        </NavLink>
      )}
    </div>
  );
};

function HomePage({ kyc_details }) {
  // 
  const [isOpen, setIsOpen] = useState(false);
  const [secondStep, setSecondStep] = useState("");
  const [showKycCompleteModal, setShowKycCompleteModal] = useState(false);
  const { currentUser, getAccessToken } = useAuth();

  let kyc_status = kyc_details?.aadhaarVerified && kyc_details?._PANVerified;
  // 
  // const dispatch = useDispatch();

  const logoData = [
    {
      path: "/bbps/utility/Electricity",
      // path: "/bbpsServices/commingsoon",
      logo: ElectricityLogo,
      label: "Electricity Bill",
    },
    {
      path: "/bbps/recharge/Fastag",
      // path: "/bbpsServices/commingsoon",
      logo: fasTagLogo,
      label: "FASTag",
    },
    {
      path: "/bbps/finance/Loan_Repayment",
      // path: "/bbpsServices/commingsoon",
      logo: loanRepayment,
      label: "Loan Emi",
    },
    {
      path: "/bbps/utility/LPG_Gas",
      // path: "/bbpsServices/commingsoon",
      logo: gasLogo,
      label: "LPG",
    },

    {
      // path:"/bbps",
      path: "/bbpsServices/commingsoon",
      logo: mobileRecharge,
      label: "Mobile Postpaid",
    },
    {
      // path:"/bbps/recharge/DTH_Recharge",
      path: "/bbpsServices/commingsoon",
      logo: DthLogo,
      label: "DTH Recharge",
    },
    {
      path: "/bbps/finance/Credit_Card",
      // path: "/bbpsServices/commingsoon",
      logo: CreditCardLogo,
      label: "Credit Card",
    },
    {
      path: "/bbps/finance/Insurance",
      // path: "/bbpsServices/commingsoon",
      logo: insuranceLogo,
      label: "Insurance",
    },
    {
      //path:"/bbps/recharge/Landline_Postpaid",
      path: "/bbpsServices/commingsoon",
      logo: LandLineLogo,
      label: "Landline Postpaid",
    },

    {
      // path:"/bbps",
      path: "/bbpsServices/commingsoon",
      logo: municipalTax,
      label: "Municipal Taxes",
    },

    // {
    //   // path:"/bbps",
    //   path: "/bbpsServices/commingsoon",
    //   logo: BharatBillB,
    //   label: "Bill Payments",
    // },

    {
      // path:"/bbps/utility/Broadband",
      path: "/bbpsServices/commingsoon",
      logo: BroadbandLogo,
      label: "Broadband",
    },
    // {
    //   // path:"/bbps/utility/Broadband",
    //   path: "/payout/house-rent",
    //   logo: HouseRent,
    //   label: "Rent",
    // },

    {
      // path: "/bbps/utility/LPG_Gas",
      path: "/bbpsServices/commingsoon",
      logo: PipeGasLogo,
      label: "Piped Gas",
    },
    {
      // path:"/bbps",
      path: "/bbpsServices/commingsoon",
      logo: mobileRecharge,
      label: "Mobile Prepaid",
    },

    {
      path: "/bbpsServices",
      // path: "/bbpsServices/commingsoon",
      logo: BharatBillB,
      label: "View All",
    },
  ];

  const isUserExist = async () => {
    try {
      const token = await getAccessToken();
      const response = await axios.get(APIUrls.isUserExist, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      const status = response?.data?.code;
      const data = response?.data?.response;
      if (status === 200) {
        if (data?.EmailVerifiy === false) {
          setIsOpen(true);
          setSecondStep(2);
        }
      }
    } catch (error) {
      // 
      const response = error?.response?.data;

      // open the registraction form
      if (response?.code === 400) {
        setIsOpen(true);
      }
    }
  };
  useEffect(() => {
    isUserExist();
  }, [currentUser]);

  return (
    <>
      {/* Main container div */}
      <div className=" max-w-[1568px] m-auto text-white  rounded-[12px] ">
        {currentUser && kyc_status === true && <KycStatusCard />}
        <div className="md:flex md:justify-between mt-[10px] xs:mt-[20px] md:mt-[40px]">
          {/**For Rent card and education card */}
          <div className="w-full md:w-[27.55%] flex flex-col  md:gap-[25px]">
            <div className="self-stretch	 mb-[20px] md:mb-[0px] self-start	 bg-[#131313] flex p-[16px] xs:p-[20px] md:p-[40px] flex-col items-start gap-[16px] md:gap-[32px] rounded-[12px] ">
              <p className="flex items-center gap-[16px] font-[Inter] text-[16px] xs:text-[24px] not-italic font-bold leading-[normal]">
                <span> Rent Bills</span>{" "}
                <img
                  src={LiveBadge}
                  alt=""
                  className="w-[44px] h-[20px] xs:w-[56px] xs:h-[27px]"
                />
              </p>
              <div className="flex items-center gap-[16px] xs:gap-[32px] w-full xs:justify-around flex-wrap		">
                <RentPaymentCardItem
                  kyc_status={kyc_status}
                  setShowKycCompleteModal={setShowKycCompleteModal}
                  icon={HouseRent}
                  name="House Rent"
                  linkto="/payout/house-rent"
                  currentUser={currentUser}
                />
                <RentPaymentCardItem
                  kyc_status={kyc_status}
                  setShowKycCompleteModal={setShowKycCompleteModal}
                  icon={OfficeRent}
                  name="Office Rent"
                  linkto="/payout/office-rent"
                  currentUser={currentUser}
                />
              </div>
            </div>
            <div className="self-stretch relative	 mb-[20px] md:mb-[0px] self-start	 bg-[#131313] flex p-[16px] xs:p-[20px] md:p-[40px] flex-col items-start gap-[16px] md:gap-[32px] rounded-[12px] ">
              <div className="absolute top-[10px] xs:top-[18px] md:top-[10px] left-0">
                <img
                  src={ComingSoonBadge}
                  alt=""
                  className="w-[63px] h-[15px] xs:w-auto xs:h-auto"
                />
              </div>
              <p className="flex items-center pt-[10px] xs:pt-[35px] gap-[16px] font-[Inter] text-[16px] xs:text-[24px] not-italic font-bold leading-[normal]">
                <span> Education</span>{" "}
                {/* <img
                  src={LiveBadge}
                  alt=""
                  className="w-[44px] h-[20px] xs:w-[56px] xs:h-[27px]"
                /> */}
              </p>
              <div className="flex items-center gap-[16px] xs:gap-[32px] w-full xs:justify-around flex-wrap		">
                <RentPaymentCardItem
                  kyc_status={kyc_status}
                  setShowKycCompleteModal={setShowKycCompleteModal}
                  icon={SchoolFee}
                  name="School fees"
                  linkto="/education/school-fee"
                  currentUser={currentUser}
                />
                <RentPaymentCardItem
                  kyc_status={kyc_status}
                  setShowKycCompleteModal={setShowKycCompleteModal}
                  icon={CollegeFee}
                  name="College fees"
                  linkto="/education/college-fee"
                  currentUser={currentUser}
                />
              </div>
            </div>
          </div>
          {/**For utility card */}
          <div className="relative w-full md:w-[69.89%] bg-[#131313] flex p-[16px]  xs:p-[40px]  flex-col items-start gap-[16px] md:gap-[32px] rounded-[12px]">
            <div className="absolute top-[10px] xs:top-[22px] md:top-[10px] left-0">
              <img
                src={ComingSoonBadge}
                alt=""
                className="w-[63px] h-[15px] xs:w-auto xs:h-auto"
              />
            </div>
            <div className="flex justify-between md:justify-between items-center mt-[10px] xs:mt-[15px] self-stretch">
              <p className=" text-[#FFF] font-[Inter] text-[16px] xs:text-[20px] not-italic font-bold leading-[normal] tracking-[0.32px]">
                Recharge & Pay Bills{" "}
              </p>
              <img src={BharatBillLogo} alt="" className="hidden xs:block" />
              <img src={BharatSinglBillLogo} alt="" className=" xs:hidden" />
            </div>
            {/* <div className="flex flex-wrap justify-center	md:justify-normal	 items-center gap-[15px] md:gap-[40px] self-stretch"> */}
            <div className="grid grid-cols-4  lg:grid-cols-5 justify-items-center		   gap-[15px] md:gap-[40px]">
              {logoData.map((item, index) => (
                <ServicesLogo logoData={item} key={index} />
              ))}
            </div>
          </div>
        </div>
      </div>
      {showKycCompleteModal && (
        <CompleteKYCVerificationModal
          show={true}
          closePopup={() => setShowKycCompleteModal(false)}
        />
      )}
      {isOpen && currentUser && <RegistrationForm secondStep={secondStep} />}
      {/* {isOpenModal && (
        <EmailOTPInHomePage
          isOpenModal={isOpenModal}
          setIsOpenModal={setIsOpenModal}
          otp_uuid={otpuuids}
          email={emails}
          SendVerify={SendVerify}
        />
      )} */}
    </>
  );
}

export default HomePage;
