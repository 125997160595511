import React, { useEffect, useState } from "react";
// import homeRent from "../../assets/homeRent.svg";
// import officeRent from "../../assets/officeRent.svg";
import { Icon } from "@iconify/react";
import { NavLink, Navigate, Outlet, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import houseRent from "../../../src/assets/revamped/homeRent.svg";
import officeRent from "../../../src/assets/revamped/officeRent.svg";
import activeTick from "../../assets/mdi_tick.svg";
import { useAuth } from "../../stores/AuthContext";

function RentCards() {
  const [activeTab, setActiveTab] = useState("");
  const [activeImage, setActiveImage] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  // const navigate = useNavigate();
  const { currentUser } = useAuth();

  // useEffect(() => {
  //   if (currentUser) {
  //     navigate("/payout/house-rent");
  //   }
  // }, []);

  useEffect(() => {
    switch (location.pathname) {
      case "/payout/house-rent":
        setActiveTab("House");
        setActiveImage(houseRent);
        break;

      case "/payout/office-rent":
        setActiveTab("Office");
        setActiveImage(officeRent);
        break;

      default:
    }
  }, [location.pathname]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };
  if (!currentUser) {
    toast.error("please login to continue", {
      toastId: "success1",
    });
    return <Navigate to="/login" />;
  }

  // 

  return (
    <div className=" ">
      <div className=" hidden md:flex px-[80px] py-[40px] items-center gap-[48px] rounded-[25px] border-[1px] border-[solid] border-[rgba(255,255,255,0.04)] bg-[#202020] [box-shadow:0px_0px_20px_0px_rgba(0,_0,_0,_0.10)]">
        <NavLink
          to={"/payout/house-rent"}
          // className={`flex items-center relative  gap-[16px]`}
          onClick={() => handleTabClick("house")}
          className={({ isActive, isPending }) =>
            `flex items-center relative  gap-[16px]  ${
              isActive ? "text-[20px]" : ""
            }  `
          }
        >
          <img src={houseRent} alt="" /> <span>House Rent</span>
          {location.pathname === "/payout/house-rent" && (
            <div className="absolute bottom-[-15px] left-0  w-full h-1 bg-[linear-gradient(90deg,_rgba(244,121,90,1)_12%,_rgba(244,112,92,1)_33%,_rgba(245,89,96,1)_55%,_rgba(247,51,103,1)_76%,_rgba(249,0,112,1)_100%)]"></div>
          )}
        </NavLink>
        <NavLink
          to={"/payout/office-rent"}
          // className="flex items-center relative gap-[16px]"
          onClick={() => handleTabClick("office")}
          className={({ isActive, isPending }) =>
            `flex items-center relative  gap-[16px]  ${
              isActive ? "text-[20px]" : ""
            }  `
          }
        >
          <img src={officeRent} alt="" />
          <span>Office Rent</span>

          {location.pathname === "/payout/office-rent" && (
            <div className="absolute bottom-[-15px] left-0  w-full h-1 bg-[linear-gradient(90deg,_rgba(244,121,90,1)_12%,_rgba(244,112,92,1)_33%,_rgba(245,89,96,1)_55%,_rgba(247,51,103,1)_76%,_rgba(249,0,112,1)_100%)]"></div>
          )}
        </NavLink>
      </div>

      {/* For mobile */}

      <div className="md:hidden bg-[#202020]  rounded-[12px] font-inter ">
        <div
          className="flex bg-[#202020] rounded-[12px] h-[80px] px-[1.5rem] py-[24px] justify-between items-center"
          onClick={toggleDropdown}
        >
          <button
            onClick={toggleDropdown}
            className="flex items-center gap-[16px]"
          >
            <img src={activeImage} alt="" className="h-[2rem] w-[2rem]" />

            <span className="text-[#FFF] font-[Inter] text-[1rem] not-italic font-bold leading-normal">
              {activeTab} Rent
            </span>
          </button>
          <h3 className="flex items-center text-white ">
            {isOpen ? (
              <Icon
                icon="material-symbols:keyboard-arrow-down-rounded"
                className="cursor-pointer  text-[2rem]"
              />
            ) : (
              <Icon
                icon="material-symbols:keyboard-arrow-down-rounded"
                rotate={2}
                className="cursor-pointer text-[2rem]"
              />
            )}
          </h3>
        </div>
        {isOpen ? (
          <div className="px-[1.5rem] py-[1rem]">
            {" "}
            <NavLink
              to={"/payout/house-rent"}
              className={`flex justify-between items-center gap-[16px] mb-3 p-[0.5rem] ${
                activeTab === "House" && "bg-[#272727] rounded-[0.5rem]"
              } `}
              onClick={() => {
                handleTabClick("house");
                toggleDropdown();
              }}
            >
              <div className="flex flex-row gap-[1rem]">
                <img src={houseRent} alt="" className="h-[1.5rem] w-[1.5rem]" />{" "}
                <span>House Rent</span>
              </div>

              {activeTab === "House" && (
                <img
                  src={activeTick}
                  className="h-[1.5rem] w-[1.5rem]"
                  alt="active tick"
                />
              )}
            </NavLink>
            <hr
              style={{
                borderColor: "rgba(255, 255, 255, 0.10)",
              }}
              className="h-[1px] w-full my-[1rem] "
            />
            <NavLink
              to={"/payout/office-rent"}
              className={`flex justify-between items-center gap-[16px] mb-3 p-[0.5rem] ${
                activeTab === "Office" && "bg-[#272727] rounded-[0.5rem]"
              } `}
              onClick={() => {
                handleTabClick("office");
                toggleDropdown();
              }}
            >
              <div className="flex flex-row gap-[1rem]">
                <img
                  src={officeRent}
                  alt=""
                  className="h-[1.5rem] w-[1.5rem]"
                />{" "}
                <span>Office Rent</span>
              </div>

              {activeTab === "Office" && (
                <img
                  src={activeTick}
                  className="h-[1.5rem] w-[1.5rem]"
                  alt="active tick"
                />
              )}
            </NavLink>
          </div>
        ) : (
          <></>
        )}
      </div>

      {/* <NavLink to={"/payout/maintenance"}>

      <PayCategoryCard text={"Maintenance"} image={blackScrew} />
      </NavLink>
      <NavLink to={"/payout/education-fee"}>

      <PayCategoryCard text={"Education Fee"} image={cil_education} />
      </NavLink> */}
      <Outlet />
    </div>
  );
}

export default RentCards;
