import React from "react";
import bbpsLogo from "../../assets/revamped/BharatBillPaymentLogo.svg";

const BbpsHeader = () => {
  return (
    <div className="flex justify-between items-center self-stretch">
      {/* <p>Selected State: {selectedStates}</p>
<p>Selected Biller: {selectedBiller?.billerId}</p> */}
      <p className="text-[#FFF] font-[Inter] text-[16px] not-italic font-bold leading-[normal] tracking-[0.32px]">
        BHARAT BILLPAY PAYMENT SYSTEM
      </p>
      <p>
        <img src={bbpsLogo} alt="" />
      </p>
    </div>
  );
};

export default BbpsHeader;
