import React from "react";
import { Icon } from "@iconify/react";
import { NavLink } from "react-router-dom";

const CompleteKYCVerificationModal = ({ closePopup }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-10 ">
      <div className="bg-[#202020] p-6 border  rounded-md w-96 relative ">
        <div className="flex justify-end absolute top-2 right-2">
          <button onClick={closePopup}>
            <Icon
              icon="charm:cross"
              width="32"
              height="32"
              className="text-white"
            />{" "}
          </button>
        </div>
        <div className=" mt-2 flex justify-center">
          <Icon
            icon="mingcute:user-search-fill"
            width="80"
            height="80"
            className="text-[#f73367]"
          />
        </div>
        <p className="mt-3 text-[18px] text-white text-center">
          To continue using our services, please complete your KYC process.
          Click below to get started.
        </p>
        <NavLink
          to="/profile/kyc-verification"
          className="mt-5 flex justify-center"
        >
          <span
            className=" cursor-pointer text-[14px] text-white font-semibold px-[10px] py-[5px] bg-[linear-gradient(90deg,_#F4795A_0.04%,_#F4705C_15.04%,_#F55960_38.04%,_#F73367_67.04%,_#F90070_100.04%)] rounded-[8px] "
            onClick={closePopup}
          >
            Complete KYC
          </span>
        </NavLink>
      </div>
    </div>
  );
};

export default CompleteKYCVerificationModal;
