import CreditCard from "../../assets/revamped/CreditCard.svg";
import LifeInsurance from "../../assets/revamped/Insurance.svg";
import LoanRepayment from "../../assets/revamped/LoanIcon.svg";
import electricity from "../../assets/revamped/Electricity.svg";
import LPGIcon from "../../assets/revamped/LpsGas.svg";
import Water from "../../assets/revamped/Water.svg";
import fastag from "../../assets/revamped/FASTag.svg";
import mobileRecharge from "../../assets/revamped/MobileRecharge.svg";
import HouseRent from "../../../src/assets/revamped/HouseRent.svg";
import OfficeRent from "../../../src/assets/revamped/building.svg";
import SchoolIcon from "../../../src/assets/revamped/SchoolIcon.svg";
import CollegeIcon from "../../../src/assets/revamped/CollegeIcon.svg";

const allServices = [
  //bbps/utility section data

  {
    service: "utility",
    path: "/bbps/utility/Electricity",
    title: "Electricity",
    logo: electricity,
  },
  {
    service: "utility",
    path: "/bbps/utility/Water",
    title: "Water",
    logo: Water,
  },
  {
    service: "utility",
    path: "/bbps/utility/LPG_Gas",
    title: "LPG Booking",
    logo: LPGIcon,
  },
  {
    service: "utility",
    path: "/bbps/utility/BBPSEducation",
    title: "Education",
    logo: CollegeIcon,
  },
  //rent section data
  {
    service: "rent",
    path: "/payout/house-rent",
    title: "House Rent",
    logo: HouseRent,
  },
  {
    service: "rent",
    path: "/payout/office-rent",
    title: "Office Rent",
    logo: OfficeRent,
  },
  {
    service: "education",
    path: "/education/school-fee",
    title: "School fee",
    logo: SchoolIcon,
  },
  {
    service: "education",
    path: "/education/college-fee",
    title: "College fee",
    logo: CollegeIcon,
  },

  {
    service: "recharge",
    path: "/bbps/recharge/Fastag",
    title: "Fastag",
    logo: fastag,
  },
  {
    service: "recharge",
    path: "/bbps/recharge/NCMC_Recharge",
    title: "NCMC Recharge",
    logo: fastag,
  },
  {
    service: "recharge",
    path: "/bbps/recharge/Mobile_Postpaid",
    title: "Postpaid Recharge",
    logo: mobileRecharge,
  },

  {
    service: "finance",
    path: "/bbps/finance/Credit_Card",
    title: "Credit Card",
    logo: CreditCard,
  },
  {
    service: "finance",
    path: "/bbps/finance/Insurance",
    title: "Insurance",
    logo: LifeInsurance,
  },
  {
    service: "finance",
    path: "/bbps/finance/Loan_Repayment",
    title: "Loan EMI Payment",
    logo: LoanRepayment,
  },
];

function getServiceList(service = "") {
  if (service !== "") {
    return allServices.filter((s) => s.service === service);
  }
  return allServices;
}

export { allServices, getServiceList };
