import { Icon } from "@iconify/react";
import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { APIUrls } from "../../../../baseUrl/BaseUrl";
import { useDebounce } from "use-debounce";

function DropDownForEducationBiller({ setSelectedBiller }) {
  const [isOpen, setIsOpen] = useState(false);
  const [position, setPosition] = useState("below");
  const divRef = useRef(null);
  const dropdownRef = useRef(null);

  //debouncing code
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm] = useDebounce(searchTerm, 300);
  const [searchResults, setSearchResults] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // Track whether the dropdown is open

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
    setSelectedBiller(null);
    setIsDropdownOpen(e.target.value.length > 0); // Open the dropdown when user types, close when input is empty
  };

  const handleBillerSelect = (biller) => {
    setSelectedBiller(biller);
    setSearchTerm(biller.billerName);
    setIsDropdownOpen(false); // Open the dropdown when user types

    // Here you can trigger an action to store the selected biller to the backend
    // For example:
    // saveSelectedBillerToBackend(biller);
  };

  useEffect(() => {
    const searchBiller = async () => {
      try {
        const response = await axios.get(
          `${APIUrls.baseUrl}/api/bbps/searchBiller?searchTerm=${debouncedSearchTerm}`
        );
        setSearchResults(response.data);
      } catch (error) {
        console.error("Error searching billers:", error);
      }
    };

    if (debouncedSearchTerm) {
      searchBiller();
    } else {
      setSearchResults([]);
    }
  }, [debouncedSearchTerm]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false); // Close the dropdown if clicked outside
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const button = divRef.current;
    if (button) {
      const spaceBelow =
        window.innerHeight - button.getBoundingClientRect().bottom;
      const spaceAbove = button.getBoundingClientRect().top;
      // Check if there's not enough space below the button to display the dropdown
      if (spaceBelow < 200 && spaceAbove > 200) {
        setPosition("above");
      } else {
        setPosition("below");
      }
    }
  }, [isOpen]);

  return (
    <div className="relative" ref={dropdownRef}>
      <input
        type="text"
        value={searchTerm}
        onChange={handleInputChange}
        placeholder="Search Biller"
        className="p-[14.5px] block w-full text-[#ffffff] bg-transparent font-bold text-[0.8rem] lg:text-[1rem] rounded-[4px] border-[1px] border-[solid] border-[rgba(255,255,255,0.20)]"
      />
      {isDropdownOpen && (
        <ul
          className={`absolute max-h-[300px] overflow-y-auto scrollbar-utility	 mt-0 p-[16px] sm:p-[24px] bg-[#202020]  w-full shadow-md z-10 border-[1px] border-solid border-[rgba(255,255,255,0.20)] ${
            position === "above" ? "bottom-full rounded-t-lg " : "rounded-b-lg"
          }`}
        >
          {searchResults.map((biller) => (
            <li
              key={biller._id}
              onClick={() => handleBillerSelect(biller)} // Handle click event
              className="cursor-pointer flex items-center gap-[16px] hover:text-white rounded-[0.5rem] border-b border-[#4D4D4D] px-4 py-4 hover:bg-[linear-gradient(90deg,_#F4795A_0.04%,_#F4705C_15.04%,_#F55960_38.04%,_#F73367_67.04%,_#F90070_100.04%)] "
            >
              <h3 className="">{biller.billerName}</h3>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default DropDownForEducationBiller;
