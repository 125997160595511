import React from "react";
import GrayColoredCardWrapper from "../cards/GrayColoredCardWrapper";

const LineItem = ({ label = "", value = "-", bold = false }) => {
  return (
    <div
      className={`${
        bold ? "text-[rgba(255,_255,_255)]" : "text-[rgba(255,_255,_255,_0.70)]"
      } font-[Inter] text-[16px] xs:text-[20px] not-italic font-medium leading-[normal] flex flex-row justify-between`}
    >
      <span>{label}</span> <span>{value}</span>
    </div>
  );
};

const PaymentInfo = ({ CardTitle = "Payment Details", TotalData = [] }) => {
  return (
    <GrayColoredCardWrapper className="flex flex-col gap-[30px] lg:gap-[40px]">
      <p className="text-[#FFF] font-[Inter] text-[20px] xs:text-[24px] not-italic font-bold leading-[normal]">
        {CardTitle}
      </p>
      {TotalData?.map((lineItem, index) => {
        return (
          <LineItem
            key={lineItem.label}
            label={lineItem.label}
            value={lineItem.value}
            bold={lineItem.bold}
          />
        );
      })}

      {/* <p className="text-[rgba(255,_255,_255,_0.50)] mt-[20px] xs:mt-[0px] text-center font-[Inter] text-[12px] xs:text-[16px] not-italic font-semibold leading-[22.4px]">
                    By paying you agree to our{" "}
                    <Link to={"/terms-and-conditions"}>
                      <span className="text-[#FFF] font-[Inter]  not-italic font-semibold leading-[22.4px] [text-decoration-line:underline]">
                        Terms & Conditions
                      </span>{" "}
                    </Link>
                    &{" "}
                    <Link to={"/refund-policy"}>
                      <span className="text-[#FFF] font-[Inter]  not-italic font-semibold leading-[22.4px] [text-decoration-line:underline]">
                        Refund Policy
                      </span>{" "}
                    </Link>
                    which is mentioned in our policy terms.
                  </p> */}
    </GrayColoredCardWrapper>
  );
};

export default PaymentInfo;
