import React from "react";
import { NavLink } from "react-router-dom";

function UtilityDropDown(props) {
  const {
    path,
    title,
    logo,
    handleTabClick,
    // eslint-disable-next-line no-unused-vars
    handleImageClick,
    toggleDropdown,
  } = props;
  return (
    <NavLink
      to={path}
      className={`flex items-center gap-[16px] mb-[32px]`}
      onClick={() => {
        handleTabClick(title);
        // handleImageClick(logo);
        toggleDropdown();
      }}
    >
      <img src={logo} alt="" className="w-[24px] h-[24px]" />{" "}
      <span>{title}</span>
    </NavLink>
  );
}

export default UtilityDropDown;
