import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "./../../../stores/AuthContext";

import { toast } from "react-toastify";
import { getServiceList } from "../../utils/bbpsServices";
import UtilityDropDown from "./../../cards/UtilityDropDown";
import UtilityTab from "./../../cards/UtilityTab";

function Finance() {
  const tabArray = getServiceList("finance");
  const [activeService, setActiveService] = useState(
    tabArray[0] ? tabArray[0] : null
  );
  const { currentUser } = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  useEffect(() => {
    let findItem = tabArray.find((s) => s.path === location.pathname);
    if (findItem) {
      setActiveService(findItem);
    } else {
      setActiveService(tabArray[0] ? tabArray[0] : null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);
  if (!currentUser) {
    toast.error("please login to continue", {
      toastId: "success1",
    });
    return <Navigate to="/login" />;
  }
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleTabClick = (tabIndex) => {
    let findItem = tabArray.find((s) => s.title === tabIndex);
    if (findItem) {
      setActiveService(findItem);
    } else {
      setActiveService(tabArray[0] ? tabArray[0] : null);
    }
  };

  return (
    <div>
      {/* for large screen  */}
      <div className=" hidden md:flex overflow-x-scroll scrollbar-utility px-[80px] py-[40px] items-center gap-[48px] rounded-[25px] border-[1px] border-[solid] border-[rgba(255,255,255,0.04)] bg-[#202020] [box-shadow:0px_0px_20px_0px_rgba(0,_0,_0,_0.10)]">
        {tabArray.map((tab, index) => (
          <UtilityTab
            path={tab.path}
            title={tab.title}
            logo={tab.logo}
            handleTabClick={handleTabClick}
          />
        ))}
      </div>

      {/* For mobile */}
      <div className="md:hidden bg-[#202020]  rounded-[12px] ">
        <div
          className="flex bg-[#202020] rounded-[12px] h-[80px] px-[40px] py-[24px] justify-between items-center"
          onClick={toggleDropdown}
        >
          <button
            onClick={toggleDropdown}
            className="flex items-center gap-[16px]"
          >
            {
              <img
                src={activeService.logo}
                alt=""
                className="w-[32px] h-[32px]"
              />
            }
            <spna className="text-[#FFF] font-[Inter] text-[16px] not-italic font-bold leading-[normal]">
              {activeService.title}
            </spna>
          </button>
          <h3 className="flex items-center text-white ">
            {isOpen ? (
              <Icon
                icon="material-symbols:keyboard-arrow-down-rounded"
                className="cursor-pointer  text-[1.5rem]"
              />
            ) : (
              <Icon
                icon="material-symbols:keyboard-arrow-down-rounded"
                rotate={2}
                className="cursor-pointer text-[1.5rem]"
              />
            )}
          </h3>
        </div>
        {isOpen ? (
          <div className="px-[40px] py-[12px]">
            {tabArray.map((tab, index) => (
              <UtilityDropDown
                path={tab.path}
                title={tab.title}
                logo={tab.logo}
                handleTabClick={handleTabClick}
                toggleDropdown={toggleDropdown}
              />
            ))}
          </div>
        ) : (
          <></>
        )}
      </div>

      <Outlet />
    </div>
  );
}

export default Finance;
