import React, { useEffect, useState } from "react";
// import homeRent from "../../assets/homeRent.svg";
// import officeRent from "../../assets/officeRent.svg";
import { Icon } from "@iconify/react";
import {
  NavLink,
  Navigate,
  Outlet,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { toast } from "react-toastify";
import SchoolIcon from "../../../src/assets/revamped/SchoolIcon.svg";
import CollegeIcon from "../../../src/assets/revamped/CollegeIcon.svg";
import activeTick from "../../assets/mdi_tick.svg";
import { useAuth } from "../../stores/AuthContext";

function EducationCards() {
  const [activeTab, setActiveTab] = useState("");
  const [activeImage, setActiveImage] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  //const navigate = useNavigate();
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  // useEffect(() => {
  //   if (currentUser) {
  //     navigate("/payout/house-rent");
  //   }
  // }, []);

  useEffect(() => {
    if (location.pathname === "/education") {
      navigate("/education/school-fee");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    switch (location.pathname) {
      case "/education/school-fee":
        setActiveTab("School");
        setActiveImage(SchoolIcon);
        break;

      case "/education/college-fee":
        setActiveTab("College");
        setActiveImage(CollegeIcon);
        break;

      default:
    }
  }, [location.pathname]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };
  if (!currentUser) {
    toast.error("please login to continue", {
      toastId: "success1",
    });
    return navigate("/login", { replace: true });
  }

  // 

  return (
    <div className=' '>
      <div className=' hidden md:flex px-[80px] py-[40px] items-center gap-[48px] rounded-[25px] border-[1px] border-[solid] border-[rgba(255,255,255,0.04)] bg-[#202020] [box-shadow:0px_0px_20px_0px_rgba(0,_0,_0,_0.10)]'>
        <NavLink
          to={"/education/school-fee"}
          // className={`flex items-center relative  gap-[16px]`}
          onClick={() => handleTabClick("school")}
          className={({ isActive, isPending }) =>
            `flex items-center relative  gap-[16px]  ${
              isActive ? "text-[20px]" : ""
            }  `
          }>
          <img src={SchoolIcon} alt='' /> <span>School fee</span>
          {location.pathname === "/education/school-fee" && (
            <div className='absolute bottom-[-15px] left-0  w-full h-1 bg-[linear-gradient(90deg,_rgba(244,121,90,1)_12%,_rgba(244,112,92,1)_33%,_rgba(245,89,96,1)_55%,_rgba(247,51,103,1)_76%,_rgba(249,0,112,1)_100%)]'></div>
          )}
        </NavLink>
        <NavLink
          to={"/education/college-fee"}
          // className="flex items-center relative gap-[16px]"
          onClick={() => handleTabClick("college")}
          className={({ isActive, isPending }) =>
            `flex items-center relative  gap-[16px]  ${
              isActive ? "text-[20px]" : ""
            }  `
          }>
          <img src={CollegeIcon} alt='' />
          <span>College fee</span>

          {location.pathname === "/education/college-fee" && (
            <div className='absolute bottom-[-15px] left-0  w-full h-1 bg-[linear-gradient(90deg,_rgba(244,121,90,1)_12%,_rgba(244,112,92,1)_33%,_rgba(245,89,96,1)_55%,_rgba(247,51,103,1)_76%,_rgba(249,0,112,1)_100%)]'></div>
          )}
        </NavLink>
      </div>

      {/* For mobile */}

      <div className='md:hidden bg-[#202020]  rounded-[12px] font-inter '>
        <div
          className='flex bg-[#202020] rounded-[12px] h-[80px] px-[1.5rem] py-[24px] justify-between items-center'
          onClick={toggleDropdown}>
          <button
            onClick={toggleDropdown}
            className='flex items-center gap-[16px]'>
            <img src={activeImage} alt='' className='h-[2rem] w-[2rem]' />

            <span className='text-[#FFF] font-[Inter] text-[1rem] not-italic font-bold leading-normal'>
              {activeTab} fee
            </span>
          </button>
          <h3 className='flex items-center text-white '>
            {isOpen ? (
              <Icon
                icon='material-symbols:keyboard-arrow-down-rounded'
                className='cursor-pointer  text-[2rem]'
              />
            ) : (
              <Icon
                icon='material-symbols:keyboard-arrow-down-rounded'
                rotate={2}
                className='cursor-pointer text-[2rem]'
              />
            )}
          </h3>
        </div>
        {isOpen ? (
          <div className='px-[1.5rem] py-[1rem]'>
            {" "}
            <NavLink
              to={"/education/school-fee"}
              className={`flex justify-between items-center gap-[16px] mb-3 p-[0.5rem] ${
                activeTab === "School" && "bg-[#272727] rounded-[0.5rem]"
              } `}
              onClick={() => {
                handleTabClick("school");
                toggleDropdown();
              }}>
              <div className='flex flex-row gap-[1rem]'>
                <img
                  src={SchoolIcon}
                  alt=''
                  className='h-[1.5rem] w-[1.5rem]'
                />{" "}
                <span>School fee</span>
              </div>

              {activeTab === "School" && (
                <img
                  src={activeTick}
                  className='h-[1.5rem] w-[1.5rem]'
                  alt='active tick'
                />
              )}
            </NavLink>
            <hr
              style={{
                borderColor: "rgba(255, 255, 255, 0.10)",
              }}
              className='h-[1px] w-full my-[1rem] '
            />
            <NavLink
              to={"/education/college-fee"}
              className={`flex justify-between items-center gap-[16px] mb-3 p-[0.5rem] ${
                activeTab === "College" && "bg-[#272727] rounded-[0.5rem]"
              } `}
              onClick={() => {
                handleTabClick("college");
                toggleDropdown();
              }}>
              <div className='flex flex-row gap-[1rem]'>
                <img
                  src={CollegeIcon}
                  alt=''
                  className='h-[1.5rem] w-[1.5rem]'
                />{" "}
                <span>College fee</span>
              </div>

              {activeTab === "College" && (
                <img
                  src={activeTick}
                  className='h-[1.5rem] w-[1.5rem]'
                  alt='active tick'
                />
              )}
            </NavLink>
          </div>
        ) : (
          <></>
        )}
      </div>

      {/* <NavLink to={"/payout/maintenance"}>

      <PayCategoryCard text={"Maintenance"} image={blackScrew} />
      </NavLink>
      <NavLink to={"/payout/education-fee"}>

      <PayCategoryCard text={"Education Fee"} image={cil_education} />
      </NavLink> */}
      <Outlet />
    </div>
  );
}

export default EducationCards;
