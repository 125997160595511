import { combineReducers, configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import { paySlice } from "./PayRedux";
import { userSlice } from "./UserRedux";
import { alltransactionSlice } from "./alltransaction";

// Combine the reducers
const rootReducer = combineReducers({
  pay: paySlice.reducer,
  // Add other reducers here
  userData: userSlice.reducer,
  allTransaction: alltransactionSlice.reducer,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: [thunk], // Apply Redux Thunk middleware
});

export const payActions = paySlice.actions;
export const userActions = userSlice.actions;
export const allTransactionActions = alltransactionSlice.actions;

export default store;
