import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";

import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { APIUrls } from "../../baseUrl/BaseUrl";
import { useAuth } from "../../stores/AuthContext";
import InputFieldLabel from "../forms/InputFieldLabel";
import Loader from "../loader/Loader";

function DynamicBillerForm({
  customerParams,
  selectedBiller,
  setBillInfo,
  billInfo,
  userBillData,
  showAmountField = false,
}) {
  const [load, setLoad] = useState(false);
  const { getAccessToken } = useAuth();

  // 

  // Define the initial form values

  let initialValues = {};

  if (billInfo) {
    let customerParams = billInfo?.requestData?.billDetails?.customerParams;
    initialValues = customerParams.reduce((acc, obj) => {
      acc[obj.name] = obj.value;
      return acc;
    }, {});
  }

  // This userBilldata use to pre fill bill details feature

  if (userBillData) {
    let customerParams = userBillData?.requestData?.billDetails?.customerParams;

    initialValues = customerParams.reduce((acc, obj) => {
      acc[obj.name] = obj.value;
      return acc;
    }, {});
  }

  // Define the validation schema using Yup
  const validationSchema = Yup.object().shape(
    customerParams.reduce((schema, param) => {
      // 
      const field = param.paramName;
      let fieldSchema = Yup.string();
      if (param.optional === "false" || param.optional === "true") {
        fieldSchema = fieldSchema.required(`${field} is required`);
      }
      if (param.minLength) {
        fieldSchema = fieldSchema.min(
          Number(param.minLength),
          `${field} must be at least ${param.minLength} characters`
        );
      }
      if (param.maxLength) {
        fieldSchema = fieldSchema.max(
          Number(param.maxLength),
          `${field} must be at most ${param.maxLength} characters`
        );
      }
      if (param.regex) {
        fieldSchema = fieldSchema.matches(
          new RegExp(param.regex),
          `${field} is invalid`
        );
      }

      return { ...schema, [field]: fieldSchema };
    }, {})
  );

  // Define the onSubmit function
  const onSubmit = async (values) => {
    // Handle form submission (e.g., send data to the server)
    // 
    // 

    // let customerParamsArray = [];

    // customerParams.forEach((param) => {
    //   const value = values[param.paramName];
    //   const customerParam = {
    //     name: param.paramName,
    //     value: value || "",
    //   };
    //   customerParamsArray.push(customerParam);
    // });

    const customerParamsArray = Object.entries(values).map(([key, value]) => {
      if (typeof value === "string") {
        return {
          name: key,
          value: value,
        };
      } else {
        return {
          name: key,
          value: String(value),
        };
      }
    });

    // setCustParaInfo(customerParamsArray);

    // 

    let data = {
      billDetails: {
        billerId: selectedBiller?.billerId,
        customerParams: customerParamsArray,
      },
    };
    // 

    const token = await getAccessToken();

    const fetchRequirementValues = ["MANDATORY", "OPTIONAL"];

    let value = selectedBiller.billarData.fetchRequirement;

    if (fetchRequirementValues.includes(value)) {
      try {
        setLoad(true);

        // When user fetch bill twice so we can reset payment card data
        setBillInfo(false);

        const response = await axios.post(APIUrls.fetch_bills, data, {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
          },
        });
        // 
        // 

        const code = response.data?.code;
        const status = response.data?.response?.status;

        if (code === 200 && status === "SUCCESS") {
          setLoad(false);
          setBillInfo({
            requestData: data,
            responseData: response.data?.response,
            amount:
              response.data?.response?.response?.billerResponse?.amount || 0,
          });
        } else {
          setLoad(false);
          setBillInfo(false);
          toast("Somthing went to wrong", {
            theme: "dark",
            hideProgressBar: true,
            type: "error",
          });
        }
      } catch (error) {
        setLoad(false);
        setBillInfo(false);
        // Handle error response
        console.error("Error:", error.response);
        toast("Somthing went to wrong", {
          theme: "dark",
          hideProgressBar: true,
          type: "error",
        });
      }
    } else if (
      ["MANDATORY", "OPTIONAL"].includes(
        selectedBiller.billarData.supportBillValidation
      )
    ) {
      // just set data
      // 

      // Remove amount param we add to pay any amount
      const filteredCustomerParamsArray = showAmountField
        ? customerParamsArray.filter((item) => item.name !== "amount")
        : customerParamsArray;

      let validateData = {
        billDetails: {
          billerId: selectedBiller?.billerId,
          customerParams: filteredCustomerParamsArray,
        },
      };

      try {
        setLoad(true);
        const response = await axios.post(
          APIUrls.validate_bills,
          validateData,
          {
            headers: {
              "Content-Type": "application/json",
              authorization: `Bearer ${token}`,
            },
          }
        );
        // 

        const code = response?.data?.code;
        const status = response?.data?.response?.status;

        if (code === 200 && status === "SUCCESS") {
          setLoad(false);
          setBillInfo({
            requestData: data,
            responseData: response.data?.response,
            amount: values.amount || 0,
          });
        } else {
          setLoad(false);
          setBillInfo(false);
          toast("Somthing went to wrong", {
            theme: "dark",
            hideProgressBar: true,
            type: "error",
          });
        }
      } catch (error) {
        setLoad(false);
        setBillInfo(false);
        // Handle error response
        console.error("Error:", error.response);
        toast("Somthing went to wrong", {
          theme: "dark",
          hideProgressBar: true,
          type: "error",
        });
      }

      // setLoad(false);
      // setBillInfo({
      //   requestData: data,
      //   responseData: {},
      //   amount: values.amount || 0,
      // });
    } else {
      // just set data
      // 
      setLoad(false);
      setBillInfo({
        requestData: data,
        responseData: {},
        amount: values.amount || 0,
      });
    }
  };

  // 

  return (
    <>
      {load ? (
        <Loader />
      ) : (
        <div>
          {/* <h1>Dynamic Biller Form</h1> */}
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ errors, touched, values }) => (
              <Form>
                {/* Dynamically generate form fields based on customer parameter array */}
                {customerParams.map((param) => (
                  <div key={param.paramName} className="mb-[10px]">
                    <InputFieldLabel
                      htmlFor={param.paramName}
                      label={param.paramName}
                    />
                    {!param.values ? (
                      <Field
                        type={param.dataType === "NUMERIC" ? "number" : "text"}
                        id={param.paramName}
                        name={param.paramName}
                        // add static value
                        // value="054760011140"
                        value={values[param.paramName]}
                        className="p-[14.5px] block w-full text-[#ffffff] bg-transparent font-bold text-[0.8rem] lg:text-[1rem] rounded-[4px] border-[1px] border-[solid] border-[rgba(255,255,255,0.20)]"
                      />
                    ) : (
                      // <Field
                      //   type="text"
                      //   id={param.paramName}
                      //   name={param.paramName}
                      //   className="p-[14.5px] block w-full text-[#ffffff] bg-transparent font-bold text-[0.8rem] lg:text-[1rem] rounded-[4px] border-[1px] border-[solid] border-[rgba(255,255,255,0.20)]"
                      // />

                      <Field
                        as="select"
                        id={param.paramName}
                        name={param.paramName}
                        className="p-[14.5px]  w-full text-[#ffffff] font-bold text-[0.8rem] lg:text-[1rem] rounded-[4px] border-[1px] border-[solid] border-[rgba(255,255,255,0.20)] block appearance-none  bg-[#131313] focus:bg-[#131313] focus:outline-none "
                      >
                        {param.values.split(",").map((value) => (
                          <option value={value}>{value}</option>
                        ))}
                      </Field>
                    )}
                    {/* <ErrorMessage
                      name={param.paramName}
                      component="div"
                      className=" text-[#F00] font-[Inter] text-[16px] md:text-[16px] not-italic  leading-[normal]"
                    /> */}
                    {errors[param.paramName] && (
                      <div className="text-[#F00] font-[Inter] text-[16px] md:text-[16px] not-italic  leading-[normal]">
                        {errors[param.paramName]}
                      </div>
                    )}
                  </div>
                ))}
                {/* // show custom amount field if customerParams do not have it */}
                {showAmountField && showAmountField === true ? (
                  <div className="mb-[10px]">
                    <InputFieldLabel htmlFor={"amount"} label={"Amount"} />
                    <Field
                      type={"number"}
                      id={"amount"}
                      name={"amount"}
                      // add static value
                      // value="054760011140"
                      className="p-[14.5px] block w-full text-[#ffffff] bg-transparent font-bold text-[0.8rem] lg:text-[1rem] rounded-[4px] border-[1px] border-[solid] border-[rgba(255,255,255,0.20)]"
                    />

                    <ErrorMessage
                      name={"Amount"}
                      component="div"
                      className=" text-[#F00] font-[Inter] text-[16px] md:text-[16px] not-italic  leading-[normal]"
                    />
                  </div>
                ) : (
                  ""
                )}

                <button
                  type="submit"
                  className="mt-[2rem]  text-[#FFF]  font-[Inter] text-[20px] not-italic font-semibold leading-[33.6px]  p-4 rounded-[10px] bg-[#424242]"
                >
                  {["OPTIONAL", "MANDATORY"].includes(
                    selectedBiller?.billarData?.fetchRequirement
                  )
                    ? "Fetch bill"
                    : ["OPTIONAL", "MANDATORY"].includes(
                        selectedBiller?.billarData?.supportBillValidation
                      )
                    ? "Validate bill"
                    : null}
                </button>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </>
  );
}

export default DynamicBillerForm;
