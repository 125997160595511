import { Icon } from "@iconify/react";
import { Checkbox, ThemeProvider } from "@material-tailwind/react";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import CryptoJS from "crypto-js";
import _ from "lodash";
import forge from "node-forge";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { CgSpinner } from "react-icons/cg";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { APIUrls } from "../../../baseUrl/BaseUrl";
import public_key from "../../../public_key";
import { useAuth } from "../../../stores/AuthContext";
import GrayColoredCardWrapper from "../GrayColoredCardWrapper";
import "./scrollbar.css";

import { load } from "@cashfreepayments/cashfree-js";

import { Helmet } from "react-helmet";
import DropdownMenu from "../../dropDown/DropdownMenu";
import AddBeneficiaryModal from "../../modals/AddBeneficiaryModal";
import CardWrapper from "../CardWrapper";
// import Razorpay from 'razorpay';
// import useRazorpay from "react-razorpay";

let MIN_AMOUNT_LIMIT = 1;
let MAX_AMOUNT_LIMIT = 50000;

if (APIUrls.paymentENV === "PROD") {
  MIN_AMOUNT_LIMIT = 1000;
} else if (APIUrls.paymentENV === "STAGE") {
  MIN_AMOUNT_LIMIT = 10;
}
const theme = {
  checkbox: {
    defaultProps: {
      color: "blue-gray",
      label: undefined,
      icon: undefined,
      ripple: true,
      className: "",
      disabled: false,
      containerProps: undefined,
      labelProps: undefined,
      iconProps: undefined,
    },
    valid: {
      colors: [
        "blue-gray",
        "gray",
        "brown",
        "deep-orange",
        "orange",
        "amber",
        "yellow",
        "lime",
        "light-green",
        "green",
        "teal",
        "cyan",
        "light-blue",
        "blue",
        "indigo",
        "deep-purple",
        "purple",
        "pink",
        "red",
      ],
    },
    styles: {
      base: {
        root: {
          display: "inline-flex",
          alignItems: "items-center",
        },
        container: {
          position: "relative",
          display: "flex",
          alignItems: "items-center",
          cursor: "cursor-pointer",
          p: "p-3",
          borderRadius: "rounded-full",
        },
        input: {
          peer: "peer",
          position: "relative",
          appearance: "appearance-none",
          width: "w-5",
          height: "h-5",
          borderWidth: "border",
          borderRadius: "rounded-md",
          borderColor: "border-blue-gray-200",
          cursor: "cursor-pointer",
          transition: "transition-all",
          before: {
            content: "before:content['']",
            display: "before:block",
            bg: "before:bg-blue-gray-500",
            width: "before:w-12",
            height: "before:h-12",
            borderRadius: "before:rounded-full",
            position: "before:absolute",
            top: "before:top-2/4",
            left: "before:left-2/4",
            transform: "before:-translate-y-2/4 before:-translate-x-2/4",
            opacity: "before:opacity-0 hover:before:opacity-10",
            transition: "before:transition-opacity",
          },
        },
        label: {
          color: "text-gray-700",
          fontWeight: "font-light",
          userSelect: "select-none",
          cursor: "cursor-pointer",
          mt: "mt-px",
        },
        icon: {
          color: "text-white",
          position: "absolute",
          top: "top-2/4",
          left: "left-2/4",
          translate: "-translate-y-2/4 -translate-x-2/4",
          pointerEvents: "pointer-events-none",
          opacity: "opacity-0 peer-checked:opacity-100",
          transition: "transition-opacity",
        },
        disabled: {
          opacity: "opacity-50",
          pointerEvents: "pointer-events-none",
        },
      },
      colors: {
        "blue-gray": {
          background: "checked:bg-gradient-to-r from-[#F4795A] to-[#F4705C]",
          border: "checked:border-none",
          before: "checked:before:bg-[rgba(255, 255, 255, 0.10)]",
        },
        gray: {
          background: "checked:bg-gray-500",
          border: "checked:border-gray-500",
          before: "checked:before:bg-gray-500",
        },
      },
    },
  },
};

const PaymentCardForEducation = () => {
  // const [Razorpay] = useRazorpay();
  const [amountLimit, setamtlimit] = useState(0);
  const {
    // confirmationResult,
    //  setConfirmationResult,
    warnModelOn,
  } = useAuth();
  const { getAccessToken, currentUser, get_wallet_lim } = useAuth();
  const { fee } = useParams();

  // const isLargeScreen = window.innerWidth > 1024;
  const userDatas = useSelector((state) => state.userData);
  const {
    register,
    handleSubmit,
    formState: {
      // errors,
      isValid,
    },
    // value,
  } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [amount, setamount] = useState(0);
  const [convieFee, setconvieFee] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [netSettledAmount, setNetSettaledAmount] = useState(0);
  const [payInfo, setPayInfo] = useState({});
  const [isAmountvalid, setisAmountValid] = useState(false);
  const formRef = useRef(null);
  const tokenRef = useRef("");
  const [dynamicFee, setDynamicFee] = useState(1.8);
  // const navigate = useNavigate();
  const [emailVerifieds, setEmailverified] = useState(true);
  const [kycStatuss, setKyc_status] = useState(false);
  const [paymentDisables, setPaymentDisable] = useState(false);
  const keyref = useRef("");
  const { emailVerified, kyc_verified, paymentDisabled } = userDatas;
  const [termsAndConditionsCheck, setTermsAndConditionsCheck] = useState(true);

  const [isOpenModal, setIsOpenModal] = useState(false);

  // const [pgTypes, setPgtypes] = useState(gatewayset);

  // 

  // const { usagelimit: MAX_AMOUNT_LIMIT } = get_wallet_lim();

  useEffect(() => {
    if (_.has(currentUser, "reloadUserInfo")) {
      if (_.has(currentUser.reloadUserInfo, "customAttributes")) {
        setValuesToForm(currentUser);
        setamtlimit(get_wallet_lim());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  const setValuesToForm = () => {
    // const { FirstName, LastName, State, City, Address, Pincode } = JSON.parse(
    //   userData.reloadUserInfo.customAttributes
    // ).Customer_data;

    if (_.isEmpty(emailVerified)) {
      ////
      setEmailverified(false);
    }

    if (emailVerified === true) {
      ////
      setEmailverified(true);
    }

    if (_.isEmpty(paymentDisabled)) {
      ////
      setPaymentDisable(false);
    }

    if (paymentDisabled === true) {
      setPaymentDisable(true);
    }

    if (_.isEmpty(kyc_verified)) {
      ////
      setKyc_status(false);
    }

    if (kyc_verified === true) {
      setKyc_status(true);
    }
  };

  // const onSubmit = async (data, event) => {
  //   event.preventDefault();

  //   //

  //   const payableAmount = data.amount.toString();
  //   const paymentData = payInfo;
  //   //
  //   const Paydata = {
  //     Amount: payableAmount,
  //     TotalAmount: totalAmount.toString(),
  //     paymentType: rent,
  //     paymentInfo: {
  //       beneficier_id: paymentData.bend_ids,
  //       name: paymentData?.name,
  //       address:paymentData?.address,
  //       type: paymentData?.paydatas?.type || "",
  //       bankAccount: paymentData?.paydatas?.bankAccount || "",
  //       ifsc_code: paymentData?.paydatas?.ifsc_code || "",
  //       upi_code: paymentData?.paydatas?.upi_code || "",
  //     },
  //     conviFees: convieFee.toString(),
  //   };

  //    // Encrypt
  //    const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(Paydata), 'payinstacard@cyper').toString();

  //    const encryptedDAta = {

  //       payData:ciphertext
  //    }
  //   //

  //   try {
  //     setIsLoading(true);
  //     const token = await getAccessToken();
  //     const response = await fetch(APIUrls.make_payment, {
  //       method: "POST",
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify(encryptedDAta),
  //     });

  //     const responseJson = await response.json();
  //     //
  //     if (_.isEmpty(responseJson)) {
  //       setIsLoading(false);
  //       return toast.error("Something went wrong !!");
  //     }

  //     if (responseJson?.code === 201) {
  //       if (responseJson.response.beneficer_count !== 0) {
  //         const paymentObjects = responseJson.response;
  //         //

  //         return window.location.replace(paymentObjects.PaymentProcessUrl);
  //         //return benarray;
  //       }
  //     }

  //     if (responseJson?.code === 400) {
  //       setIsLoading(false);
  //       const message = responseJson.response.message;
  //       throw new Error("Something went Wrong ,Try again later !");
  //     }

  //     //
  //   } catch (error) {
  //     setIsLoading(false);
  //     //

  //     toast.error("Something went Wrong !");
  //   }

  const inputRef = useRef(null);

  const onSubmit = async (data, event) => {
    event.preventDefault();

    //
    //

    //

    if (!emailVerified) return toast.error("Your Email is not Verified !");

    if (!kycStatuss) {
      return toast.error("Your Kyc status is disabled !");
    }

    if (!paymentDisables) {
      return toast.error("Your payment is disabled !");
    }

    // 

    //return navigate("/payout/comingsoons");
    //

    //logic for if user's first transaction and amount is >= 5000 make convience fee "0"
    let convieCharge;
    if (
      userDatas?.transaction_arrays.length === 0 &&
      parseInt(data?.amount) >= 5000
    ) {
      convieCharge = "0";
    } else {
      convieCharge = convieFee.toString();
    }

    const payableAmount = data.amount.toString();

    // passing settlment amount

    // const payableAmount = netSettledAmount.toString();

    const paymentData = payInfo;
    //
    const Paydata = {
      Amount: payableAmount,
      TotalAmount: totalAmount.toString(),
      paymentType: fee,
      paymentInfo: {
        beneficier_id: paymentData.bend_ids,
        name: paymentData?.name,
        address: paymentData?.address,
        type: paymentData?.paydatas?.type || "",
        bankAccount: paymentData?.paydatas?.bankAccount || "",
        ifsc_code: paymentData?.paydatas?.ifsc_code || "",
        upi_code: paymentData?.paydatas?.upi_code || "",
      },
      conviFees: convieCharge,
    };

    forge.random.getBytesSync(16);
    const binaryKEy = forge.random.getBytesSync(16).toString();

    const symmetricKey = forge.util.bytesToHex(binaryKEy);

    const key = symmetricKey;
    // Encrypt
    const ciphertext = CryptoJS.AES.encrypt(
      JSON.stringify(Paydata),
      key
    ).toString();

    const encryptData = () => {
      // Replace with the server's public key
      // const symmetricKey = forge.random.getBytesSync(16);

      // Convert the public key to a Forge RSA public key object
      const publicKeyForge = forge.pki.publicKeyFromPem(public_key);

      // Encrypt the data using RSA_PKCS1_OAEP_PADDING and sha256 hash
      const encrypted = publicKeyForge.encrypt(key, "RSA-OAEP", {
        md: forge.md.sha256.create(),
      });

      // Convert the encrypted bytes to a Base64-encoded string
      const encryptedBase64 = forge.util.encode64(encrypted);

      return encryptedBase64;
    };

    // const encryptedDAta = {
    //   payData: encryptData(Paydata),
    // };
    //

    try {
      setIsLoading(true);
      // const form = formRef.current;

      // Access the input element using the ref
      const inputElement = inputRef.current;

      const keysElement = keyref.current;

      // Get the updated value

      const updatedValue = forge.util.encode64(ciphertext);

      //
      const tokens = tokenRef.current;
      tokens.value = await getAccessToken();
      // Set the value of the input element
      inputElement.value = updatedValue;
      keysElement.value = encryptData(Paydata, public_key);

      // Submit the form

      // if (pgTypes === "airpay") {
      //   form.submit();
      // }

      //JUSPAY

      const cashFreeData = {
        token: tokens.value,
        key: keysElement.value,
        payData: inputElement.value,
      };

      try {
        const response = await axios.post(
          APIUrls.cashFreeCreateOrder,
          cashFreeData
        );
        //
        setIsLoading(false);

        if (response.status === 201) {
          const payment_session_id =
            response?.data?.response?.payment_session_id;
          const order_id = response?.data?.response?.order_id;

          // window.location.replace(
          //   `http://localhost:3000/cashfreepayment/${payment_session_id}/${order_id}`
          // );

          handleCheckOut(payment_session_id, order_id);
        }
      } catch (error) {
        setIsLoading(false);
        //
        toast.error("Something went Wrong !");
      }

      // RAZORPAY
      // if (pgTypes === "razorpay") {
      //   const razorpayData = {
      //     token: tokens.value,
      //     key: keysElement.value,
      //     payData: inputElement.value,
      //   };

      //   try {
      //     const response = await axios.post(APIUrls.razorPayroll, razorpayData);
      //     //
      //     setIsLoading(false);
      //     if (response.status === 201) {
      //       const { id } = response?.data?.response;
      //       ////
      //       const {
      //         FirstName,
      //         LastName,
      //         State,
      //         City,
      //         Address,
      //         Pincode,
      //         Email,
      //       } = JSON.parse(
      //         currentUser?.reloadUserInfo?.customAttributes
      //       )?.Customer_data;

      //       const options = {
      //         key: "rzp_test_hh53r5tcNlGJXh", // Enter the Key ID generated from the Dashboard
      //         //amount: parseInt(totalAmount) * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      //         currency: "INR",
      //         name: "PAYINSTACARD",
      //         description: "Rent Payment",
      //         image:
      //           "https://payinstacard.com/static/media/payinstacardLogo.2ebde325f3c3a95bbd71bc7c3632815b.svg",
      //         order_id: id, //This is a sample Order ID. Pass the `id` obtained in the response of createOrder().
      //         handler: function (response) {
      //           //
      //           // alert(response.razorpay_payment_id);
      //           // alert(response.razorpay_order_id);
      //           // alert(response.razorpay_signature);

      //           navigate("/paymentverify/" + response.razorpay_payment_id);
      //         },

      //         prefill: {
      //           name: FirstName,
      //           email: Email,
      //           contact: currentUser.phoneNumber,
      //           method: "card",
      //         },
      //         notes: {
      //           address: "Izzat Nagar Weaker Section Colony",
      //         },
      //         theme: {
      //           color: "#00006B",
      //         },
      //       };

      //       const rzp1 = new Razorpay(options);

      //       rzp1.on("payment.failed", function (response) {
      //         // alert(response.error.code);
      //         // alert(response.error.description);
      //         // alert(response.error.source);
      //         // alert(response.error.step);
      //         // alert(response.error.reason);
      //         // alert(response.error.metadata.order_id);
      //         // alert(response.error.metadata.payment_id);

      //         setIsLoading(false);

      //         navigate("/paymentverify/" + response.error.metadata.payment_id);
      //       });

      //       rzp1.on("payment.authorized", function (response) {
      //         // alert(response.error.code);
      //         // alert(response.error.description);
      //         // alert(response.error.source);
      //         // alert(response.error.step);
      //         // alert(response.error.reason);
      //         // alert(response.error.metadata.order_id);
      //         // alert(response.error.metadata.payment_id);

      //         setIsLoading(false);

      //         navigate("/paymentverify/" + response.error.metadata.payment_id);
      //       });

      //       rzp1.on("payment.captured", function (response) {
      //         // alert(response.error.code);
      //         // alert(response.error.description);
      //         // alert(response.error.source);
      //         // alert(response.error.step);
      //         // alert(response.error.reason);
      //         // alert(response.error.metadata.order_id);
      //         // alert(response.error.metadata.payment_id);

      //         setIsLoading(false);

      //         navigate("/paymentverify/" + response.error.metadata.payment_id);
      //       });

      //       rzp1.open();
      //       //
      //     }
      //   } catch (e) {
      //     setIsLoading(false);
      //     //
      //     toast.error("Something went Wrong !");
      //   }
      // }
    } catch (error) {
      setIsLoading(false);
      //

      toast.error("Something went Wrong !");
    }

    // setIsLoading(true);
    // const { otp } = data;
    // try {
    //   const result = await window.confirmationResult.confirm(otp);
    //   reset();
    //   //
    // } catch (error) {
    //   //
    //   toast.error(error.message, {
    //     position: "top-right",
    //     autoClose: 5000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //     theme: "light",
    //   });
    // }
    // setIsLoading(false);
  };

  const handleCheckOut = async (payment_session_id, order_id) => {
    try {
      // it containse mode (sandbox, production) for the cashfree PG
      const cashfree = await load({
        mode: APIUrls.cashfreeMode, // "sandbox", //or production
      });
      let checkoutOptions = {
        paymentSessionId: payment_session_id,
        returnUrl: `${APIUrls.cashFreePaymentResponse}/?orderid=${order_id}`,
        redirectTarget: "_self",
      };
      cashfree.checkout(checkoutOptions).then(function (result) {
        if (result.error) {
          alert(result.error.message);
        }
        if (result.redirect) {
          //
        }
      });
    } catch (error) {
      //
    }
  };

  useEffect(() => {
    if (parseInt(amountLimit?.usagelimit) < 2000) {
      setisAmountValid(false);
    } else if (
      amount >= MIN_AMOUNT_LIMIT &&
      amount <= parseInt(amountLimit?.usagelimit)
    ) {
      setisAmountValid(true);
    } else {
      setisAmountValid(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amount]);

  const payset = (event) => {
    //

    if (!_.isEmpty(event)) {
      const convertTojson = JSON.parse(event);
      // 
      setPayInfo(convertTojson);
    }
  };

  // handle amount Logic HEre

  const handleAmount = (event) => {
    let amount = event.target.value;
    //
    amount = amount.replace(/^0+/, "");

    //
    if (amount === "" || isNaN(amount)) {
      setamount(0);

      return;
    }

    amount = parseInt(amount); // Convert to integer to remove leading zeros

    //
    setamount(amount);
    const feePercentage = dynamicFee || 1.8;
    ////
    if (_.isNumber(amount)) {
      setconvieFee(parseFloat(((feePercentage / 100) * amount).toFixed(2)));
      ////
      ////
      let fee = parseFloat(((feePercentage / 100) * amount).toFixed(2));
      //.log(amount + fee);
      // const totalamount = amount + fee;
      ////
      // const roundedTotalAmount = Math.ceil(totalamount);
      const roundedTotalAmount = Math.ceil(amount);
      ////

      // setNetSettaledAmount(parseInt(Math.ceil(amount - fee)));
      setNetSettaledAmount(parseInt(Math.ceil(amount + fee)));
      // setTotalAmount(parseInt(roundedTotalAmount));
      setTotalAmount(parseInt(Math.ceil(amount + fee)));
    }
  };

  // FETCH BENIFICARY HERE

  const { data, status } = useQuery({
    queryKey: ["benificers"],
    queryFn: async () => {
      try {
        const token = await getAccessToken();
        const response = await fetch(APIUrls.fetchBen("EDUCATION"), {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });
        setIsLoading(false);
        const responseJson = await response.json();

        console.log(response,"beneficiaries response")

        console.log(responseJson,"education beneficiaries")
        //
        if (_.isEmpty(responseJson)) {
          return toast.error("Something went wrong !!");
        }

        if (responseJson?.code === 200) {
          //setTotalBen(responseJson.response.beneficer_count)
          if (responseJson.response.beneficer_count !== 0) {
            const benarray = responseJson.response.beneficer_array;

            return benarray;
          }

          // const message = responseJson.response.message;
        }

        if (responseJson.response.beneficer_count === 0) {
          throw new Error("There are no benificers available");
        }
        if (responseJson?.code === 492) {
          // const message = responseJson.response.message;
          throw new Error("There is no benificary");
        }

        if (responseJson?.code === 400) {
          // const message = responseJson.response.message;
          throw new Error("Something went Wrong ,Try again later !");
        }

        //
      } catch (error) {
        setIsLoading(false);
        //

        //toast.error("Something went Wrong !");
      }
    },
  });

  // SET THE DYNAMIC COMMISSIONS
  const loadPErsent = async () => {
    const token = await getAccessToken();
    const headers = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    await axios
      .get(APIUrls.get_persentage, headers)
      .then((data) => {
        const per = data.data.response.conviencePercentage;
        ////
        setDynamicFee(per);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    loadPErsent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amount]);
  // 

  return (
    <CardWrapper
      className={
        "px-[1rem] xs:max-sm:px-[1.5rem] sm:max-md:px-[2.5rem] md:px-[1rem] lg:px-[1.5rem] xl:px-[2.5rem] flex flex-col gap-[2rem] py-[2rem] min-h-[420px] xs:min-h-[500px]"
      }>
      <div className='font-inter flex flex-col gap-[2rem] '>
        <ThemeProvider value={theme}>
          <Helmet>
            <meta charSet='utf-8' />
            <title>Education Payment</title>
            <link
              rel='canonical'
              href='https://payinstacard.com/education/school-fee'
            />
          </Helmet>
          <form
            action=''
            onSubmit={handleSubmit(onSubmit)}
            className='flex flex-col lg:flex-row gap-[2rem] lg:gap-[80px] lg:min-w-[350px] '>
            <GrayColoredCardWrapper className='lg:w-[50%]  xs:p-[40px] lg:px-[80px] lg:py-[40px] flex flex-col gap-[24px]'>
              {!emailVerified && (
                <div className='text-[0.9rem] mt-[0.5rem] gap-[0.5rem] text-[#EBEAEA] bg-[#841a99] flex flex-row items-center rounded-[12px] py-[0.8rem] px-[1rem]'>
                  <Icon
                    icon='material-symbols:info-rounded'
                    className='text-[1.5rem]'
                  />
                  <span>
                    {" "}
                    Before you continue to payment, please take a moment to
                    verify your email address by{" "}
                    <Link to={"/profile"}>
                      <span className='text-[#f8f8f8] whitespace-nowrap underline '>
                        Clicking Here
                      </span>{" "}
                    </Link>
                  </span>
                </div>
              )}

              {!paymentDisables && emailVerifieds && kycStatuss && (
                <div className='text-[0.9rem] mt-[0.5rem] gap-[0.5rem] text-[#EBEAEA] bg-[#DA4633] flex flex-row items-center rounded-[12px] py-[0.8rem] px-[1rem]'>
                  <Icon
                    icon='material-symbols:info-rounded'
                    className='text-[1.5rem]'
                  />
                  <span>
                    {" "}
                    Sorry, your payment is currently blocked. Connect with our
                    Payinstacard Team to resolve this issue.
                  </span>
                </div>
              )}

              {!kycStatuss && emailVerifieds && (
                <div className='mb-5 text-[0.9rem] mt-[0.5rem] gap-[0.5rem] text-[#EBEAEA] bg-[#00006B] flex flex-row items-center rounded-[12px] py-[0.8rem] px-[1rem]'>
                  <Icon
                    icon='material-symbols:info-rounded'
                    className='text-[2.5rem]'
                  />
                  <span>
                    {" "}
                    Welcome to our platform! Your KYC details are currently
                    under review by our team, and payment functionality will be
                    unlocked shortly.
                  </span>
                </div>
              )}

              {parseInt(amountLimit?.usagelimit) < 2000 && (
                <div className='mb-5 text-[0.9rem] mt-[0.5rem] gap-[0.5rem] text-[#EBEAEA] bg-[#00006B] flex flex-row items-center rounded-[12px] py-[0.8rem] px-[1rem]'>
                  <Icon
                    icon='material-symbols:info-rounded'
                    className='text-[2.5rem]'
                  />
                  <span>
                    {" "}
                    Your payinstacard quota has been exhausted, it will renew
                    next month, or please feel free to contact payinstacard
                    support for assistance.
                  </span>
                </div>
              )}
              <div className='flex flex-col gap-[1rem] mb-5'>
                <label
                  htmlFor='amount'
                  className='text-[#FFF] font-[Inter] text-[16px] xs:text-[20px] not-italic font-bold leading-[normal] '>
                  Enter Amount*
                </label>

                {/* <CurrencyInput
            
              className="bg-transparent outline-none text-[#2F2F2F] font-bold text-[0.8rem] lg:text-[1rem] appearance-none"
              style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.2)" }}
              value={amount}
              defaultValue={0}
              intlConfig={{ locale: 'en-US', currency: 'GBP' }}
              {...register("amount", {
                required: "Amount is required",
                min: { value: 5, message: "Minimum value is 2000" },
                max: { value: 50000, message: "Maximum value is 50000" },
              })}
              onValueChange={(e) => handleAmount(e)}
            /> */}
                <input
                  type='text'
                  className=' outline-none text-[#ffffff] p-4 bg-[#131313] font-bold text-[0.8rem] lg:text-[1rem] appearance-none rounded-[4px] border-[1px] border-[solid] border-[rgba(255,255,255,0.20)]'
                  value={amount === 0 ? 0 : amount}
                  {...register("amount", {
                    required: "Amount is required",
                    min: {
                      value: MIN_AMOUNT_LIMIT,
                      message: "Minimum value is " + MIN_AMOUNT_LIMIT,
                    },
                    max: {
                      value: MAX_AMOUNT_LIMIT,
                      message: "Maximum value is " + MAX_AMOUNT_LIMIT,
                    },
                  })}
                  onChange={(e) => {
                    //

                    handleAmount(e);
                  }}
                />

                {!isAmountvalid && (
                  <div className='text-[0.9rem] mt-[-9px] gap-[0.5rem] text-[#676767]  flex flex-row  rounded-[12px] '>
                    <Icon
                      icon='material-symbols:info-rounded'
                      className='text-[1.5rem]'
                    />
                    <span className='text-[rgba(255,_255,_255,_0.50)] font-[Inter] text-[12px] xs:text-[14px] not-italic font-normal leading-[normal]]'>
                      Please enter amount between{" "}
                      {MIN_AMOUNT_LIMIT.toString().replace(
                        /\B(?=(\d{3})+(?!\d))/g,
                        ","
                      )}{" "}
                      and{" "}
                      {MAX_AMOUNT_LIMIT.toString().replace(
                        /\B(?=(\d{3})+(?!\d))/g,
                        ","
                      )}
                      .
                    </span>
                  </div>
                )}
              </div>
              {/* <div className="flex flex-col gap-[0.5rem]">
            <label
              htmlFor="amount"
              className="text-[#8A8A8A] text-[0.8rem] lg:text-[1rem] font-bold "
            >
              Select anyone
            </label>
            <div className="flex gap-10 text-[#8A8A8A] active:text-black text-[0.9rem] lg:text-[1.12rem] font-bold">
              <Radio
                id="bankAccount"
                name="type"
                color="gray"
                label="Bank Account"
                className=""
                defaultChecked
              />
              <Radio id="upi" name="type" color="gray" label="UPI" />
            </div>
          </div> */}
              {status === "error" && isAmountvalid && (
                <div className='flex flex-col gap-[0.5rem] mb-5'>
                  <p className='mb-2'>No Beneficiary Data found</p>{" "}
                  <button type="button" 
                  onClick={() => setIsOpenModal(true)}
                    
                    className=' rounded-[30px] text-center w-full px-[1.5rem] xs:px-[3rem] py-[0.7rem] text-[0.7rem] xs:text-[1rem] font-bold text-white bg-[linear-gradient(90deg,_#F4795A_0.04%,_#F4705C_15.04%,_#F55960_38.04%,_#F73367_67.04%,_#F90070_100.04%)]'>
                    Add New Bank Account
                  </button>
                </div>
              )}
              {status === "loading" && isAmountvalid && <p>Fetching data...</p>}
              {status === "success" && isAmountvalid && (
                <div className='flex flex-col gap-[1rem] mb-5'>
                  <label
                    htmlFor='accounId'
                    className='text-[#FFF] font-[Inter] text-[16px] xs:text-[20px] not-italic font-bold leading-[normal] '>
                    Beneficiary’s account number/ UPI ID
                  </label>

                  {/* <Select
                variant="standard"
                className="h-[53.6px] xs:h-[56px] outline-none text-[#ffffff] font-bold text-[0.8rem] lg:text-[1rem] rounded-[4px] border-[1px] border-[solid] border-[rgba(255,255,255,0.20)]"
                style={{ padding: "16px" }}
                placeholder="Select Beneficiary"
                // {...register("beneficiary", {
                //   required: "Beneficiary is required",
                // })}

                onChange={(e) => {
                  payset(e);
                }}
                selected={(element) => {
                  if (
                    element &&
                    React.cloneElement(element, {
                      disabled: true,
                      className: "text-black-900",
                    })
                  ) {
                    return JSON.parse(
                      React.cloneElement(element, {
                        disabled: true,
                        className: "text-black-900",
                      }).props.value
                    ).name;
                  }
                }}
              >
                {data.map((datas, index) => (
                  <Option
                    key={index}
                    value={JSON.stringify({
                      paydatas: datas.payment_info,
                      bend_ids: datas.beneficiary_id,
                      name: datas.beneficiary_name,
                      address: datas.beneficiary_address,
                    })}
                    className=""
                  >
                    <span className="ml-[16px]">{datas?.beneficiary_name}</span>
                  </Option>
                ))}
              </Select> */}
                  <DropdownMenu
                    beneficiaryData={data}
                    payset={payset}
                    paymentInfo={_.isEmpty(payInfo)}
                    status={status}
                    setIsOpenModal={setIsOpenModal}
                  />

                  {/* <input
              type="text"
              className="bg-transparent outline-none  text-[#2F2F2F] font-bold text-[0.8rem] lg:text-[1rem] "
              style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.2)" }}
              {...register("otp", {
                required: "OTP is required.",
                pattern: {
                  value: /^\d{6}$/,
                  message: "OTP must be a 6-digit number.",
                },
              })}
            /> */}
                  {/* <div
              className={`flex flex-row gap-[0.5rem] overflow-x-auto w-[15rem] lg:w-[21vw] py-[0.5rem] lg:py-[0.3rem]  ${
                isLargeScreen ? "scrollbar" : ""
              }`}
            >
              <button
                type="button"
                className="bg-black text-white rounded-full px-[0.8rem] py-[0.3rem] font-[600] text-[0.75rem] lg:text-[0.9rem]"
              >
                @ybl
              </button>
              <button
                type="button"
                className="bg-black text-white rounded-full px-[0.8rem] py-[0.3rem] font-[600] text-[0.75rem] lg:text-[0.9rem]"
              >
                @paytm
              </button>
              <button
                type="button"
                className="bg-black text-white rounded-full px-[0.8rem] py-[0.3rem] font-[600] text-[0.75rem] lg:text-[0.9rem]"
              >
                @icici
              </button>
              <button
                type="button"
                className="bg-black text-white rounded-full px-[0.8rem] py-[0.3rem] font-[600] text-[0.75rem] lg:text-[0.9rem]"
              >
                @upi
              </button>
              <button
                type="button"
                className="bg-black text-white rounded-full px-[0.8rem] py-[0.3rem] font-[600] text-[0.75rem] lg:text-[0.9rem]"
              >
                @amazon
              </button>
            </div> */}

                  {!payInfo && (
                    <div className='text-[0.9rem] mt-[0.5rem] gap-[0.5rem] text-white bg-[#DA4633] flex flex-row items-center rounded-[12px] py-[0.8rem] px-[1rem]'>
                      <Icon
                        icon='material-symbols:info-rounded'
                        className='text-[1.5rem]'
                      />
                      <span>This field is required.</span>
                    </div>
                  )}
                </div>
              )}
              {/* <div className="flex flex-col gap-[0.5rem]">
            <label
              htmlFor="accounId"
              className="text-[#8A8A8A] text-[0.8rem]  lg:text-[1rem] font-bold "
            >
              Re-enter bank account number
            </label>
            <input
              type="number"
              id="myinput"
               value={value?.reEnterBankAccountNum.replace(/./g, '*')}
              className="bg-transparent outline-none  text-[#2F2F2F] font-bold text-[0.8rem] lg:text-[1rem] "
              style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.2)" }}
              {...register("reEnterBankAccountNum", {
                required: "OTP is required.",
                pattern: {
                  value: /^\d{6}$/,
                  message: "OTP must be a 6-digit number.",
                },
              })}
             
            />
       
            {errors?.otp && (
              <div className="text-[0.9rem] mt-[0.5rem] gap-[0.5rem] text-[#EBEAEA] bg-[#DA4633] flex flex-row items-center rounded-[12px] py-[0.8rem] px-[1rem]">
                <Icon
                  icon="material-symbols:info-rounded"
                  className="text-[1.5rem]"
                />
                <span>{errors?.otp?.message}</span>
              </div>
            )}
          </div>
          <div className="flex flex-col gap-[0.5rem]">
            <label
              htmlFor="accounId"
              className="text-[#8A8A8A] text-[0.8rem]  lg:text-[1rem] font-bold "
            >
              IFSC code
            </label>
            <input
              type="text"
              className="bg-transparent outline-none  text-[#2F2F2F] font-bold text-[0.8rem] lg:text-[1rem] "
              style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.2)" }}
              {...register("otp", {
                required: "OTP is required.",
                pattern: {
                  value: /^\d{6}$/,
                  message: "OTP must be a 6-digit number.",
                },
              })}
            />
         
            {errors?.otp && (
              <div className="text-[0.9rem] mt-[0.5rem] gap-[0.5rem] text-[#EBEAEA] bg-[#DA4633] flex flex-row items-center rounded-[12px] py-[0.8rem] px-[1rem]">
                <Icon
                  icon="material-symbols:info-rounded"
                  className="text-[1.5rem]"
                />
                <span>{errors?.otp?.message}</span>
              </div>
            )}
          </div>
          <div className="flex flex-col gap-[0.5rem]">
            <label
              htmlFor="beneficiaryName"
              className="text-[#8A8A8A] text-[0.8rem] lg:text-[1rem] font-bold "
            >
              Beneficiary name
            </label>
            <input
              type="text"
              className="bg-transparent outline-none  text-[#2F2F2F] font-bold text-[0.8rem] lg:text-[1rem] "
              style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.2)" }}
              {...register("otp", {
                required: "OTP is required.",
                pattern: {
                  value: /^\d{6}$/,
                  message: "OTP must be a 6-digit number.",
                },
              })}
            />

            {errors?.otp && (
              <div className="text-[0.9rem] mt-[0.5rem] gap-[0.5rem] text-[#EBEAEA] bg-[#DA4633] flex flex-row items-center rounded-[12px] py-[0.8rem] px-[1rem]">
                <Icon
                  icon="material-symbols:info-rounded"
                  className="text-[1.5rem]"
                />
                <span>{errors?.otp?.message}</span>
              </div>
            )}
          </div>
          <div className="flex flex-col gap-[0.5rem]">
            <label
              htmlFor="beneficiaryName"
              className="text-[#8A8A8A] text-[0.8rem] lg:text-[1rem] font-bold "
            >
              Your name
            </label>
            <input
              type="text"
              className="bg-transparent outline-none  text-[#2F2F2F] font-bold text-[0.8rem] lg:text-[1rem] "
              style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.2)" }}
              {...register("otp", {
                required: "OTP is required.",
                pattern: {
                  value: /^\d{6}$/,
                  message: "OTP must be a 6-digit number.",
                },
              })}
            />

            {errors?.otp && (
              <div className="text-[0.9rem] mt-[0.5rem] gap-[0.5rem] text-[#EBEAEA] bg-[#DA4633] flex flex-row items-center rounded-[12px] py-[0.8rem] px-[1rem]">
                <Icon
                  icon="material-symbols:info-rounded"
                  className="text-[1.5rem]"
                />
                <span>{errors?.otp?.message}</span>
              </div>
            )}
          </div> */}
              {/* <div
            className="flex flex-col gap-[1rem] px-[1rem] py-[0.5rem]"
            style={{
              background: "#F8F8FF",
              border: " 0.932084px dashed rgba(14, 60, 110, 0.3)",
              borderRadius: "3.72834px",
            }}
          >
            <div className="flex flex-row justify-end text-[#8A8A8A] text-[0.75rem] font-normal">
              <p>(Optional)</p>
            </div>
            <div className="flex flex-row justify-center items-center gap-[1rem] text-[0.8rem] lg:text-[1rem] font-bold text-[#8A8A8A]">
              <Icon
                icon="feather:upload-cloud"
                className="text-[1.5rem] lg:text-[2rem] font-[400] text-[#00006B]/[0.8]"
              />{" "}
              <p>
                <span
                  className="text-[#00006B] underline underline-offset-4 cursor-pointer"
                  onClick={() => {
                    document.querySelector(".input-field").click();
                  }}
                >
                  Upload{" "}
                </span>
                Rental agreement
              </p>
            </div>
            <p className=" text-center lg:text-[0.75rem] text-[#8A8A8A] text-[0.5rem]">
              Supported formates: JPEG, PNG, PDF, File size <br /> no more than
              10MB
            </p>
            <input
              type="file"
              hidden
              className="input-field"
              {...register("rentalAgreement")}
              // onChange={handleSubmit((data) => {
              //   const file = data.shopCoverImage[0];
              //   if (file) {
              //     dispatch(
              //       vendorRegistrationActions.updateOnBoardingInformation({
              //         shopCoverImage: file,
              //       })
              //     );
              //   }
              // })}
            />
          </div>
          <div className="flex flex-col gap-[0.5rem]">
            <p className="text-[#8A8A8A] text-[0.8rem] lg:text-[1rem] font-bold">Uploaded</p>
            <div className="flex flex-row justify-between border border-[#11AF22] rounded-md px-[1rem] py-[0.2rem] items-center">
              <span className="text-[#0F0F0F] text-[0.75rem]">No file Selected</span>
              <div className="rounded-full bg-[#FFF3F3] p-[0.5rem] cursor-pointer">
                <img src={deleteIcon} alt="" className="w-[1rem] h-auto" />
              </div>
            </div>
          </div> */}

              {/* {isAmountvalid ? (
            <p className="text-[0.75rem] lg:text-[0.9rem] font-bold text-white text-center">
              By paying you agree to our{" "}
              <Link to={"/terms-and-conditions"}>
                <span className="text-[#F73367] whitespace-nowrap">
                  Terms & Conditions
                </span>{" "}
              </Link>
              &{" "}
              <Link to={"/refund-policy"}>
                <span className="text-[#F73367] whitespace-nowrap">
                  Refund Policy
                </span>{" "}
              </Link>
              which is mentioned in our policy terms.
            </p>
          ) : null} */}
            </GrayColoredCardWrapper>

            {isAmountvalid ? (
              <div className='lg:w-[50%] p-[24px] xs:p-[40px] lg:px-[80px] lg:py-[40px] text-white font-inter rounded-[25px] border-[1px] border-solid border-[rgba(255,255,255,0.04)] bg-[#202020]'>
                {isAmountvalid ? (
                  <GrayColoredCardWrapper className='flex flex-col gap-[30px] lg:gap-[40px]'>
                    <p className='text-[#FFF] font-[Inter] text-[20px] xs:text-[24px] not-italic font-bold leading-[normal]'>
                      Payment Details
                    </p>

                    <div className=' text-[rgba(255,_255,_255)] font-[Inter] text-[16px] xs:text-[20px] not-italic font-medium leading-[normal] flex flex-row justify-between'>
                      {/* <span>Amount</span>{" "} */}
                      <span>Net Settlement Amount</span>{" "}
                      {/* <span>{_.isNaN(totalAmount) ? 0 : totalAmount}</span> */}
                      <span>{_.isNaN(amount) ? 0 : amount}</span>
                    </div>
                    <div className='text-[rgba(255,_255,_255,_0.70)] font-[Inter] text-[16px] xs:text-[20px] not-italic font-medium leading-[normal] flex flex-row justify-between'>
                      <span>Convenience fees</span>{" "}
                      <span>{_.isNaN(convieFee) ? 0 : convieFee}</span>
                    </div>
                    <div className=' text-[rgba(255,_255,_255,_0.70)] font-[Inter] text-[16px] xs:text-[20px] not-italic font-medium leading-[normal] flex flex-row justify-between'>
                      {/* <span>Net Settlement Amount</span>{" "} */}
                      <span>Total Amount</span>{" "}
                      <span>{netSettledAmount || 0}</span>
                    </div>
                    {isAmountvalid ? (
                      <div className='flex flex-row items-center'>
                        <Checkbox
                          checked={termsAndConditionsCheck}
                          onChange={() => {
                            setTermsAndConditionsCheck(
                              !termsAndConditionsCheck
                            );
                          }}
                        />

                        <p className='text-[rgba(255,_255,_255,_0.50)] mt-[20px] xs:mt-[0px]  font-[Inter] text-[12px] xs:text-[16px] not-italic font-semibold leading-[22.4px]'>
                          By paying you agree to our{" "}
                          <Link to={"/terms-and-conditions"}>
                            <span className='text-[#FFF] font-[Inter]  not-italic font-semibold leading-[22.4px] [text-decoration-line:underline]'>
                              Terms & Conditions
                            </span>{" "}
                          </Link>
                          &{" "}
                          <Link to={"/refund-policy"}>
                            <span className='text-[#FFF] font-[Inter]  not-italic font-semibold leading-[22.4px] [text-decoration-line:underline]'>
                              Refund Policy
                            </span>{" "}
                          </Link>
                          which is mentioned in our policy terms.
                        </p>
                      </div>
                    ) : null}
                  </GrayColoredCardWrapper>
                ) : null}

                {/* <div className="mt-2">

         <p className="text-[#FF0000]">NOTE: We are not accepting rent payments until the 22nd of February.</p>

        </div> */}

                {/** disable payment option to enable change button type="submit" and remove "true && from disabled attribute and show Coming Soon */}
                {emailVerifieds &&
                isAmountvalid &&
                paymentDisables &&
                kycStatuss &&
                !warnModelOn ? (
                  <button
                    type='button'
                    disabled={
                      true ||
                      isLoading ||
                      !isValid ||
                      _.isEmpty(payInfo) ||
                      !termsAndConditionsCheck ||
                      status === "error"
                    }
                    className={`${
                      !isValid ||
                      isLoading ||
                      _.isEmpty(payInfo) ||
                      !termsAndConditionsCheck ||
                      status === "error"
                        ? "bg-[grey]"
                        : "bg-[linear-gradient(90deg,_rgba(244,121,90,1)_12%,_rgba(244,112,92,1)_33%,_rgba(245,89,96,1)_55%,_rgba(247,51,103,1)_76%,_rgba(249,0,112,1)_100%)]"
                    } mt-[80px] xs:mt-[32px] text-center w-full text-[16px] xs:text-[20px] rounded-[30px] text-white py-[0.8rem] font-[800] flex flex-row items-center justify-center gap-[0.5rem]`}>
                    {isLoading && (
                      <CgSpinner size={20} className='mt-1 animate-spin' />
                    )}
                    Total Pay ₹{_.isNaN(totalAmount) ? 0 : totalAmount}
                  </button>
                ) : null}
              </div>
            ) : null}
          </form>

          {/* AIRPAY PAYMENT FORM HERE */}
          <form ref={formRef} method='POST' action={APIUrls.airpay_payment}>
            <input type='hidden' name='token' ref={tokenRef} value='' />

            <input type='hidden' name='payData' value='' ref={inputRef} />
            <input type='hidden' name='key' value='' ref={keyref} />
          </form>
        </ThemeProvider>
        {isOpenModal && (
          <AddBeneficiaryModal
            isOpenModal={isOpenModal}
            setIsOpenModal={setIsOpenModal}
            type='EDUCATION'
          />
        )}
      </div>
    </CardWrapper>
  );
};

export default PaymentCardForEducation;
