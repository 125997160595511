import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import React, { useEffect, useState } from "react";
import logo from "../../assets/revamped/PayinstacardRLogo.png";
import BBPSlogo from "../../assets/revamped/bbps-assured.png";
import Rupee from "../../assets/revamped/rupee.png";

// eslint-disable-next-line no-unused-vars
import _ from "lodash";

const styles = StyleSheet.create({
  page: {
    fontFamily: "Helvetica",
    paddingTop: 30,
    paddingLeft: 50,
    paddingRight: 50,
    paddingBottom: 50,
  },

  logo_style: {
    width: 70,
  },
  invoice: {
    fontSize: 13,
    fontWeight: "bold",
  },
  firstSection: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 16,
  },
  secondSection: {
    color: "#000",
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 24,
    textAlign: "center",
  },
  border_bottom: {
    borderBottom: "1px solid black",
    opacity: "0.9",
    marginBottom: 16,
  },
  leftFont: {
    color: "rgba(0, 0, 0, 0.70)",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
  },
  rightFont: {
    color: "#000",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",
  },
  rightFontForRefId: {
    color: "#000",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",
    wordWrap: "break-word",
  },
  rightFontForRupee: {
    color: "#000",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },

  imageContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 20,
  },
});

// Create Receipt component
const PdfRecieptForBBPS = ({ payment }) => {
  const [paData, setPayData] = useState(payment.response);
  console.log("payment data------>", payment);

  useEffect(() => {
    setPayData(payment);

    // console.log("aasas", JSON.parse(payment.UserDefinedData?.UserDefined1));
    // console.log("asas", paData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // function maskBankAccountNumber(accountNumber) {
  //   if (_.isEmpty(accountNumber)) return;
  //   const visibleDigits = 4;
  //   const maskedLength = accountNumber.length - visibleDigits;
  //   const maskedPart = "x".repeat(maskedLength);
  //   const visiblePart = accountNumber.slice(-visibleDigits);
  //   const maskedAccountNumber = maskedPart + visiblePart;
  //   return maskedAccountNumber;
  // }

  const formateDate = (timestamp) => {
    const date = new Date(timestamp);

    // Extract day, month, and year components
    const day = date.getUTCDate();
    const month = date.getUTCMonth() + 1; // Months are zero-indexed, so we add 1
    const year = date.getUTCFullYear();

    // Extract hours and minutes components
    let hours = date.getHours();
    const minutes = date.getMinutes();

    // Determine AM/PM and format hours
    const ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'

    // Format the components to ensure they are two digits
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

    // Create the formatted date string in "dd/mm/yyyy" format
    return `${formattedDay}-${formattedMonth}-${year} & ${hours}:${formattedMinutes}${ampm}`;
  };
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.imageContainer}>
          <Image
            src={logo}
            style={{
              width: "201px",
              height: "48px",
            }}
          />
          <Image
            src={BBPSlogo}
            style={{
              width: "62px",
              height: "64px",
            }}
          />
        </View>

        <View style={styles.secondSection}>
          <Text>Receipt of Payment</Text>
        </View>

        <View style={styles.border_bottom}></View>

        <View style={styles.firstSection}>
          <View>
            <Text style={styles.leftFont}>Status</Text>
          </View>

          <View style={{ width: "40%" }}>
            <Text
              style={{
                color: "#00B300",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "normal",
              }}
            >
              Transaction Successful
            </Text>
          </View>
        </View>

        <View
          style={{
            borderBottom: "1px solid black",
            opacity: "0.9",
            marginBottom: 16,
          }}
        ></View>

        <View style={styles.firstSection}>
          <View>
            <Text style={styles.leftFont}>Order ID</Text>
          </View>

          <View style={{ width: "40%" }}>
            <Text style={styles.rightFont}>{paData?.OrderId} </Text>
          </View>
        </View>

        <View>
          <View>
            <Text style={styles.leftFont}>BBPS Reference ID</Text>
          </View>

          <View style={{ marginBottom: 16, marginTop: 5 }}>
            <Text style={styles.rightFontForRefId}>
              {paData?.billData?.billInfo?.responseData?.response?.refId}
            </Text>
          </View>
        </View>

        <View style={styles.firstSection}>
          <View>
            <Text style={styles.leftFont}>Date & Time </Text>
          </View>

          <View style={{ width: "40%" }}>
            <Text style={styles.rightFont}>
              {formateDate(payment?.TransactionTime)}
            </Text>
          </View>
        </View>

        <View
          style={{
            borderBottom: "1px solid black",
            opacity: "0.9",
            marginBottom: 16,
          }}
        ></View>

        <View style={styles.firstSection}>
          <View>
            <Text style={styles.leftFont}>Customer Name</Text>
          </View>

          <View style={{ width: "40%" }}>
            <Text style={styles.rightFont}>
            Sai Krishna Musunuru
              {/* {paData?.CustomerData?.FirstName} {paData?.CustomerData?.LastName} */}
            </Text>
          </View>
        </View>
        <View style={styles.firstSection}>
          <View>
            <Text style={styles.leftFont}>Email</Text>
          </View>

          <View style={{ width: "40%" }}>
            <Text style={styles.rightFont}>
            {/* {paData?.CustomerData?.Email} */}

            saikrishnabtech8@gmail.com
            
            </Text>
          </View>
        </View>
        {/* <View style={styles.firstSection}>
          <View>
            <Text style={styles.leftFont}>Mobile Number</Text>
          </View>

          <View style={{ width: "40%" }}>
            <Text style={styles.rightFont}>
              {paData?.CustomerData?.MobileNo}
            </Text>
          </View>
        </View> */}

        <View style={styles.firstSection}>
          <View>
            <Text style={styles.leftFont}>
              {
                paData?.billData?.billInfo?.requestData?.billDetails
                  ?.customerParams[0].name
              }
            </Text>
          </View>

          <View style={{ width: "40%" }}>
            <Text style={styles.rightFont}>
              {" "}
              {
                paData?.billData?.billInfo?.requestData?.billDetails
                  ?.customerParams[0].value
              }
            </Text>
          </View>
        </View>

        <View style={styles.firstSection}>
          <View>
            <Text style={styles.leftFont}>Operator</Text>
          </View>

          <View style={{ width: "40%" }}>
            <Text style={styles.rightFont}>
              {paData?.billData?.billerDetail?.billerName}
            </Text>
          </View>
        </View>

        <View
          style={{
            borderBottom: "1px solid black",
            opacity: "0.9",
            marginBottom: 16,
          }}
        ></View>

        <View style={styles.firstSection}>
          <View>
            <Text style={styles.leftFont}>Payment Method</Text>
          </View>

          <View style={{ width: "40%" }}>
            <Text style={styles.rightFont}>{paData?.PaymentMethod} </Text>
          </View>
        </View>
        <View style={styles.firstSection}>
          <View>
            <Text style={styles.leftFont}>Transaction Id</Text>
          </View>

          <View style={{ width: "40%" }}>
            <Text style={styles.rightFont}>{paData?.OrderId}</Text>
          </View>
        </View>

        <View
          style={{
            borderBottom: "1px solid black",
            opacity: "0.9",
            marginBottom: 16,
          }}
        ></View>

        <View style={styles.firstSection}>
          <View>
            <Text style={styles.leftFont}>Amount</Text>
          </View>

          <View style={{ width: "40%" }}>
            <View style={styles.rightFontForRupee}>
              <Image
                src={Rupee}
                style={{
                  width: 16,
                }}
              />
              <Text>{paData?.billData?.billInfo?.amount}</Text>
            </View>
          </View>
        </View>
        <View style={styles.firstSection}>
          <View>
            <Text style={styles.leftFont}>Convenience Fee</Text>
          </View>

          <View style={{ width: "40%" }}>
            <View style={styles.rightFontForRupee}>
              <Image
                src={Rupee}
                style={{
                  width: 16,
                }}
              />
              <Text>0</Text>
            </View>
          </View>
        </View>

        <View
          style={{
            borderBottom: "1px solid black",
            opacity: "0.9",
            marginBottom: 16,
          }}
        ></View>

        <View style={styles.firstSection}>
          <View>
            <Text
              style={{
                color: "rgba(0, 0, 0)",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "normal",
              }}
            >
              Total Amount{" "}
            </Text>
          </View>

          <View style={{ width: "40%" }}>
            <View style={styles.rightFontForRupee}>
              <Image
                src={Rupee}
                style={{
                  width: 16,
                }}
              />
              <Text>{paData?.billData?.billInfo?.amount}</Text>
            </View>
          </View>
        </View>

        <View
          style={{
            borderBottom: "1px solid black",
            opacity: "0.9",
            marginBottom: 16,
          }}
        ></View>
        <View
          style={{
            textAlign: "center",
          }}
        >
          <Text
            style={{
              fontSize: 14,
              color: "#000",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "normal",
            }}
          >
            Customer support :{" "}
            <Text
              style={{
                fontSize: 16,
                color: "#00006B",
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "normal",
              }}
            >
              support@payinstacard.com
            </Text>
          </Text>
        </View>
      </Page>
    </Document>
  );
};

export default PdfRecieptForBBPS;
