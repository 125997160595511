import React from "react";
import { NavLink } from "react-router-dom";
import { Icon } from "@iconify/react";

function ServicesLogo({ logoData }) {
  return (
    <div className="">
      <NavLink
        to={logoData.path}
        className="flex min-w-[100px] xs:min-w-[113px] p-[8px] xs:p-[12px] flex-col items-center gap-[12px] xs:gap-[1rem] hover:bg-[linear-gradient(90deg,_#F4795A_0.04%,_#F4705C_15.04%,_#F55960_38.04%,_#F73367_67.04%,_#F90070_100.04%)] rounded-[0.5rem]"
      >
        <img
          src={logoData.logo}
          alt=""
          className="w-[24px] h-[24px] xs:w-auto xs:h-auto  "
        />

        <span className="text-center font-[Inter] text-[14px] xs:text-[16px] not-italic font-medium leading-[normal] flex justify-center items-center gap-[0.1rem]">
          {logoData.label}

          {logoData?.label === "View All" && (
            <Icon icon="bx:right-arrow-alt" className="text-[1.5rem]" />
          )}
        </span>
      </NavLink>
    </div>
  );
}

export default ServicesLogo;
