import React, { useEffect, useState } from "react";
import { RouterProvider } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "./components/loader/Loader";
import { router } from "./routes/Route";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import LogVisitors from "./components/utils/LogVisitors";

import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import Maintain from "./Maintain";
import { APIUrls } from "./baseUrl/BaseUrl";
import NetworkModel from "./components/modals/NetworkModel";
import { userActions } from "./stores/IndexRedux";

function App() {
  const [loading, setLoading] = useState(false);
  // const [BlockIP, setBlockIP] = useState(false);
  const [isOnline, setIsOnline] = useState(window.navigator.onLine);
  const dispatch = useDispatch();

  const userLoading = useSelector((state) => state.userData.userLoading);

  // const userblockstate = useSelector((state) => state.userData.UserBlockIp);
  // const BlockUser = (values) => {
  //   

  //   const states = userblockstate;

  //   

  //   
  // };

  useEffect(() => {
    const images = [
      "./assets/aadhaarAvatar.svg",
      "./assets/blackScrew.svg",
      "./assets/card1.svg",
      "./assets/card2.svg",
      "./assets/card3.svg",
      "./assets/cil_education.svg",
      "./assets/delete.svg",
      "./assets/easy.svg",
      "./assets/error404.svg",
      "./assets/failure.svg",
      "./assets/homeRent.svg",
      "./assets/instant.svg",
      "./assets/officeRent.svg",
      "./assets/payinstacardLogo.svg",
      "./assets/Pen.svg",
      "./assets/shield.svg",
      "./assets/success.svg",
      "./assets/aboutUsHeroBackground.svg",
      "./assets/FINANCE.svg",
      "./assets/heroPhone.svg",
      "./assets/iPhoneX.svg",
      "./assets/nageshPic.svg",
      "./assets/saiKrishnaPic.svg",
    ];

    cacheImages(images);
  }, []);

  const cacheImages = async (srcArr) => {
    const promises = await srcArr.map((src) => {
      return new Promise((resolve, reject) => {
        const img = new Image();

        img.src = src;
        img.onload = resolve();
        img.onerror = reject();
      });
    });
    await Promise.all(promises);
    setLoading(false);
  };
  const queryClient = new QueryClient();

  const [serverDown, setservrDownd] = useState(false);

  async function checkApiStatus() {
    setLoading(true);
    try {
      const response = await axios.get(APIUrls.baseUrl);

      // Check the HTTP status code to determine the API status
      if (response.status === 200) {
        //
        setLoading(false);
      } else {
        //

        setLoading(false);

        setservrDownd(true);
        // You can handle different status codes accordingly
      }
    } catch (error) {
      setLoading(false);
      console.error("Error:", error);
      //

      setservrDownd(true);
      // Handle the error, which could indicate that the API is down
    }
  }

  const loginBlock = async () => {
    setLoading(true);
    function isAnyTrue(threat) {
      return (
        threat.is_tor ||
        threat.is_proxy ||
        threat.is_anonymous ||
        threat.is_known_attacker ||
        threat.is_known_abuser ||
        threat.is_threat ||
        threat.is_bogon
      );
    }

    try {
      setLoading(true);
      await axios
        .post(APIUrls.login_blocker)
        .then((res) => {
          //
          if (res.status === 200) {
            ////

            setLoading(false);
            const location = res.data.response;
            if (location.status === 200) {
              const result = isAnyTrue(location.threat);
              //BlockUser(true)

              if (location?.country_name !== "India") {
                // setBlockIP(true);
                // BlockUser(true)

                dispatch(userActions.setUserBlock(true));
              }
              if (result) {
                // 
                // setBlockIP(result);
                dispatch(userActions.setUserBlock(result));
              }
            }
            //
          }
        })
        .catch((err) => {
          //
          setLoading(false);
        });
      // Check the HTTP status code to determine the API status
    } catch (error) {
      setLoading(false);
      console.error("Error:", error.message);
      //

      // Handle the error, which could indicate that the API is down
    }
  };

  useEffect(() => {
    checkApiStatus();
    loginBlock();
    LogVisitors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
    };

    const handleOffline = () => {
      setIsOnline(false);
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      {!isOnline ? (
        <NetworkModel />
      ) : serverDown ? (
        <Maintain />
      ) : (
        <div className="font-inter">
          <ToastContainer theme="dark" />
          {loading || userLoading ? (
            <Loader />
          ) : (
            <RouterProvider router={router} />
          )}
        </div>
      )}

      {/* {!isOnline ? (
        <NetworkModel />
      ) : serverDown ? (
        <Maintain />
      ) : BlockIP ? (
        <Ipblocklayout />
      ) : (
        <div className=" font-inter">
          <ToastContainer />
          {loading || userLoading ? (
            <Loader />
          ) : (
            <RouterProvider router={router} />
          )}
        </div>
      )} */}
    </QueryClientProvider>
  );
}

export default App;
