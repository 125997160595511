import React from "react";
import { NavLink, useLocation } from "react-router-dom";

function UtilityTab(props) {
  const { path, title, logo, handleTabClick } = props;
  const location = useLocation();
  const mainUrl = location.pathname;

  return (
    <NavLink
      to={path}
      // className={`flex items-center relative  gap-[16px]`}
      onClick={() => handleTabClick(title)}
      className={({ isActive, isPending }) =>
        `flex items-center relative  gap-[16px] shrink-0 ${
          isActive ? "text-[20px]" : ""
        }  `
      }
    >
      <img src={logo} alt="" /> <span>{title}</span>
      {mainUrl.includes(path) && (
        <div className="absolute bottom-[-15px] left-0  w-full h-1 bg-[linear-gradient(90deg,_rgba(244,121,90,1)_12%,_rgba(244,112,92,1)_33%,_rgba(245,89,96,1)_55%,_rgba(247,51,103,1)_76%,_rgba(249,0,112,1)_100%)]"></div>
      )}
    </NavLink>
  );
}

export default UtilityTab;
