import {
  RecaptchaVerifier,
  reauthenticateWithPhoneNumber,
  sendPasswordResetEmail,
  signInWithCustomToken,
  signInWithPhoneNumber,
  signOut,
  updateProfile,
} from "firebase/auth";
import React, { useContext, useEffect, useState } from "react";
import { auth } from "../Firebase";
//import APIUrls from "../BaseUrl";
import _ from "lodash";
import { useDispatch } from "react-redux";
import { APIUrls } from "../baseUrl/BaseUrl";
import { fetchUser, generateReferal } from "./UserRedux";
const AuthContext = React.createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthContextProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState();
  const [userRole, setUserRole] = useState();
  const [loading, setLoading] = useState(true);
  const [recaptchaVerifier, setrecaptchaVerifier] = useState({});
  const [confirmationResult, setConfirmationResult] = useState();
  const [refresh, setrefresh] = useState(0);
  const [walletLimitData, setWalletlimitdata] = useState(0);
  const [warnModelOn, setWarnModel] = useState(false);

  const dispatch = useDispatch();

  // const userblockstate = useSelector((state) => state.userData.UserBlockIp);

  const [gatewayset, SetGateway] = useState("airpay");

  //
  let recaptchaVerifier2 = null;
  const setupRecaptcha = (phone) => {
    ////

    if (_.isEmpty(recaptchaVerifier)) {
      recaptchaVerifier2 = new RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "invisible",
        },
        auth
      );

      //

      setrecaptchaVerifier(recaptchaVerifier2);
      recaptchaVerifier2.render();

      return recaptchaVerifier2;
    }
    return recaptchaVerifier;
  };

  function logout() {
    // 
    return signOut(auth);
  }

  const updateUsers = async () => {
    const user = auth.currentUser;
    await user.reload();
    setCurrentUser(user);
  };

  async function login(phoneNumber) {
    const res = setupRecaptcha(phoneNumber);
    //
    return await signInWithPhoneNumber(auth, phoneNumber, res);
  }
  async function customLogin(token) {
    
      
    return await signInWithCustomToken(auth, token);
  }

  function resetPassword(email) {
    return sendPasswordResetEmail(auth, email);
  }
  function updateUser(uid, role) {
    return updateUser(uid, role);
  }

  function updateEmail(email) {
    return currentUser.updateEmail(email);
  }

  function updatePassword(password) {
    return currentUser.updatePassword(password);
  }

  function getAccessToken() {
    return currentUser?.getIdToken(true);
  }
  function getCurrentUser() {
    return currentUser.getUser();
  }
  function saveUserRole(value) {
    setUserRole(value);
  }
  function updateProfilePicture(photoURL) {
    return updateProfile(currentUser, { photoURL });
  }

  function reAuthenticate(phoneNumber) {
    // const credential = promptForCredentials();

    return reauthenticateWithPhoneNumber(currentUser, phoneNumber);
  }

  function re_fresh_user_data() {
    return setrefresh(refresh + 1);
  }

  // const getUserRoleFunc= async(user)=>{
  //   try {
  //     //
  //     const result= await fetch(APIUrls.getUserRole,{
  //       method:"GET",
  //       headers:{
  //        Authorization:`Bearer ${user.accessToken}`,
  //       }
  //     });
  //     const resultJson = await result.json();
  //     if (!result.ok) {
  //       throw new Error("Failed to login");
  //     }

  //     setUserRole(resultJson?.response?.role)

  //   } catch (error) {
  //     //
  //   }
  // }

  // async function LoginLogger (){
  //   const token = await getAccessToken()
  //          // Get browser CodeName
  //   const browserCodeName = navigator.appCodeName;

  //   // Get browser Name
  //   const browserName = navigator.appName;

  //   // Get browser Version
  //   const browserVersion = navigator.appVersion;

  //   // Check if cookies are enabled
  //   const cookiesEnabled = navigator.cookieEnabled;

  //   // Get platform
  //   const platform = navigator.platform;

  //   // Get user-agent header
  //   const userAgent = navigator.userAgent;
  //   // Additional properties you can include
  //   const language = navigator.language;
  //   const online = navigator.onLine;
  //   const doNotTrack = navigator.doNotTrack || navigator.msDoNotTrack || 'unspecified';
  //   const javaEnabled = navigator.javaEnabled();

  //   // Create the client information object
  //   const info = {
  //     browserCodeName,
  //     browserName,
  //     browserVersion,
  //     cookiesEnabled,
  //     platform,
  //     userAgent,
  //     language,
  //     online,
  //     doNotTrack,
  //     javaEnabled,
  //   };

  //    await axios.post(APIUrls.LoginLogger, {device_info: info},
  //     {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //         "Content-Type": "application/json",
  //       },
  //     }
  //     );
  // }

  const fetch_wallet_limit = async (tokens) => {
    setLoading(true);
    try {
      const token = tokens;
      const response = await fetch(APIUrls.get_wallet_limit, {
        headers: {
          Authorization: `Bearer ${token}`, // Include the token in the request header
        },
      }); // Replace with your API endpoint
      const limitData = await response.json();

      //
      setWalletlimitdata(limitData);

      setLoading(false);
    } catch (error) {
      console.error("Error fetching user data:", error);

      setLoading(false);
    }
  };

  function get_wallet_lim() {
    return walletLimitData?.response;
  }

  const get_settings = async () => {
    setLoading(true);
    try {
      const response = await fetch(APIUrls.get_settingsData); // Replace with your API endpoint
      const limitData = await response.json();

      //
      const pay_status = limitData?.payment_status;
      const gatew = limitData?.gateway;
      setWarnModel(!pay_status);
      SetGateway(gatew);

      setLoading(false);
    } catch (error) {
      console.error("Error fetching user data:", error);

      setLoading(false);
    }
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      setCurrentUser(user);
      get_settings();
      //
      const temp_token = user?.accessToken;

      if (!_.isEmpty(temp_token)) {
        await fetch_wallet_limit(temp_token);
        dispatch(fetchUser(temp_token));
        dispatch(generateReferal(temp_token));
      }
      setLoading(false);
    });
    //

    return () => unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value = {
    currentUser,
    setupRecaptcha,
    login,
    logout,
    resetPassword,
    updateEmail,
    updatePassword,
    getAccessToken,
    updateUser,
    getCurrentUser,
    userRole,
    saveUserRole,
    confirmationResult,
    setConfirmationResult,
    updateProfilePicture,
    reAuthenticate,
    re_fresh_user_data,
    updateUsers,
    get_wallet_lim,
    warnModelOn,
    gatewayset,
    customLogin
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
