import React from "react";
import BharatBillLogo from "../../../src/assets/revamped/BharatBillPaymentLogo.svg";

import CableTvLogo from "../../../src/assets/revamped/CableTvLogo.svg";
import DthLogo from "../../../src/assets/revamped/DthLogo.svg";
import fasTagLogo from "../../../src/assets/revamped/FasTagLogo.svg";
import LandLineLogo from "../../../src/assets/revamped/LandLineLogo.svg";
import MobileRechargeLogo from "../../../src/assets/revamped/MobileRechargeLogo.svg";
import ServicesCard from "./ServicesCard";

import HouseRent from "../../../src/assets/revamped/HouseRent.svg";
import OfficeRent from "../../../src/assets/revamped/building.svg";
import SchoolIcon from "../../../src/assets/revamped/SchoolIcon.svg";
import CollegeIcon from "../../../src/assets/revamped/CollegeIcon.svg";

import BroadbandLogo from "../../../src/assets/revamped/BroadbandLogo.svg";
import EducationLogo from "../../../src/assets/revamped/EducationLogo.svg";
import ElectricityLogo from "../../../src/assets/revamped/ElectricityLogo.svg";
import LPGLogo from "../../../src/assets/revamped/LpsGas.svg";
import PipeGasLogo from "../../../src/assets/revamped/PipeGasLogo.svg";
import WaterLogo from "../../../src/assets/revamped/Water.svg";

import CreditCardLogo from "../../../src/assets/revamped/CreditCard.svg";
import InsuranceLogo from "../../../src/assets/revamped/Insurance.svg";
import LoanLogo from "../../../src/assets/revamped/LoanIcon.svg";
import MunicipalTaxLogo from "../../../src/assets/revamped/Municipal TaxLogo.svg";
import RecurringDepositsLogo from "../../../src/assets/revamped/RecurringDepositsLogo.svg";

import ClubsLogo from "../../../src/assets/revamped/ClubsLogo.svg";
import DonationLogo from "../../../src/assets/revamped/DonationLogo.svg";
import HospitalLogo from "../../../src/assets/revamped/HospitalLogo.svg";
import HousingSocietyLogo from "../../../src/assets/revamped/HousingSocietyLogo.svg";
import { useAuth } from "../../stores/AuthContext";
import { toast } from "react-toastify";
import { Navigate, useNavigate } from "react-router-dom";

export const RechargeSection = [
  {
    lable: "Mobile Recharge",
    logo: MobileRechargeLogo,
    // path: "/bbps/recharge/Mobile_Recharge",
    path: "/bbpsServices/commingsoon",
  },
  {
    lable: "FASTag",
    logo: fasTagLogo,
    path: "/bbps/recharge/Fastag",
  },
  {
    lable: "NCMC Recharge",
    logo: fasTagLogo,
    path: "/bbps/recharge/NCMC_Recharge",
  },
  {
    lable: "DTH Recharge",
    logo: DthLogo,
    // path: "/bbps/recharge/DTH_Recharge",
    path: "/bbpsServices/commingsoon",
  },
  {
    lable: "Cable TV",
    logo: CableTvLogo,
    // path: "/bbps/recharge/Cable_TV",
    path: "/bbpsServices/commingsoon",
  },
  {
    lable: "Postpaid",
    logo: MobileRechargeLogo,
    // path: "/bbps/recharge/Mobile_Postpaid",
    path: "/bbpsServices/commingsoon",
  },
  {
    lable: "Landline Postpaid",
    logo: LandLineLogo,
    // path: "/bbps/recharge/Landline_Postpaid",
    path: "/bbpsServices/commingsoon",
  },
];
export const RentSection = [
  {
    lable: "House Rent",
    logo: HouseRent,
    path: "/payout/house-rent",
  },
  {
    lable: "Office Rent",
    logo: OfficeRent,
    path: "/payout/office-rent",
  },
];
export const EducationSection = [
  {
    lable: "School fee",
    logo: SchoolIcon,
    path: "/education/school-fee",
  },
  {
    lable: "College fee",
    logo: CollegeIcon,
    path: "/education/college-fee",
  },
];

export const UtilitySection = [
  // {
  //   lable: "Rental",
  //   logo: HouseRent,
  //   path: "/payout/house-rent",
  // },
  {
    lable: "Broadband",
    logo: BroadbandLogo,
    // path: "/Broadband",
    path: "/bbpsServices/commingsoon",
  },
  {
    lable: "Electricity Bill",
    logo: ElectricityLogo,
    path: "/bbps/utility/Electricity",
  },
  {
    lable: "Water",
    logo: WaterLogo,
    // path: "/bbps/utility/Water",
    path: "/bbpsServices/commingsoon",
  },
  {
    lable: "LPG Booking",
    logo: LPGLogo,
    path: "/bbps/utility/LPG_Gas",
  },
  {
    lable: "Piped Gas",
    logo: PipeGasLogo,
    // path: "/Piped_Gas",
    path: "/bbpsServices/commingsoon",
  },
  {
    lable: "Education",
    logo: EducationLogo,
    path: "/bbps/utility/BBPSEducation",
    // path: "/bbpsServices/commingsoon",
  },
];

export const FinanceSection = [
  {
    lable: "Loan EMI Payment",
    logo: LoanLogo,
    path: "/bbps/finance/Loan_Repayment",
  },
  {
    lable: "Insurance",
    logo: InsuranceLogo,
    path: "/bbps/finance/Insurance",
  },
  {
    lable: "Credit Card",
    logo: CreditCardLogo,
    path: "/bbps/finance/Credit_Card",
  },
  {
    lable: "Municipal Tax",
    logo: MunicipalTaxLogo,
    // path: "/Municipal_Tax",
    path: "/bbpsServices/commingsoon",
  },
  {
    lable: "Recurring Deposits",
    logo: RecurringDepositsLogo,
    // path: "/Recurring_Deposits",
    path: "/bbpsServices/commingsoon",
  },
];

export const OtherPaymentsSection = [
  {
    lable: "Hospital & Pathology",
    logo: HospitalLogo,
    path: "/Hospital_Pathology",
  },
  {
    lable: "Subscriptions",
    logo: RecurringDepositsLogo,
    path: "/Subscriptions",
  },
  {
    lable: "Housing Society",
    logo: HousingSocietyLogo,
    path: "/Housing_Society",
  },
  {
    lable: "Clubs & Associations",
    logo: ClubsLogo,
    path: "/Clubs_Associations",
  },
  {
    lable: "Donation",
    logo: DonationLogo,
    path: "/Donation",
  },
];

function BBPSServices(props) {
  const { currentUser } = useAuth();

  const navigate = useNavigate();

  if (!currentUser) {
    toast.error("please login to continue", {
      toastId: "success1",
    });
    return <Navigate to='/login' replace={true} />;
  }

  return (
    <>
      <div className=' bg-[#131313] max-w-[1568px] m-auto text-white px-[20px] py-[20px] md:px-[80px] md:py-[40px] mb-[20px] md:mb-[40px] rounded-[16px] border-[1px] border-[solid] border-[rgba(255,255,255,0.04)] box-shadow:0px_0px_20px_0px_rgba(0,_0,_0,_0.10) '>
        <div className='flex justify-between items-center self-stretch'>
          <p className='hidden md:block text-[#FFF] font-[Inter] text-[16px] not-italic font-bold leading-[normal] tracking-[0.32px]'>
            {" "}
            BHARAT BILLPAY PAYMENT SYSTEM
          </p>
          <img src={BharatBillLogo} alt='' />
        </div>
      </div>

      <ServicesCard title='Recharge' data={RechargeSection} />
      <ServicesCard title='Utility Bills' data={UtilitySection} />
      <ServicesCard title='Finance & Tax' data={FinanceSection} />
      <ServicesCard title='Other Payments' data={OtherPaymentsSection} />
    </>
  );
}

export default BBPSServices;
