import { load } from "@cashfreepayments/cashfree-js";
import { Icon } from "@iconify/react/dist/iconify.js";
import axios from "axios";
import CryptoJS from "crypto-js";
import _ from "lodash";
import forge from "node-forge";
import React, { useEffect, useRef, useState } from "react";
import { CgSpinner } from "react-icons/cg";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { APIUrls } from "../../../../baseUrl/BaseUrl";
import public_key from "../../../../public_key";
import { useAuth } from "../../../../stores/AuthContext";
import GrayColoredCardWrapper from "../../../cards/GrayColoredCardWrapper";
import SelectBox from "../../../forms/SelectBox";
import Loader from "../../../loader/Loader";
import BbpsHeader from "../../BbpsHeader";
import DynamicInputElectricity from "./DynamicInputForBBPSEducation";
import EditableInputFieldForElectricity from "./EditableInputFieldForBBPSEducation";
import DynamicInputForBBPSEducation from "./DynamicInputForBBPSEducation";
import EditableInputFieldForBBPSEducation from "./EditableInputFieldForBBPSEducation";
import { useDebounce } from "use-debounce";
import DropDownForEducationBiller from "./DropDownForEducationBiller";

// import Select from "react-select";

function BBPSEducation({ utilitycategory = "Electricity" }) {
  // const { utilitycategory } = useParams();
  const [billerData, setBillerData] = useState([]);
  const [selectedBiller, setSelectedBiller] = useState(false);
  const [loading, setLoading] = useState(false);
  const [billInfo, setBillInfo] = useState(false);
  const [userBillData, setUserBillData] = useState(false);
  // const [emailVerifieds, setEmailverified] = useState(true);
  const [kycStatuss, setKyc_status] = useState(false);
  const [paymentDisables, setPaymentDisable] = useState(false);
  const { getAccessToken, currentUser } = useAuth();
  // const [isLoading, setIsLoading] = useState(false);
  const payButtonRef = useRef(null);
  const userDatas = useSelector((state) => state.userData);
  const [isEditAmount, setIsEditAmount] = useState(false);
  const [errors, setErrors] = useState({});
  const { emailVerified, kyc_verified, paymentDisabled } = userDatas;

  //debouncing code
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm] = useDebounce(searchTerm, 300);
  const [searchResults, setSearchResults] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // Track whether the dropdown is open

  useEffect(() => {
    if (_.has(currentUser, "reloadUserInfo")) {
      if (_.has(currentUser.reloadUserInfo, "customAttributes")) {
        setValuesToForm(currentUser);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);
  const setValuesToForm = (userData) => {
    // const { FirstName, LastName, State, City, Address, Pincode } = JSON.parse(
    //   userData.reloadUserInfo.customAttributes
    // ).Customer_data;

    if (_.isEmpty(emailVerified)) {
      //
      // setEmailverified(false);
    }

    if (emailVerified === true) {
      //
      // setEmailverified(true);
    }

    if (_.isEmpty(paymentDisabled)) {
      //
      setPaymentDisable(false);
    }

    if (paymentDisabled === true) {
      setPaymentDisable(true);
    }

    if (_.isEmpty(kyc_verified)) {
      //
      setKyc_status(false);
    }

    if (kyc_verified === true) {
      setKyc_status(true);
    }
  };

  //code for fetch biller

  // useEffect(() => {
  //   const fetchData = async () => {
  //     if (utilitycategory && utilitycategory !== "") {
  //       try {
  //         setLoading(true);
  //         const token = await getAccessToken();
  //         const response = await axios.post(
  //           APIUrls.biller_fetch,
  //           {
  //             billertype: utilitycategory,
  //           },
  //           {
  //             headers: {
  //               Authorization: `Bearer ${token}`,
  //               "Content-Type": "application/json",
  //             },
  //           }
  //         );

  //         if (response.data.code === 200) {
  //           // 
  //           setBillerData(response?.data?.response?.billers);
  //           setLoading(false);
  //         } else {
  //           setLoading(false);
  //           toast("Somthing went to wrong", {
  //             theme: "dark",
  //             hideProgressBar: true,
  //             type: "error",
  //           });
  //         }
  //       } catch (error) {
  //         setLoading(false);
  //       }
  //     }
  //   };

  //   fetchData();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [utilitycategory]);

  useEffect(() => {
    
    setBillInfo(false);
    setErrors({});
    setIsEditAmount(false);
  }, [utilitycategory, selectedBiller]);

  const handleTransaction = async () => {
    // 
    // 

    if (!emailVerified) return toast.error("Your Email is not Verified !");

    // if (!kycStatuss) {
    //   return toast.error("Your Kyc status is disabled !");
    // }

    if (!paymentDisables) {
      return toast.error("Your payment is disabled !");
    }
    let QuickPay = "No";
    if (selectedBiller?.billarData?.billerAcceptsAdhoc === "true") {
      // if (selectedBiller?.billarData?.fetchRequirement === "OPTIONAL") {
      // }

      if (selectedBiller?.billarData?.fetchRequirement === "NOT_SUPPORTED") {
        if (
          selectedBiller?.billarData?.supportBillValidation === "OPTIONAL" ||
          selectedBiller?.billarData?.supportBillValidation === "NOT_SUPPORTED"
        ) {
          QuickPay = "Yes";
        }
      }
      // if (selectedBiller?.billerDetail?.supportBillValidation === "MANDATORY") {
      // }
    }

    const Paydata = {
      billerDetail: selectedBiller,
      billInfo: { ...billInfo, QuickPay: QuickPay },
      billerCategory: utilitycategory,
    };

    // 

    forge.random.getBytesSync(16);
    const binaryKEy = forge.random.getBytesSync(16).toString();

    const symmetricKey = forge.util.bytesToHex(binaryKEy);

    const key = symmetricKey;
    // Encrypt
    const ciphertext = CryptoJS.AES.encrypt(
      JSON.stringify(Paydata),
      key
    ).toString();
    const encryptData = (paymentData, publicKey) => {
      // Replace with the server's public key
      // const symmetricKey = forge.random.getBytesSync(16);

      // Convert the public key to a Forge RSA public key object
      const publicKeyForge = forge.pki.publicKeyFromPem(public_key);

      // Encrypt the data using RSA_PKCS1_OAEP_PADDING and sha256 hash
      const encrypted = publicKeyForge.encrypt(key, "RSA-OAEP", {
        md: forge.md.sha256.create(),
      });

      // Convert the encrypted bytes to a Base64-encoded string
      const encryptedBase64 = forge.util.encode64(encrypted);

      return encryptedBase64;
    };

    try {
      setLoading(true);

      const updatedValue = forge.util.encode64(ciphertext);

      const cashFreeData = {
        token: await getAccessToken(),
        key: encryptData(Paydata, public_key),
        payData: updatedValue,
      };

      try {
        const response = await axios.post(
          APIUrls.cashFreeCreateOrderForBBPS,
          cashFreeData
        );
        // 
        setLoading(false);

        if (response.status === 201) {
          const payment_session_id =
            response?.data?.response?.payment_session_id;
          const order_id = response?.data?.response?.order_id;
          // 

          handleCheckOut(payment_session_id, order_id);
        }
      } catch (error) {
        setLoading(false);
        // 
        toast.error("Something went Wrong !");
      }
    } catch (error) {
      setLoading(false);
      // 

      toast.error("Something went Wrong !");
    }
  };
  const handleCheckOut = async (payment_session_id, order_id) => {
    try {
      // it containse mode (sandbox, production) for the cashfree PG
      const cashfree = await load({
        mode: APIUrls.cashfreeMode, // "sandbox", //or production
      });
      let checkoutOptions = {
        paymentSessionId: payment_session_id,
        returnUrl: `${APIUrls.cashFreePaymentResponseForBBPS}/?orderid=${order_id}`,
        redirectTarget: "_self",
      };
      cashfree.checkout(checkoutOptions).then(function (result) {
        if (result.error) {
          alert(result.error.message);
        }
        if (result.redirect) {
          // 
        }
      });
    } catch (error) {
      // 
    }
  };

  const openEditAmount = () => {
    setIsEditAmount(true);
  };

  const closeEditAmount = () => {
    setIsEditAmount(false);
  };

  const updateBillInfo = (updatedBillInfo) => {
    setBillInfo(updatedBillInfo);
  };

  //debouncing code

  // const handleInputChange = (e) => {
  //   setSearchTerm(e.target.value);
  //   setSelectedBiller(null);
  //   setIsDropdownOpen(true); // Open the dropdown when user types
  // };
  // const handleBillerSelect = (biller) => {
  //   setSelectedBiller(biller);
  //   setSearchTerm(biller.billerName);
  //   setIsDropdownOpen(false); // Open the dropdown when user types

  //   // Here you can trigger an action to store the selected biller to the backend
  //   // For example:
  //   // saveSelectedBillerToBackend(biller);
  // };

  // useEffect(() => {
  //   const searchBiller = async () => {
  //     try {
  //       const response = await axios.get(
  //         `${APIUrls.baseUrl}/api/bbps/searchBiller?searchTerm=${debouncedSearchTerm}`
  //       );
  //       setSearchResults(response.data);
  //     } catch (error) {
  //       console.error("Error searching billers:", error);
  //     }
  //   };

  //   if (debouncedSearchTerm) {
  //     searchBiller();
  //   } else {
  //     setSearchResults([]);
  //   }
  // }, [debouncedSearchTerm]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="px-[1rem] md:px-[80px] py-[2rem] md:py-[40px]">
            <div className="flex flex-col md:flex-row gap-[2rem] md:gap-[80px] lg:min-w-[350px]">
              <div className="md:w-[50%]  xs:p-[40px] lg:px-[80px] lg:py-[40px] flex flex-col gap-[24px]">
                <BbpsHeader />

                {/* Biller selecte card */}
                {billerData?.length > 0 && (
                  <SelectBox
                    name="biller"
                    label="Select your biller"
                    options={billerData.map((datas, index) => {
                      return {
                        label: datas.billerName,
                        value: datas.billerId,
                      };
                    })}
                    value={selectedBiller.billerId || ""}
                    onChange={(e) => {
                      setSelectedBiller(
                        billerData?.find((a) => a.billerId === e)
                      );
                    }}
                    token={getAccessToken}
                    setLoading={setLoading}
                    setUserBillData={setUserBillData}
                  />
                )}

                <div className="flex flex-col gap-[1rem] mb-5">
                  <label
                    htmlFor="accounId"
                    className="text-[#FFF] font-[Inter] text-[1rem] xs:text-[20px] not-italic font-bold leading-[normal]"
                  >
                    {" "}
                    Select your biller
                  </label>
                  {/** deboucing code */}
                  {/* <input
                    type="text"
                    value={searchTerm}
                    onChange={handleInputChange}
                    placeholder="Search Biller"
                    className="p-[14.5px] block w-full text-[#ffffff] bg-transparent font-bold text-[0.8rem] lg:text-[1rem] rounded-[4px] border-[1px] border-[solid] border-[rgba(255,255,255,0.20)]"
                  /> */}
                  <DropDownForEducationBiller
                    setSelectedBiller={setSelectedBiller}
                  />
                </div>

                {/* {billerData?.length > 0 && */}

                {selectedBiller &&
                  selectedBiller?.billarData?.customerParams && (
                    <DynamicInputForBBPSEducation
                      selectedBiller={selectedBiller}
                      customerParams={
                        selectedBiller?.billarData?.customerParams
                      }
                      billInfo={billInfo}
                      setBillInfo={updateBillInfo}
                      userBillData={userBillData}
                      openEditAmount={openEditAmount}
                    />
                  )}
              </div>

              {/* payment card */}
              <div
                className="md:w-[50%] p-[24px] xs:p-[40px] lg:px-[80px] lg:py-[40px] text-white font-inter rounded-[25px] border-[1px] border-[solid] border-[rgba(255,255,255,0.04)] bg-[#202020] md:self-start"
                ref={payButtonRef}
              >
                <GrayColoredCardWrapper className="flex flex-col gap-[30px] lg:gap-[40px]">
                  <p className="text-[#FFF] font-[Inter] text-[20px] xs:text-[24px] not-italic font-bold leading-[normal]">
                    Payment Details
                  </p>

                  {/* customer name */}
                  <div className="text-[rgba(255,_255,_255,_0.70)] font-[Inter] text-[16px] xs:text-[20px] not-italic font-medium leading-[normal] flex flex-row justify-between">
                    <span>Name</span>{" "}
                    <span>
                      {billInfo?.responseData?.response?.billerResponse
                        ?.customerName || "-"}
                    </span>
                  </div>

                  {/* due date (if not receive that not show) */}

                  {billInfo?.responseData?.response?.billerResponse
                    ?.dueDate && (
                    <div className="text-[rgba(255,_255,_255,_0.70)] font-[Inter] text-[16px] xs:text-[20px] not-italic font-medium leading-[normal] flex flex-row justify-between">
                      <span>Due Date</span>{" "}
                      <span>
                        {billInfo?.responseData?.response?.billerResponse
                          ?.dueDate || "-"}
                      </span>
                    </div>
                  )}
                  <div className=" text-[rgba(255,_255,_255,_0.70)] font-[Inter] text-[16px] xs:text-[20px] not-italic font-medium leading-[normal] flex flex-row justify-between">
                    <span>Amount</span>{" "}
                    <div className="flex items-center gap-[5px]">
                      {billInfo ? (
                        isEditAmount ? (
                          <EditableInputFieldForBBPSEducation
                            closeEditAmount={closeEditAmount}
                            billInfo={billInfo}
                            setBillInfo={updateBillInfo}
                            setErrors={setErrors}
                            errors={errors}
                            selectedBiller={selectedBiller}
                          />
                        ) : (
                          <span> {billInfo?.amount} </span>
                        )
                      ) : (
                        <span>-</span>
                      )}

                      {/** if bill validationis mandatory or optional when we edit or enter amount */}

                      {selectedBiller?.billarData?.billerAcceptsAdhoc ===
                        "true" &&
                        billInfo &&
                        !isEditAmount && (
                          <span onClick={() => openEditAmount()}>
                            <Icon
                              icon="tabler:edit"
                              width="1.5rem"
                              height="1.5rem"
                              className="text-[#f73367] cursor-pointer"
                            />
                          </span>
                        )}
                    </div>
                  </div>
                  {/* Error message for edit amount input filed */}
                  {errors.amount && (
                    <p className="text-[16px] text-red-500 mt-[-30px]">
                      {errors.amount}
                    </p>
                  )}

                  <div className=" text-[rgba(255,_255,_255)] font-[Inter] text-[16px] xs:text-[20px] not-italic font-medium leading-[normal] flex flex-row justify-between">
                    <span>Total</span> <span>{billInfo?.amount || "-"}</span>
                  </div>
                </GrayColoredCardWrapper>
                {/** disable payment option and show Coming Soon */}

                <button
                  type="submit"
                  className="bg-[#424242] mt-[80px] xs:mt-[32px] text-center w-full text-[16px] xs:text-[20px] rounded-[30px] text-white py-[0.8rem] font-[800] flex flex-row items-center justify-center gap-[0.5rem]"
                  onClick={handleTransaction}
                  // disabled={!selectedBiller || !billInfo || errors.amount}
                  disabled={true}
                >
                  {loading && (
                    <CgSpinner size={20} className="mt-1 animate-spin" />
                  )}
                  Total Pay ₹{billInfo?.amount || "0"}
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default BBPSEducation;
